import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TaskState } from './state';
import { ITask, ITaskResult, ITaskType } from '@/interfaces';

const defaultState: TaskState = {
    tasks: [],
    schedule: [],
    lastResult: null,
    statusInfo: {},
    taskTypes: new Map<number, ITaskType>(),
    results: [],
    workers: [],
    runningTasks: [],
    lastUsedTask: null,
    runningTaskMap: new Map<number, ITask>(),
    taskResultsMap: new Map<number, ITaskResult>(),
};

export const taskModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
