import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IServiceCase
        , IServiceCaseCreate
        , IServiceCaseStepCreate
        , IServiceCaseUpdate
        , IServiceReport
        , IServiceReportCreate
        , IServiceReportUpdate
        , IServicePriceList
        , IServicePriceListCreate
        , IServicePriceListUpdate
    } from '@/interfaces/service';
import { State } from '../state';
import { ServiceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetCases
    , commitCaseStatusInfo
    , commitSetCase
    , commitSetStepTypeInfo
    , commitSetSteps
    , commitSetStep
    , commitSetPayers
    , commitSetReport
    , commitSetServicePriceLists
    , commitSetServicePriceListOne
    , commitRemoveServicePriceList
    , commitSetServiceOperations
    } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification
        , commitRemoveNotification
    } from '../main/mutations';
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<ServiceState, State>;

export const actions = {
    async actionGetCases(context: MainContext) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            const response = await api.getServiceCases(context.rootState.main.token);
            if (response) {
                //console.debug(response);
                commitSetCases(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.casesLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCaseStatusInfo(context: MainContext) {
        try {
            const response = await api.getCaseStatusInfo(context.rootState.main.token);
            if (response) {
                // console.debug('action actionGetCaseStatusInfo');
                // console.debug(response.data)
                commitCaseStatusInfo(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCase(context: MainContext, payload: { id: number, case: IServiceCaseUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateServiceCase(context.rootState.main.token, payload.id, payload.case),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            console.debug(response);
            commitSetCase(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.caseUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCase(context: MainContext, payload: IServiceCaseCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceCase(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetCase(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.caseCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteCase(context: MainContext, payload: {id: number}) {
        try {
            const loadingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteServiceCase(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.serviceCaseDeleted').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.serviceCaseNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetStepTypeInfo(context: MainContext) {
        try {
            const response = await api.getStepTypeInfo(context.rootState.main.token);
            if (response) {
                // console.debug('action actionGetCaseStatusInfo');
                // console.debug(response.data)
                commitSetStepTypeInfo(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetSteps(context: MainContext, caseID: number) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            const response = await api.getServiceCaseSteps(context.rootState.main.token, caseID);
            if (response) {
                //console.debug(response);
                commitSetSteps(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.serviceStepsLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCaseStep(context: MainContext, payload: IServiceCaseStepCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceCaseStep(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetStep(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.caseStepCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPayers(context: MainContext) {
        try {
            const response = await api.getServicePayers(context.rootState.main.token);
            if (response) {
                //console.debug(response);
                commitSetPayers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetReport(context: MainContext, caseID: number) {
        try {
            // console.debug('actionGetReport');
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.getServiceReport(context.rootState.main.token, caseID),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            // console.debug(response);
            commitSetReport(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.serviceReportLoaded').toString(), color: 'success' });
        } catch (error: any) {
            // console.debug('actionGetReport - error' + error);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateReport(context: MainContext, payload: { id: number, case: IServiceReportUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateServiceReport(context.rootState.main.token, payload.id, payload.case),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            // console.debug(response);
            commitSetReport(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.serviceReportUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateReport(context: MainContext, payload: IServiceReportCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceReport(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetReport(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.serviceReportCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteReport(context: MainContext, payload: {id: number}) {
        try {
            const loadingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteServiceReport(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.serviceReportDeleted').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.serviceReportNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetServicePriceLists(context: MainContext) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getServicePriceLists(context.rootState.main.token);
            if (response) {
                commitSetServicePriceLists(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'ServicePriceLists loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetServicePriceLists').toString(), color: 'error' });
        }
    },
    async actionGetServicePriceListOne(context: MainContext, payload: { servicepricelistID: number }) {
        try {
            const response = await api.getServicePriceListOne(context.rootState.main.token, payload);
            if (response) {
                commitSetServicePriceListOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateServicePriceList(context: MainContext, payload: IServicePriceListCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createServicePriceList(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetServicePriceListOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.servicePriceListCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.servicePriceListNotCreated').toString(), color: 'error' });
        }
    },
    async actionUpdateServicePriceList(context: MainContext, payload: IServicePriceListUpdate) {
        try {
            const updatingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateServicePriceList(context.rootState.main.token, payload.id, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetServicePriceListOne(context, response.data);
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: i18n.t('notification.servicePriceListSaved').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.servicePriceListNotSaved').toString(), color: 'error' });
        }
    },
    async actionDeleteServicePriceList(context: MainContext, payload: IServicePriceList ) {
        const removingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteServicePriceList(context.rootState.main.token, payload);
            if (response) {
                commitRemoveServicePriceList(context, response.data);
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.servicePriceListNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetServiceOperations(context: MainContext) {
        try {
            const response = await api.getServiceOpeations(context.rootState.main.token);
            if (response) {
                commitSetServiceOperations(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetServiceOperations').toString(), color: 'error' });
        }
    },
};

const { dispatch } = getStoreAccessors<ServiceState, State>('');

export const dispatchGetCases = dispatch(actions.actionGetCases);
export const dispatchGetCaseStatusInfo = dispatch(actions.actionGetCaseStatusInfo);
export const dispatchCreateCase = dispatch(actions.actionCreateCase);
export const dispatchUpdateCase = dispatch(actions.actionUpdateCase);
export const dispatchDeleteCase = dispatch(actions.actionDeleteCase);
export const dispatchGetStepTypeInfo = dispatch(actions.actionGetStepTypeInfo);
export const dispatchGetSteps = dispatch(actions.actionGetSteps);
export const dispatchCreateCaseStep = dispatch(actions.actionCreateCaseStep);
export const dispatchGetPayers = dispatch(actions.actionGetPayers);
export const dispatchGetReport = dispatch(actions.actionGetReport);
export const dispatchCreateReport = dispatch(actions.actionCreateReport);
export const dispatchUpdateReport = dispatch(actions.actionUpdateReport);
export const dispatchDeleteReport = dispatch(actions.actionDeleteReport);
export const dispatchGetServicePriceLists = dispatch(actions.actionGetServicePriceLists);
export const dispatchGetServicePriceListOne = dispatch(actions.actionGetServicePriceListOne);
export const dispatchCreateServicePriceList = dispatch(actions.actionCreateServicePriceList);
export const dispatchUpdateServicePriceList = dispatch(actions.actionUpdateServicePriceList);
export const dispatchDeleteServicePriceList = dispatch(actions.actionDeleteServicePriceList);
export const dispatchGetServiceOperations = dispatch(actions.actionGetServiceOperations);
