import { api } from '@/api';
import { ActionContext } from 'vuex';
import { LocaleMessages } from 'vue-i18n';
import { IUserProfileCreate, IUserProfileUpdate, IActionProfileCreate, IActionProfile, IRoleUpdate, IRoleCreate } from '@/interfaces';
import {
    ILocaleDictionaryItem
    , ILocaleDictionaryItemUpdate
} from '@/interfaces/admin';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetUsers
    , commitSetUser
    , commitSetActions
    , commitSetAction
    , commitSetActionsMulti
    , commitSetRoles
    , commitSetRole
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
    commitAddNotification
    , commitRemoveNotification
    , commitSetLocale
} from '../main/mutations';
import {
    commitSetLog
    , commitSetLocaleDictionaryItems
    , commitSetLocaleDictionaryItem
} from '../admin/mutations'
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    async actionGetUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetActions(context: MainContext) {
        try {
            const response = await api.getActions(context.rootState.main.token);
            if (response) {
                commitSetActions(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.userUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.userCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateAction(context: MainContext, payload: IActionProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createAction(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetAction(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.actionCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateActionsMulti(context: MainContext, payload: IActionProfileCreate[]) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createActionsMulti(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetActionsMulti(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.actionCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteAction(context: MainContext, payload: IActionProfile) {
        try {
            const loadingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteAction(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.actionDeleted').toString(), color: 'success' });
            const response2 = await api.getActions(context.rootState.main.token);
            if (response2) {
                commitSetActions(context, response2.data);
            }
            //await this.actionGetActions(context);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.actionNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetRoles(context: MainContext) {
        try {
            const response = await api.getRoles(context.rootState.main.token);
            if (response) {
                commitSetRoles(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateRole(context: MainContext, payload: { id: number, role: IRoleUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateRole(context.rootState.main.token, payload.id, payload.role),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetRole(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.roleUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateRole(context: MainContext, payload: IRoleCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createRole(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetRole(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.roleCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionLocaleDownload(context: MainContext) {
        try {
            const response = (await Promise.all([
                api.downloadLocale(context.rootState.main.token),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            const now = new Date();

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'dictionary.json');
            document.body.appendChild(fileLink);

            fileLink.click();
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateLocale(context: MainContext, payload: LocaleMessages) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.updateLocale(context.rootState.main.token, payload);
            if (response) {
                commitSetLocale(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.saving').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUploadLocale(context: MainContext, payload: FormData) {
        try {
            const uploadingNotification = { content: i18n.t('notification.uploading').toString(), showProgress: true };
            commitAddNotification(context, uploadingNotification);
            await api.uploadLocale(context.rootState.main.token, payload);
            commitRemoveNotification(context, uploadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.localeUploaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionReloadLocale(context: MainContext) {
        try {
            const uploadingNotification = { content: i18n.t('notification.reloading').toString(), showProgress: true };
            await api.reloadLocale(context.rootState.main.token);
            commitAddNotification(context, { content: i18n.t('notification.localeReloaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionReloadLocaleDB(context: MainContext) {
        try {
            const uploadingNotification = { content: i18n.t('notification.reloading').toString(), showProgress: true };
            await api.reloadLocaleDB(context.rootState.main.token);
            commitAddNotification(context, { content: i18n.t('notification.localeReloaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionImportLocale(context: MainContext) {
        try {
            const uploadingNotification = { content: i18n.t('notification.importing').toString(), showProgress: true };
            await api.importLocale(context.rootState.main.token);
            commitAddNotification(context, { content: i18n.t('notification.localeImported').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetLog(context: MainContext) {
        try {
            const logNotification = { content: i18n.t('notification.log').toString(), showProgress: true };
            commitAddNotification(context, logNotification);
            const response = await api.getLog(context.rootState.main.token);
            if (response) {
                commitSetLog(context, response.data);
            }
            commitRemoveNotification(context, logNotification);
            commitAddNotification(context, { content: i18n.t('notification.logDownloaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetLocaleDictionary(context: MainContext) {
        try {
            const response = await api.getLocaleDB(context.rootState.main.token);
            if (response) {
                commitSetLocaleDictionaryItems(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateLocaleDictionaryItem(context: MainContext, payload: { id: number, item: ILocaleDictionaryItemUpdate }) {
        try {
            const response = await api.updateLocaleDictionaryItem(
                context.rootState.main.token
                , payload.id
                , payload.item
            );
            if (response) {
                commitSetLocaleDictionaryItem(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    }
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchGetActions = dispatch(actions.actionGetActions);
export const dispatchCreateAction = dispatch(actions.actionCreateAction);
export const dispatchCreateActionsMulti = dispatch(actions.actionCreateActionsMulti);
export const dispatchDeleteAction = dispatch(actions.actionDeleteAction);
export const dispatchGetRoles = dispatch(actions.actionGetRoles);
export const dispatchUpdateRole = dispatch(actions.actionUpdateRole);
export const dispatchCreateRole = dispatch(actions.actionCreateRole);
export const dispatchLocaleDownload = dispatch(actions.actionLocaleDownload);
export const dispatchUpdateLocale = dispatch(actions.actionUpdateLocale);
export const dispatchUploadLocale = dispatch(actions.actionUploadLocale);
export const dispatchReloadLocale = dispatch(actions.actionReloadLocale);
export const dispatchReloadLocaleDB = dispatch(actions.actionReloadLocaleDB);
export const dispatchImportLocale = dispatch(actions.actionImportLocale);
export const dispatchGetLog = dispatch(actions.actionGetLog);

export const dispatchGetLocaleDictionary = dispatch(actions.actionGetLocaleDictionary);
export const dispatchUpdateLocaleDictionaryItem = dispatch(actions.actionUpdateLocaleDictionaryItem);
