import {
  IProject
  , ITimesheet
  , IProjectTask
  , IProjectTaskState
} from '@/interfaces/project';
import { ProjectState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setProjectMulti(state: ProjectState, payload: IProject[]) {
        state.projects = payload;
    },
    setProjectOne(state: ProjectState, payload: IProject) {
        const projectList = state.projects.filter(
            (item: IProject) => item.id !== payload.id);
        projectList.push(payload);
        state.projects = projectList;
    },
    removeProject(state: ProjectState, payload: { projectID: number }) {
        state.projects = state.projects.filter(
            (item: IProject) => item.id !== payload.projectID);
    },
    setTimesheetMulti(state: ProjectState, payload: ITimesheet[]) {
        state.timesheets = payload;
    },
    setTimesheetOne(state: ProjectState, payload: ITimesheet) {
        const tsList = state.timesheets.filter(
            (item: ITimesheet) => item.id !== payload.id);
        tsList.push(payload);
        state.timesheets = tsList;
    },
    removeTimesheet(state: ProjectState, payload: { timesheetID: number }) {
        state.timesheets = state.timesheets.filter(
            (item: ITimesheet) => item.id !== payload.timesheetID);
    },
    setProjectTaskMulti(state: ProjectState, payload: IProjectTask[]) {
        state.tasks = payload;
    },
    setProjectTaskOne(state: ProjectState, payload: IProjectTask) {
        const tasks = state.tasks.filter(
            (item: IProjectTask) => item.id !== payload.id);
        tasks.push(payload);
        state.tasks = tasks;
    },
    removeProjectTask(state: ProjectState, payload: { taskID: number }) {
        state.tasks = state.tasks.filter(
            (item: IProjectTask) => item.id !== payload.taskID);
    },
    setProjectTaskStateMulti(state: ProjectState, payload: IProjectTaskState[]) {
        state.taskStates = payload;
    },
    setProjectTaskStateOne(state: ProjectState, payload: IProjectTaskState) {
        const states = state.taskStates.filter(
            (item: IProjectTaskState) => item.id !== payload.id);
        states.push(payload);
        state.taskStates = states;
    },
    removeProjectTaskState(state: ProjectState, payload: { stateID: number }) {
        state.tasks = state.tasks.filter(
            (item: IProjectTaskState) => item.id !== payload.stateID);
    },
};

const { commit } = getStoreAccessors<ProjectState, State>('');

export const commitSetProjectMulti = commit(mutations.setProjectMulti);
export const commitSetProjectOne = commit(mutations.setProjectOne);
export const commitRemoveProject = commit(mutations.removeProject);
export const commitSetTimesheetMulti = commit(mutations.setTimesheetMulti);
export const commitSetTimesheetOne = commit(mutations.setTimesheetOne);
export const commitRemoveTimesheet = commit(mutations.removeTimesheet);
export const commitSetProjectTaskMulti = commit(mutations.setProjectTaskMulti);
export const commitSetProjectTaskOne = commit(mutations.setProjectTaskOne);
export const commitRemoveProjectTask = commit(mutations.removeProjectTask);

export const commitSetProjectTaskStateMulti = commit(mutations.setProjectTaskStateMulti);
export const commitSetProjectTaskStateOne = commit(mutations.setProjectTaskStateOne);
export const commitRemoveProjectTaskState = commit(mutations.removeProjectTaskState);
