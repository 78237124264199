import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c(VApp,[(_vm.loggedIn===null)?_c(VMain,[_c(VContainer,{attrs:{"fill-height":""}},[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c('div',{staticClass:"headline my-5"},[_vm._v("Loading...")]),_c(VProgressCircular,{attrs:{"size":"100","indeterminate":"","color":"primary"}})],1)],1)],1):_c('router-view'),_c('NotificationsManager')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }