export const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date) => {
    return (
        [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        ].join('-') +
        'T' +
        [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        ].join(':')
    );
}

export const formatDateCompact = (date: Date) => {
    return (
        [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        ].join('') +
        [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        ].join('')
    );
}


export const formatDateS = (date: string) => {
    return formatDate(new Date(date));
}

export const getDateLocale = (iso: string) => {
    return new Date(iso).toLocaleString();
}

export function getTimezoneOffset() {
    function z(n: number){return (n<10? '0' : '') + n}
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0? '+' : '-';
    offset = Math.abs(offset);
    return sign + z(offset/60 | 0) + z(offset%60);
}
