import { ISCADAHelios, ISCADAHeliosRecord, ISCADAHeliosRecordRow } from '@/interfaces';
import { SCADAState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setHeliosDevices(state: SCADAState, payload: ISCADAHelios[]) {
        state.heliosDevices = payload;
    },
    setHeliosRecords(state: SCADAState, payload: ISCADAHeliosRecord[]) {
        state.recordsHelios = payload;
    },
    setCurrentHeliosRecord(state: SCADAState, payload: ISCADAHeliosRecordRow[]) {
        state.currentHeliosRecord = payload;
    },
    setCurrentHeliosRecordReduced(state: SCADAState, payload: ISCADAHeliosRecordRow[]) {
        state.currentHeliosRecordReduced = payload;
    },
};

const { commit } = getStoreAccessors<SCADAState, State>('');

export const commitSetHeliosDevices = commit(mutations.setHeliosDevices);
export const commitSetHeliosRecords = commit(mutations.setHeliosRecords);
export const commitCurrentHeliosRecord = commit(mutations.setCurrentHeliosRecord);
export const commitCurrentHeliosRecordReduced = commit(mutations.setCurrentHeliosRecordReduced);
