import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ICompanyDepartment } from '@/interfaces/company';
import { State } from '../state';
import { CompanyState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetDepartments
    , commitSetDepartmentUsers
    , commitSetUsersBase
    , commitSetCompanies
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification
        , commitRemoveNotification
} from '../main/mutations';

type MainContext = ActionContext<CompanyState, State>;

export const actions = {
    async actionGetCompanies(context: MainContext) {
        try {
            const response = await api.getCompanies(context.rootState.main.token);
            if (response) {
                commitSetCompanies(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetDepartments(context: MainContext) {
        try {
            //const loadingNotification = { content: 'loading', showProgress: true };
            const response = await api.getCompanyDepartments(context.rootState.main.token);
            if (response) {
                //console.debug(response);
                commitSetDepartments(context, response.data);
            }
            //commitRemoveNotification(context, loadingNotification);
            //commitAddNotification(context, { content: 'Departments loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetDepartmentUsers(context: MainContext, departmentID: number) {
        try {
            const response = await api.getUsersByDepartment(context.rootState.main.token, departmentID);
            if (response) {
                commitSetDepartmentUsers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUsersBase(context: MainContext) {
        try {
            const response = await api.getUsersBase(context.rootState.main.token);
            if (response) {
                commitSetUsersBase(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<CompanyState, State>('');


export const dispatchGetCompanies = dispatch(actions.actionGetCompanies);
export const dispatchGetDepartments = dispatch(actions.actionGetDepartments);
export const dispatchGetDepartmentUsers = dispatch(actions.actionGetDepartmentUsers);
export const dispatchGetUsersBase = dispatch(actions.actionGetUsersBase);
