import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { scadaModule } from './scada';
import { taskModule } from './tasks';
import { serviceModule } from './service';
import { logisticsModule } from './logistics';
import { companyModule } from './company';
import { crmModule } from './crm';
import { financeModule } from './finance';
import { galleryModule } from './gallery';
import { commonModule } from './common';
import { ltuModule } from './ltu';
import { projectModule } from './project';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    scada: scadaModule,
    task: taskModule,
    service: serviceModule,
    logistics: logisticsModule,
    company: companyModule,
    crm: crmModule,
    finance: financeModule,
    gallery: galleryModule,
    common: commonModule,
    ltu: ltuModule,
    project: projectModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
