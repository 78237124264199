import { ILogisticsExpedition, IAHUTranFile, ILogisticsTransport } from '@/interfaces/logistics';
import { LogisticsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setExpeditions(state: LogisticsState, payload: ILogisticsExpedition[]) {
        state.expeditions = payload;
    },
    setAHUTranFile(state: LogisticsState, payload: IAHUTranFile | null) {
        state.ahutranFile = payload;
    },
    setTransports(state: LogisticsState, payload: ILogisticsTransport[]) {
        state.transports = payload;
    },
};

const { commit } = getStoreAccessors<LogisticsState, State>('');

export const commitSetExpeditions = commit(mutations.setExpeditions);
export const commitSetAHUtranFile = commit(mutations.setAHUTranFile);
export const commitSetTransports = commit(mutations.setTransports);
