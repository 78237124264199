import { api } from '@/api';
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ProjectState } from './state';
import {
    IProject
    , IProjectCreate
    , IProjectUpdate
    , ITimesheet
    , ITimesheetCreate
    , ITimesheetUpdate
    , IProjectTaskCreate
    , IProjectTaskUpdate
    , IProjectTaskStateCreate
    , IProjectTaskStateUpdate
  } from '@/interfaces/project';
import {
    commitSetProjectMulti
    , commitSetProjectOne
    , commitRemoveProject
    , commitSetTimesheetMulti
    , commitSetTimesheetOne
    , commitRemoveTimesheet
    , commitSetProjectTaskMulti
    , commitSetProjectTaskOne
    , commitRemoveProjectTask
    , commitSetProjectTaskStateMulti
    , commitSetProjectTaskStateOne
    , commitRemoveProjectTaskState
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification
    , commitRemoveNotification
} from '../main/mutations';


type MainContext = ActionContext<ProjectState, State>;

export const actions = {
    async actionGetProjectMulti(context: MainContext) {
        try {
            const response = await api.getProjects(context.rootState.main.token);
            if (response) {
                commitSetProjectMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProjectOne(context: MainContext, payload: { projectID: number }) {
        try {
            const response = await api.getProject(context.rootState.main.token, payload);
            if (response) {
                commitSetProjectOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateProject(context: MainContext, payload: IProjectCreate) {
        try {
            const savingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createProject(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetProjectOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: 'Project created', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to create Project!', color: 'error' });
        }
    },
    async actionUpdateProject(context: MainContext, payload: { projectID: number, data: IProjectUpdate }) {
        try {
            const updatingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateProject(
                    context.rootState.main.token
                    , payload.projectID
                    , payload.data
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetProjectOne(context, response.data);
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: 'Project successfully saved', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to save Project!', color: 'error' });
        }
    },
    async actionDeleteProject(context: MainContext, payload: { projectID: number } ) {
        const removingNotification = { content: 'deleting ...', showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteProject(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitRemoveProject(context, response.data);
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to delete Project', color: 'error' });
        }
    },
    async actionGetTimesheetMulti(context: MainContext) {
        try {
            const response = await api.getTimesheets(context.rootState.main.token);
            if (response) {
                commitSetTimesheetMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTimesheetOne(context: MainContext, payload: { timesheetID: number }) {
        try {
            const response = await api.getTimesheet(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitSetTimesheetOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateTimesheet(context: MainContext, payload: ITimesheetCreate) {
        try {
            const savingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createTimesheet(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetTimesheetOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: 'Timesheet created', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to create the timesheet!', color: 'error' });
        }
    },
    async actionUpdateTimesheet(context: MainContext, payload: { timesheetID: number, data: ITimesheetUpdate }) {
        try {
            const updatingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateTimesheet(
                    context.rootState.main.token
                    , payload.timesheetID
                    , payload.data
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetTimesheetOne(context, response.data);
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: 'Timesheet successfully saved', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to save the timesheet!', color: 'error' });
        }
    },
    async actionDeleteTimesheet(context: MainContext, payload: { timesheetID: number } ) {
        const removingNotification = { content: 'deleting ...', showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteTimesheet(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitRemoveTimesheet(context, { timesheetID: response.data.id});
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to delete the timesheet', color: 'error' });
        }
    },

    async actionGetProjectTaskMulti(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.getProjectTaskMulti(
                context.rootState.main.token
                , payload.id
            );
            if (response) {
                commitSetProjectTaskMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProjectTaskOne(context: MainContext, payload: { projectTaskID: number }) {
        try {
            const response = await api.getProjectTask(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitSetProjectTaskOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateProjectTask(context: MainContext, payload: IProjectTaskCreate) {
        try {
            const response = (await Promise.all([
                api.createProjectTask(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            if (response) {
                commitSetProjectTaskOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to create the projectTask!', color: 'error' });
        }
    },
    async actionUpdateProjectTask(context: MainContext, payload: { projectTaskID: number, data: IProjectTaskUpdate }) {
        try {
            const response = (await Promise.all([
                api.updateProjectTask(
                    context.rootState.main.token
                    , payload.projectTaskID
                    , payload.data
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to save the Task!', color: 'error' });
        }
    },
    async actionDeleteProjectTask(context: MainContext, payload: { projectTaskID: number } ) {
        try {
            const response = await api.deleteProjectTask(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitRemoveProjectTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to delete the projectTask', color: 'error' });
        }
    },

    async actionGetProjectTaskStateMulti(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.getProjectTaskStateMulti(
                context.rootState.main.token
                , payload.id
            );
            if (response) {
                commitSetProjectTaskStateMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProjectTaskStateOne(context: MainContext, payload: { taskStateID: number }) {
        try {
            const response = await api.getProjectTaskState(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitSetProjectTaskStateOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateProjectTaskState(context: MainContext, payload: IProjectTaskStateCreate) {
        try {
            const response = (await Promise.all([
                api.createProjectTaskState(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to create the projectTask!', color: 'error' });
        }
    },
    async actionUpdateProjectTaskState(context: MainContext, payload: { taskStateID: number, data: IProjectTaskStateUpdate }) {
        try {
            const response = (await Promise.all([
                api.updateProjectTaskState(
                    context.rootState.main.token
                    , payload.taskStateID
                    , payload.data
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to save the Task!', color: 'error' });
        }
    },
    async actionDeleteProjectTaskState(context: MainContext, payload: { taskStateID: number } ) {
        try {
            const response = await api.deleteProjectTaskState(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitRemoveProjectTaskState(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to delete the projectTask', color: 'error' });
        }
    },
}

const { dispatch } = getStoreAccessors<ProjectState, State>('');

export const dispatchGetProjectMulti = dispatch(actions.actionGetProjectMulti);
export const dispatchGetProjectOne = dispatch(actions.actionGetProjectOne);
export const dispatchCreateProject = dispatch(actions.actionCreateProject);
export const dispatchUpdateProject = dispatch(actions.actionUpdateProject);
export const dispatchDeleteProject = dispatch(actions.actionDeleteProject);

export const dispatchGetTimesheetMulti = dispatch(actions.actionGetTimesheetMulti);
export const dispatchGetTimesheetOne = dispatch(actions.actionGetTimesheetOne);
export const dispatchCreateTimesheet = dispatch(actions.actionCreateTimesheet);
export const dispatchUpdateTimesheet = dispatch(actions.actionUpdateTimesheet);
export const dispatchDeleteTimesheet = dispatch(actions.actionDeleteTimesheet);

export const dispatchGetProjectTaskMulti = dispatch(actions.actionGetProjectTaskMulti);
export const dispatchGetProjectTaskOne = dispatch(actions.actionGetProjectTaskOne);
export const dispatchCreateProjectTask = dispatch(actions.actionCreateProjectTask);
export const dispatchUpdateProjectTask = dispatch(actions.actionUpdateProjectTask);
export const dispatchDeleteProjectTask = dispatch(actions.actionDeleteProjectTask);

export const dispatchGetProjectTaskStateMulti = dispatch(actions.actionGetProjectTaskStateMulti);
export const dispatchGetProjectTaskStateOne = dispatch(actions.actionGetProjectTaskStateOne);
export const dispatchCreateProjectTaskState = dispatch(actions.actionCreateProjectTaskState);
export const dispatchUpdateProjectTaskState = dispatch(actions.actionUpdateProjectTaskState);
export const dispatchDeleteProjectTaskState = dispatch(actions.actionDeleteProjectTaskState);
