import { ICompany, ICompanyDepartment } from '@/interfaces/company';
import { IUserProfile } from '@/interfaces';
import { CompanyState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCompanies(state: CompanyState, payload: ICompany[]) {
        state.companies = payload;
    },
    setDepartments(state: CompanyState, payload: ICompanyDepartment[]) {
        state.departments = payload;
        const colorsCount = pallete.length;
        for (let i = 0; i < state.departments.length; i++) {
            state.departments[i].color = pallete[i % colorsCount];
        }
    },
    setDepartmentUsers(state: CompanyState, payload: IUserProfile[]) {
        state.departmentUsers = payload;
    },
    setUsersBase(state: CompanyState, payload: IUserProfile[]) {
        state.usersBase = payload;
    },
};

const { commit } = getStoreAccessors<CompanyState, State>('');

export const pallete = [
    '#FFC107'
    , '#CDDC39'
    , '#00BCD4'
    , '#2196F3'
    , '#EF5350'
    , '#FF9800'
  ];

export const commitSetCompanies = commit(mutations.setCompanies);
export const commitSetDepartments = commit(mutations.setDepartments);
export const commitSetDepartmentUsers = commit(mutations.setDepartmentUsers);
export const commitSetUsersBase = commit(mutations.setUsersBase);
