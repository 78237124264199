import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ServiceState } from './state';
import { IServiceCase } from '@/interfaces/service';

const defaultState: ServiceState = {
    cases: [],
    statusInfo: {},
    stepTypeInfo: {},
    steps: [],
    payers: [],
    report: null,
    servicePriceLists: [],
    operations: [],
};

export const serviceModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
