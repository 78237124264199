import { CompanyState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    companies: (state: CompanyState) => state.companies,
    departments: (state: CompanyState) => state.departments,
    oneDepartment: (state: CompanyState) => (departmentID: number) => {
        const filterDepartments = state.departments.filter((department) => department.id === departmentID);
        if (filterDepartments.length > 0) {
            return { ...filterDepartments[0] };
        }
    },
    departmentUsers: (state: CompanyState) => state.departmentUsers,
    usersBase: (state: CompanyState) => state.usersBase,
};

const { read } = getStoreAccessors<CompanyState, State>('');

export const readCompanies = read(getters.companies);
export const readDepartments = read(getters.departments);
export const readOneDepartment = read(getters.oneDepartment);
export const readDepartmentUsers = read(getters.departmentUsers);
export const readUsersBase = read(getters.usersBase);
