import { api } from '@/api';
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { LTUState } from './state';
import {
    commitSetLTUPriceListMulti
    , commitSetLTUPriceListOne
    , commitRemoveLTUPriceList
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
    commitAddNotification
    , commitRemoveNotification
} from '../main/mutations';
import {
    ILTUPriceList
} from '@/interfaces/ltu'

type MainContext = ActionContext<LTUState, State>;

export const actions = {
    async actionGetLTUPriceListMulti(context: MainContext) {
        try {
            const response = await api.getLTUPriceLists(context.rootState.main.token);
            if (response) {
                commitSetLTUPriceListMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
}

const { dispatch } = getStoreAccessors<LTUState, State>('');

export const dispatchGetLTUPriceListMulti = dispatch(actions.actionGetLTUPriceListMulti);
