import { getStoreAccessors } from 'typesafe-vuex';
import { FinanceState } from './state';
import { State } from '../state';


export const getters = {
    currencies: (state: FinanceState) => state.currencies,
    paymentMethods: (state: FinanceState) => state.paymentMethods,
    taxRateMulti: (state: FinanceState) => state.taxRates,
    taxRateOne: (state: FinanceState) => (taxRatesID: number) => {
        const filteredTaxRates = state.taxRates.filter((taxRates) => taxRates.id === taxRatesID);
        if (filteredTaxRates.length > 0) {
            return { ...filteredTaxRates[0] };
        }
    },
};

const { read } = getStoreAccessors<FinanceState, State>('');

export const readCurrencies = read(getters.currencies);
export const readPaymentMethods = read(getters.paymentMethods);
export const readTaxRateMulti = read(getters.taxRateMulti);
export const readTaxRateOne = read(getters.taxRateOne);
