import {
    IServiceCase
    , IServiceCaseStep
    , IServicePayer
    , IServiceReport
    , IServicePriceList
    , IServiceOperation
} from '@/interfaces/service';
import { ServiceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCases(state: ServiceState, payload: IServiceCase[]) {
        state.cases = payload;
    },
    setStatusInfo(state: ServiceState, payload: { [key: number]: string }) {
        state.statusInfo = payload;
    },
    setCase(state: ServiceState, payload: IServiceCase) {
        const cases = state.cases.filter((_case: IServiceCase) => _case.id !== payload.id);
        cases.push(payload);
        state.cases = cases;
    },
    setStepTypeInfo(state: ServiceState, payload: { [key: number]: string }) {
        state.stepTypeInfo = payload;
    },
    setSteps(state: ServiceState, payload: IServiceCaseStep[]) {
        state.steps = payload;
    },
    setStep(state: ServiceState, payload: IServiceCaseStep) {
        const steps = state.steps.filter((step: IServiceCaseStep) => step.id !== payload.id);
        steps.push(payload);
        state.steps = steps;
    },
    setPayers(state: ServiceState, payload: IServicePayer[]) {
        state.payers = payload;
    },
    setReport(state: ServiceState, payload: IServiceReport | null) {
        state.report = payload;
    },
    setServicePriceLists(state: ServiceState, payload: IServicePriceList[]) {
        state.servicePriceLists = payload;
    },
    setServicePriceListOne(state: ServiceState, payload: IServicePriceList) {
        const servicePriceList = state.servicePriceLists.filter((item: IServicePriceList) => item.id !== payload.id);
        servicePriceList.push(payload);
        state.servicePriceLists = servicePriceList;
    },
    removeServicePriceList(state: ServiceState, payload: IServicePriceList) {
        state.servicePriceLists = state.servicePriceLists.filter(
            (item: IServicePriceList) => (item.id !== payload.id));
    },
    setOperations(state: ServiceState, payload: IServiceOperation[]) {
        state.operations = payload;
    },
};

const { commit } = getStoreAccessors<ServiceState, State>('');

export const commitSetCases = commit(mutations.setCases);
export const commitCaseStatusInfo = commit(mutations.setStatusInfo);
export const commitSetCase = commit(mutations.setCase);
export const commitSetStepTypeInfo = commit(mutations.setStepTypeInfo);
export const commitSetSteps = commit(mutations.setSteps);
export const commitSetStep = commit(mutations.setStep);
export const commitSetPayers = commit(mutations.setPayers);
export const commitSetReport = commit(mutations.setReport);
export const commitSetServicePriceLists = commit(mutations.setServicePriceLists);
export const commitSetServicePriceListOne = commit(mutations.setServicePriceListOne);
export const commitRemoveServicePriceList = commit(mutations.removeServicePriceList);
export const commitSetServiceOperations = commit(mutations.setOperations);
