import axios from 'axios';
import { ResponseType } from 'axios'
import { LocaleMessages } from 'vue-i18n'
import { apiUrl } from '@/env';
import {
  IUserProfile
  , IUserProfileUpdate
  , IUserProfileCreate
  , IActionProfile
  , IActionProfileCreate
  , IUserProfileCompany
  , ISCADAHelios
  , ISCADAHeliosCreate
  , ISCADAHeliosRecord
  , ITask
  , ITaskCreate
  , ITaskProgress
  , IBackendVersion
  , IRole
  , IRoleCreate
  , IRoleUpdate
  , ITaskResult
  , IDataCriteria
  } from './interfaces';
import {
    IMeasurementUnit
    , IRAL
    , IRALCreate
    , IRALUpdate
    , ICountry
} from './interfaces/common'
import {
  IServiceCase
  , IServiceCaseCreate
  , IServiceCaseUpdate
  , IServiceCaseStep
  , IServiceCaseStepCreate
  , IServicePayer
  , IServiceReport
  , IServiceReportCreate
  , IServiceReportUpdate
  , IServicePriceList
  , IServicePriceListCreate
  , IServicePriceListUpdate
  , IServiceOperation
  } from './interfaces/service';
import {
  ILogisticsExpedition
  , ILogisticsExpeditionCreate
  , ILogisticsExpeditionUpdate
  , ILogisticsTransport
} from './interfaces/logistics'
import {
  ICompanyDepartment
  , ICompanyDepartmentCreate
  , ICompanyDepartmentUpdate
  , ICompany
} from './interfaces/company'
import {
   ICRMCustomer
  , ICRMCompany
  , ICRMCompanyCreate
  , ICRMCompanyUpdate
  , ICRMBranch
  , ICRMBranchCreate
  , ICRMBranchUpdate
  , ICRMContactPerson
  , ICRMContactPersonCreate
  , ICRMContactPersonUpdate
  , ICRMOfferTableViev
  , ICRMOfferItem
  , ICRMOfferDiscount
  , ICRMProductGroup
  , ICRMProductGroupCreate
  , ICRMProductGroupUpdate
  , ICRMProduct
  , ICRMProductCreate
  , ICRMPriceList
  , ICRMProductCategory
  , ICRMProductCategoryUpdate
  , ICRMProductSubategoryUpdate
  , ICRMProductSubcategory
  , ICRMPriceListCreate
  , ICRMProductPrice
  , IExportXLSXTask
  , IImportXLSXTask
  , IPopulationTask
  , ISyncTask
  , ISyncTaskResult
  , IPropagationTask
  , IRecalculationTask
  , IRecalculationTaskResult
  , IProductImportXLSXTask
  , IProductTaskResult
  , ICRMPriceListExport
  , ICRMPriceListPopulation
  , ICRMProductVariant
  , ICRMOverheads
  , ICRMProductsExport
  , ICRMPriceListSync
  , ICRMOverheadsUpdate
  , ICRMProductGroupRabat
  , ICRMProductCategorySwap
  , ICRMProductGroupSwap
  , ICRMNumberSeries
  , ICRMNumberSeriesCreate
  , ICRMNumberSeriesUpdate
  , ICRMOfferState
  , ICRMOfferValidity
  , ICRMOffer
  , ICRMOfferCreate
  , ICRMOfferUpdate
  , ICRMOfferItemCreate
  , ICRMOfferItemUpdate
  , ICRMProductSize
  , ICRMProductType
  , ICRMOfferDiscountCreate
  , ICRMOfferDiscountUpdate
  , ICRMProductPriceDiscountGroup
  , ICRMProductPriceDiscountGroupUpdate
  , ICRMOfferSend
  , ICRMOfferMessage
  , ICRMOfferMessageCreate
  , ICRMOfferMessageUpdate
  , ICRMProductPriceSizeType
  , ICRMDiscountMax
  , ICRMOfferItemGrouped
  , ICRMProductComposite
  , ICRMProductCompositeUpdate
  , IRMOfferGroupDefaults
  , IRMOfferGroupDefaultsCreate
  , IRMOfferGroupDefaultsUpdate
  , ICRMCompanyBranch
  , ICRMProductPriceUpdate
  , ICRMProject
  , ICRMProjectCreate
  , ICRMProjectUpdate
  , ICRMOfferItemGAEB
  , ICRMOfferItemGAEBCreate
  , ICRMOfferItemGAEBUpdate
} from './interfaces/crm';
import {
  IFinanceCurrency
  , IFinanceCurrencyUpdate
  , IFinancePaymentMethod
  , IFinancePaymentMethodCreate
  , IFinancePaymentMethodUpdate
  , IFinanceTaxRate
  , IFinanceTaxRateCreate
  , IFinanceTaxRateUpdate
} from './interfaces/finance';
import {
  IImageFile
} from './interfaces/gallery';
import {
  ILTUPriceList
} from './interfaces/ltu';
import {
  ILog
  , ILocaleDictionaryItem
  , ILocaleDictionaryItemCreate
  , ILocaleDictionaryItemUpdate
} from './interfaces/admin';
import {
  IProject
  , IProjectCreate
  , IProjectUpdate
  , ITimesheet
  , ITimesheetCreate
  , ITimesheetUpdate
  , IProjectTask
  , IProjectTaskCreate
  , IProjectTaskUpdate
  , IProjectTaskState
  , IProjectTaskStateCreate
  , IProjectTaskStateUpdate
} from '@/interfaces/project';


function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
}

function config(token: string, params: any) {
  return {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
}

export const api = {
  // ADMIN
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfileCompany[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async getUsersBase(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/base`, authHeaders(token));
  },
  async getUsersByDepartment(token: string, departmentID: number) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/department/${departmentID}?skip=0&limit=-1`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getActions(token: string) {
    return axios.get<IActionProfile[]>(`${apiUrl}/api/v1/actions/?limit=-1`, authHeaders(token));
  },
  async createAction(token: string, data: IActionProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/actions/`, data, authHeaders(token));
  },
  async createActionsMulti(token: string, data: IActionProfileCreate[]) {
    return axios.post(`${apiUrl}/api/v1/actions/batch`, data, authHeaders(token));
  },
  async deleteAction(token: string, data: IActionProfile) {
    return axios.delete(`${apiUrl}/api/v1/actions/${data.id}`, authHeaders(token));
  },
  async getRoles(token: string) {
    return axios.get<IRole[]>(`${apiUrl}/api/v1/roles/?limit=-1`, authHeaders(token));
  },
  async createRole(token: string, data: IRoleCreate) {
    return axios.post(`${apiUrl}/api/v1/roles/`, data, authHeaders(token));
  },
  async updateRole(token: string, roleID: number, data: IRoleUpdate) {
    return axios.put<IRole>(`${apiUrl}/api/v1/roles/${roleID}`, data, authHeaders(token));
  },
  async downloadLocale(token: string) {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'json' as ResponseType,
    };
    return axios.get(`${apiUrl}/api/v1/locale`, config);
  },
  async updateLocale(token: string, data: LocaleMessages) {
    return axios.put<LocaleMessages>(`${apiUrl}/api/v1/locale`, data, authHeaders(token));
  },
  async uploadLocale(token: string, data: FormData) {
    return axios.put(`${apiUrl}/api/v1/locale/upload`, data, authHeaders(token));
  },
  async reloadLocale(token: string) {
    return axios.put(`${apiUrl}/api/v1/locale/reload`, null, authHeaders(token));
  },
  async reloadLocaleDB(token: string) {
    return axios.put(`${apiUrl}/api/v1/locale/reload/db`, null, authHeaders(token));
  },
  async importLocale(token: string) {
    return axios.post(`${apiUrl}/api/v1/locale/import/db`, null, authHeaders(token));
  },
  async getLog(token: string) {
    return axios.get<ILog>(`${apiUrl}/api/v1/dev/log`, authHeaders(token));
  },
  async getLocaleDB(token: string) {
    return axios.get<ILocaleDictionaryItem[]>(`${apiUrl}/api/v1/locale/db`, authHeaders(token));
  },
  async getLocaleDictionaryItem(token: string, data: { itemID: number }) {
      return axios.get<ILocaleDictionaryItem>(`${apiUrl}/api/v1/locale/${data.itemID}`, authHeaders(token));
  },
  async createLocaleDictionaryItem(token: string, data: ILocaleDictionaryItemCreate) {
      return axios.post<ILocaleDictionaryItem>(`${apiUrl}/api/v1/locale/`, data, authHeaders(token));
  },
  async updateLocaleDictionaryItem(token: string, itemID: number, data: ILocaleDictionaryItemUpdate) {
      return axios.put<ILocaleDictionaryItem>(`${apiUrl}/api/v1/locale/${itemID}`, data, authHeaders(token));
  },
  async deleteLocaleDictionaryItem(token: string, data: {itemID: number}) {
      return axios.delete(`${apiUrl}/api/v1/locale/${data.itemID}`, authHeaders(token));
  },
  // ADMIN END

  // Common
  async getMeasurementUnits(token: string) {
    return axios.get<IMeasurementUnit[]>(`${apiUrl}/api/v1/common/measurement_unit/`, authHeaders(token));
  },
  async getCommonRALMulti(token: string) {
    return axios.get<IRAL[]>(`${apiUrl}/api/v1/common/ral/?limit=-1`, authHeaders(token));
  },
  async getCommonRAL(token: string, data: { ralID: number }) {
      return axios.get<IRAL>(`${apiUrl}/api/v1/common/ral/${data.ralID}`, authHeaders(token));
  },
  async createCommonRAL(token: string, data: IRALCreate) {
      return axios.post<IRAL>(`${apiUrl}/api/v1/common/ral/`, data, authHeaders(token));
  },
  async updateCommonRAL(token: string, ralID: number, data: IRALUpdate) {
      return axios.put<IRAL>(`${apiUrl}/api/v1/common/ral/${ralID}`, data, authHeaders(token));
  },
  async deleteCommonRAL(token: string, data: {id: number}) {
      return axios.delete(`${apiUrl}/api/v1/common/ral/${data.id}`, authHeaders(token));
  },
  async getCommonCountries(token: string) {
    const config = { params: { limit: -1 }, headers: authHeaders(token).headers }
    return axios.get<ICountry[]>(`${apiUrl}/api/v1/common/country`, config);
  },
  // Common END

  // SCADA
  async getHeliosDevices(token: string) {
    return axios.get(`${apiUrl}/api/v1/scada/helios`, authHeaders(token));
  },
  async createHelios(token: string, data: ISCADAHeliosCreate) {
    return axios.post(`${apiUrl}/api/v1/scada/helios`, data, authHeaders(token));
  },
  async updateHelios(token: string, data: ISCADAHelios) {
    return axios.put(`${apiUrl}/api/v1/scada/helios/${data.id}`, data, authHeaders(token));
  },
  async deleteHelios(token: string, data: ISCADAHelios) {
    return axios.delete(`${apiUrl}/api/v1/scada/helios/${data.id}`, authHeaders(token));
  },
  async getHeliosRecords(token: string) {
    return axios.get(`${apiUrl}/api/v1/scada/helios/record/all`, authHeaders(token));
  },
  async getOneHeliosRecord(token: string, data: ISCADAHeliosRecord) {
    let config = authHeaders(token);
    //config['params'] = {limit: data.count};
    return axios.get(`${apiUrl}/api/v1/scada/helios/record/${data.timestamp}`, config);
  },
  async getOneHeliosRecordReduced(token: string, data: ISCADAHeliosRecord) {
    let config = authHeaders(token);
    //config['params'] = {limit: data.count};
    return axios.get(`${apiUrl}/api/v1/scada/helios/record/${data.timestamp}/reduced`, config);
  },
  async uploadHeliosRecord(token: string, data: FormData) {
    let config = authHeaders(token);
    //config['headers']['Content-Type'] = 'multipart/form-data';
    return axios.post(`${apiUrl}/api/v1/scada/helios/record/upload`, data, authHeaders(token));
  },
  // SCADA

  // task management
  async getTasks(token: string) {
    return axios.get(`${apiUrl}/api/v1/tasks`, authHeaders(token));
  },
  async getSchedule(token: string) {
    return axios.get(`${apiUrl}/api/v1/tasks/schedule`, authHeaders(token));
  },
  async getLastTaskResult(token: string, data: { type: number }) {
    return axios.get(`${apiUrl}/api/v1/tasks/result/last/${data.type}`, authHeaders(token));
  },
  async getTaskResult(token: string, data: { task_id: number }) {
    return axios.get<ITaskResult[]>(`${apiUrl}/api/v1/tasks/results/${data.task_id}`, authHeaders(token));
  },
  async getTaskProgress(token: string, data: ITaskProgress) {
    return axios.get<ITaskProgress>(`${apiUrl}/api/v1/tasks/task/${data.task_id}/progress`, authHeaders(token));
  },
  async getTaskStatusInfo(token: string) {
    return axios.get(`${apiUrl}/api/v1/tasks/statuses`, authHeaders(token));
  },
  async getTaskTypes(token: string) {
    return axios.get(`${apiUrl}/api/v1/tasks/available`, authHeaders(token));
  },
  async getTaskWorkers(token: string) {
    return axios.get(`${apiUrl}/api/v1/workers`, authHeaders(token));
  },
  async addTask(token: string, data: ITaskCreate) {
    return axios.put<ITask>(`${apiUrl}/api/v1/tasks/add`, data, authHeaders(token));
  },
  async addTaskType(token: string, data: { task_type: number, formData: FormData }) {
    return axios.put<ITask>(`${apiUrl}/api/v1/tasks/add/${data.task_type}`, data.formData, authHeaders(token));
  },
  async getTask(token: string, data: { task_id: number }) {
    return axios.get<ITask>(`${apiUrl}/api/v1/tasks/task/${data.task_id}`, authHeaders(token));
  },
  async downloadTaskFile(token: string, data: ITask) {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob' as ResponseType,
    };
    return axios.get(`${apiUrl}/api/v1/tasks/task/${data.id}/download`, config);
  },
  // task management END

  // module Service
  async getServiceCases(token: string) {
    return axios.get<IServiceCase[]>(`${apiUrl}/api/v1/service/case`, authHeaders(token));
  },
  async getServiceCase(token: string, caseID: number) {
    return axios.get<IServiceCase>(`${apiUrl}/api/v1/service/case/${caseID}`, authHeaders(token));
  },
  async createServiceCase(token: string, data: IServiceCaseCreate) {
    return axios.post(`${apiUrl}/api/v1/service/case`, data, authHeaders(token));
  },
  async updateServiceCase(token: string, caseID: number,  data: IServiceCaseUpdate) {
    return axios.put(`${apiUrl}/api/v1/service/case/${caseID}`, data, authHeaders(token));
  },
  async deleteServiceCase(token: string, data: {id: number}) {
    return axios.delete(`${apiUrl}/api/v1/service/case/${data.id}`, authHeaders(token));
  },
  async getCaseStatusInfo(token: string) {
    return axios.get(`${apiUrl}/api/v1/service/info/statuses`, authHeaders(token));
  },
  async getStepTypeInfo(token: string) {
    return axios.get(`${apiUrl}/api/v1/service/info/step/types`, authHeaders(token));
  },
  async getBackendVersion(token: string) {
    return axios.get<IBackendVersion>(`${apiUrl}/api/v1/version`, authHeaders(token));
  },
  async createServiceCaseStep(token: string, data: IServiceCaseStepCreate) {
    return axios.post(`${apiUrl}/api/v1/service/step`, data, authHeaders(token));
  },
  async getServiceCaseStep(token: string, stepID: number) {
    return axios.get<IServiceCase>(`${apiUrl}/api/v1/service/step/${stepID}`, authHeaders(token));
  },
  async deleteServiceCaseStep(token: string, data: {id: number}) {
    return axios.delete(`${apiUrl}/api/v1/service/step/${data.id}`, authHeaders(token));
  },
  async getServiceCaseSteps(token: string, caseID: number) {
    return axios.get<IServiceCaseStep[]>(`${apiUrl}/api/v1/service/step/by_case/${caseID}`, authHeaders(token));
  },
  async getServicePayers(token: string) {
    return axios.get<IServicePayer[]>(`${apiUrl}/api/v1/service/payer`, authHeaders(token));
  },
  async getServiceReports(token: string) {
    return axios.get<IServiceReport[]>(`${apiUrl}/api/v1/service/report`, authHeaders(token));
  },
  async getServiceReport(token: string, caseID: number) {
    return axios.get<IServiceReport>(`${apiUrl}/api/v1/service/report/${caseID}`, authHeaders(token));
  },
  async createServiceReport(token: string, data: IServiceReportCreate) {
    return axios.post(`${apiUrl}/api/v1/service/report`, data, authHeaders(token));
  },
  async updateServiceReport(token: string, reportID: number,  data: IServiceReportUpdate) {
    return axios.put(`${apiUrl}/api/v1/service/report/${reportID}`, data, authHeaders(token));
  },
  async deleteServiceReport(token: string, data: {id: number}) {
    return axios.delete(`${apiUrl}/api/v1/service/report/${data.id}`, authHeaders(token));
  },
  async getServicePriceLists(token: string) {
      return axios.get<IServicePriceList[]>(`${apiUrl}/api/v1/service/price_list/?limit=-1`, authHeaders(token));
  },
  async getServicePriceListOne(token: string, data: { servicepricelistID: number }) {
      return axios.get<IServicePriceList>(`${apiUrl}/api/v1/service/price_list/${data.servicepricelistID}`, authHeaders(token));
  },
  async createServicePriceList(token: string, data: IServicePriceListCreate) {
      return axios.post<IServicePriceList>(`${apiUrl}/api/v1/service/price_list/`, data, authHeaders(token));
  },
  async updateServicePriceList(token: string, servicepricelistID: number, data: IServicePriceListUpdate) {
      return axios.put<IServicePriceList>(`${apiUrl}/api/v1/service/price_list/${servicepricelistID}`, data, authHeaders(token));
  },
  async deleteServicePriceList(token: string, data: {id: number}) {
      return axios.delete(`${apiUrl}/api/v1/service/price_list/${data.id}`, authHeaders(token));
  },
  async getServiceOpeations(token: string) {
    return axios.get<IServiceOperation[]>(`${apiUrl}/api/v1/service/operation/?limit=-1`, authHeaders(token));
  },
  // module Service END

  // module logistics
  async getLogisticsExpeditions(token: string) {
    return axios.get<ILogisticsExpedition[]>(`${apiUrl}/api/v1/logistics/expedition`, authHeaders(token));
  },
  async getLogisticsExpedition(token: string, expeditionID: number) {
    return axios.get<ILogisticsExpedition>(`${apiUrl}/api/v1/logistics/expedition/${expeditionID}`, authHeaders(token));
  },
  async createLogisticsExpedition(token: string, data: ILogisticsExpeditionCreate) {
    return axios.post(`${apiUrl}/api/v1/logistics/expedition`, data, authHeaders(token));
  },
  async updateLogisticsExpedition(token: string, expeditionID: number,  data: ILogisticsExpeditionUpdate) {
    return axios.put(`${apiUrl}/api/v1/logistics/expedition/${expeditionID}`, data, authHeaders(token));
  },
  async deleteLogisticsExpedition(token: string, data: {id: number}) {
    return axios.delete(`${apiUrl}/api/v1/logistics/expedition/${data.id}`, authHeaders(token));
  },
  async getAHUTranFile(token: string, fileID: string) {
    return axios.get<Blob>(`${apiUrl}/api/v1/downloads/${fileID}`, authHeaders(token));
  },
  async uploadAHUTranFile(token: string, data: FormData) {
    //let config = authHeaders(token);
    //config['headers']['Content-Type'] = 'multipart/form-data';
    return axios.post(`${apiUrl}/api/v1/logistics/ahutran/upload`, data, authHeaders(token));
  },
  async getLogisticsTransports(token: string) {
    return axios.get<ILogisticsTransport[]>(`${apiUrl}/api/v1/logistics/transport`, authHeaders(token));
  },
  // module logistics END

  // module company
  async getCompanies(token: string) {
    return axios.get<ICompany[]>(`${apiUrl}/api/v1/company/company`, authHeaders(token));
  },
  async getCompanyDepartments(token: string) {
    return axios.get<ICompanyDepartment[]>(`${apiUrl}/api/v1/company/department`, authHeaders(token));
  },
  async getCompanyDepartment(token: string, expeditionID: number) {
    return axios.get<ICompanyDepartment>(`${apiUrl}/api/v1/company/department/${expeditionID}`, authHeaders(token));
  },
  async createCompanyDepartment(token: string, data: ICompanyDepartmentCreate) {
    return axios.post(`${apiUrl}/api/v1/company/department`, data, authHeaders(token));
  },
  async updateCompanyDepartment(token: string, expeditionID: number,  data: ICompanyDepartmentUpdate) {
    return axios.put(`${apiUrl}/api/v1/company/department/${expeditionID}`, data, authHeaders(token));
  },
  async deleteCompanyDepartment(token: string, data: {id: number}) {
    return axios.delete(`${apiUrl}/api/v1/company/department/${data.id}`, authHeaders(token));
  },
  // module company END

  // module CRM
  async getCRMCustomers(token: string) {
    return axios.get<ICRMCustomer[]>(`${apiUrl}/api/v1/crm/customer/?limit=-1`, authHeaders(token));
  },
  async getCRMCompanies(token: string, active: number = 1) {
    return axios.get<ICRMCompany[]>(`${apiUrl}/api/v1/crm/company/?limit=-1&active=${active}`, authHeaders(token));
  },
  async getCRMCompaniesFull(token: string, active: number = 1) {
    const config = { params: { limit: -1, active: active}, headers: authHeaders(token).headers }
    return axios.get<ICRMCompany[]>(`${apiUrl}/api/v1/crm/company/full`, config);
  },
  async getCRMOneCompany(token: string, data: { companyID: number}) {
    return axios.get<ICRMCompany>(`${apiUrl}/api/v1/crm/company/${data.companyID}`, authHeaders(token));
  },
  async getCRMCompanyARES(token: string, ico: string) {
    return axios.get<ICRMCompany>(`${apiUrl}/api/v1/crm/company/ares/${ico}`, authHeaders(token));
  },
  async createCRMCompany(token: string, data: ICRMCompanyCreate) {
    return axios.post<ICRMCompany>(`${apiUrl}/api/v1/crm/company/`, data, authHeaders(token));
  },
  async createCRMCompanyBranch(token: string, data: ICRMCompanyCreate) {
    return axios.post<ICRMCompanyBranch>(`${apiUrl}/api/v1/crm/company/branch`, data, authHeaders(token));
  },
  async updateCRMCompany(token: string, companyID: number, data: ICRMCompanyUpdate) {
    return axios.put<ICRMCompany>(`${apiUrl}/api/v1/crm/company/${companyID}`, data, authHeaders(token));
  },
  async getCRMBranches(token: string, active: number = 1) {
    return axios.get<ICRMBranch[]>(`${apiUrl}/api/v1/crm/branch/?limit=-1&active=${active}`, authHeaders(token));
  },
  async createCRMBranch(token: string, data: ICRMBranchCreate) {
    return axios.post<ICRMBranch>(`${apiUrl}/api/v1/crm/branch/`, data, authHeaders(token));
  },
  async updateCRMBranch(token: string, branchID: number, data: ICRMBranchUpdate) {
    return axios.put<ICRMBranch>(`${apiUrl}/api/v1/crm/branch/${branchID}`, data, authHeaders(token));
  },
  async getCRMContactPersons(token: string, active: number = 1) {
    return axios.get<ICRMContactPerson[]>(`${apiUrl}/api/v1/crm/contact_person/?limit=-1&active=${active}`, authHeaders(token));
  },
  async getCRMContactPersonsFull(token: string, active: number = 1) {
    return axios.get<ICRMContactPerson[]>(`${apiUrl}/api/v1/crm/contact_person/full/?limit=-1&active=${active}`, authHeaders(token));
  },
  async getCRMOneContactPersonFull(token: string, contactID: number) {
    return axios.get<ICRMContactPerson>(`${apiUrl}/api/v1/crm/contact_person/full/${contactID}`, authHeaders(token));
  },
  async createCRMContactPerson(token: string, data: ICRMContactPersonCreate) {
    return axios.post<ICRMContactPerson>(`${apiUrl}/api/v1/crm/contact_person/`, data, authHeaders(token));
  },
  async updateCRMContactPerson(token: string, contactID: number, data: ICRMContactPersonUpdate) {
    return axios.put<ICRMContactPerson>(`${apiUrl}/api/v1/crm/contact_person/${contactID}`, data, authHeaders(token));
  },
  async getCRMProductCategories(token: string) {
    return axios.get<ICRMProductCategory[]>(`${apiUrl}/api/v1/crm/product_category/?limit=-1`, authHeaders(token));
  },
  async updateCRMProductCategory(token: string, categoryID: number, data: ICRMProductCategoryUpdate) {
    return axios.put<ICRMProductCategory>(`${apiUrl}/api/v1/crm/product_category/${categoryID}`, data, authHeaders(token));
  },
  async swapCRMProductCategoriesIndex(token: string, data: ICRMProductCategorySwap) {
    return axios.put<ICRMProductCategory[]>(`${apiUrl}/api/v1/crm/product_category/swap`, data, authHeaders(token));
  },
  async getCRMProductSubcategories(token: string) {
    return axios.get<ICRMProductSubcategory[]>(`${apiUrl}/api/v1/crm/product_subcategory/?limit=-1`, authHeaders(token));
  },
  async updateCRMProductSubcategory(token: string, subcategoryID: number, data: ICRMProductSubategoryUpdate) {
    return axios.put<ICRMProductSubcategory>(`${apiUrl}/api/v1/crm/product_subcategory/${subcategoryID}`, data, authHeaders(token));
  },
  async getCRMProductGroups(token: string, only_active: boolean) {
    if (only_active) {
      return axios.get<ICRMProductGroup[]>(`${apiUrl}/api/v1/crm/product_group/?limit=-1`, authHeaders(token));
    }
    return axios.get<ICRMProductGroup[]>(`${apiUrl}/api/v1/crm/product_group/?limit=-1&active=0`, authHeaders(token));
  },
  async getCRMProductGroupWithLinks(token: string, group_id: number) {
    return axios.get<ICRMProductGroup>(`${apiUrl}/api/v1/crm/product_group/${group_id}/links`, authHeaders(token));
  },
  async getCRMNonEmptyProductGroups(token: string) {
      return axios.get<ICRMProductGroup[]>(`${apiUrl}/api/v1/crm/product_group/non_empty`, authHeaders(token));
  },
  async createCRMProductGroup(token: string, data: ICRMProductGroupCreate) {
    return axios.post<ICRMProductGroup>(`${apiUrl}/api/v1/crm/product_group/`, data, authHeaders(token));
  },
  async updateCRMProductGroup(token: string, groupID: number, data: ICRMProductGroupUpdate) {
    return axios.put<ICRMProductGroup>(`${apiUrl}/api/v1/crm/product_group/${groupID}`, data, authHeaders(token));
  },
  async swapCRMProductGroupsIndex(token: string, data: ICRMProductGroupSwap) {
    console.log(data);
    return axios.put<ICRMProductGroup[]>(`${apiUrl}/api/v1/crm/product_group/swap`, data, authHeaders(token));
  },
  async getCRMProductVariants(token: string) {
    return axios.get<ICRMProductVariant[]>(`${apiUrl}/api/v1/crm/product_variant/?limit=-1`, authHeaders(token));
  },
  async getCRMProducts(token: string) {
    return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/?limit=1000`, authHeaders(token));
  },
  async getCRMSelectedProduct(token: string, productID: number) {
    return axios.get<ICRMProduct>(`${apiUrl}/api/v1/crm/product/${productID}`, authHeaders(token));
  },
  async getCRMProductsCount(
      token: string
      , groupID: number
      , search: string
  ) {
    const params = { search: search }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get(`${apiUrl}/api/v1/crm/product/group/${groupID}/size/`, config);
  },
  async createCRMProduct(token: string, data: ICRMProductCreate) {
    return axios.post<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/`, data, authHeaders(token));
  },
  async getCRMProductsByGroup(token: string, data: ICRMProductGroup, criteria: IDataCriteria, compositeOnly: boolean = false) {
    const params = {
        limit: criteria.limit
        , skip: criteria.skip
        , search: criteria.search
        , order: criteria.order
        , composite_only: compositeOnly
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/group/${data.id}`, config);
  },
  async searchCRMProductsByGroup(token: string, groupID: number, limit: number = 100, skip: number = 0, search: string = '') {
    // const params = { limit: limit, skip: skip, search: search }
    // const config = { params: params, headers: authHeaders(token).headers }
    // return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/group/${groupID}/search`, config);
    const data: { limit: number, skip: number, search: string } = { limit: limit, skip: skip, search: search }
    return axios.post<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/group/${groupID}/search`, data, authHeaders(token));
  },
  async getCRMProductsByGroupFull(token: string, data: ICRMProductGroup, criteria: IDataCriteria) {
    const params = {
      limit: criteria.limit
      , skip: criteria.skip
      , search: criteria.search
      , order: criteria.order
      //, order: 'id'
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/group/${data.id}/full/`, config);
  },
  async getCRMProductsByGroupAttributes(token: string, data: ICRMProductGroup, criteria: IDataCriteria) {
    const params = {
      limit: criteria.limit
      , skip: criteria.skip
      , search: criteria.search
      , order: criteria.order
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/group/${data.id}/attribute/`, config);
  },
  async getCRMOneProductWithAttributes(token: string, productID: number) {
    return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/${productID}/attribute/`, authHeaders(token));
  },
  async getCRMProductComposite(token: string, productCompositeID: number) {
    return axios.get<ICRMProductComposite>(`${apiUrl}/api/v1/crm/product/${productCompositeID}/composite`, authHeaders(token));
  },
  async updateCRMProductComposite(token: string, productCompositeID: number, data: ICRMProductCompositeUpdate) {
    return axios.put<ICRMProductComposite>(`${apiUrl}/api/v1/crm/product/${productCompositeID}/composite`, data, authHeaders(token));
  },
  async getCRMOffersTable(token: string, year: number) {
    return axios.get<ICRMOfferTableViev[]>(`${apiUrl}/api/v1/crm/offer/view?skip=0&limit=-1&year=${year}`, authHeaders(token));
  },
  async getCRMOfferItems(token: string, offerID: number) {
    return axios.get<ICRMOfferItem[]>(`${apiUrl}/api/v1/crm/offer/item/offer/${offerID}`, config(token, { limit: -1 }));
  },
  async getCRMOfferItemsGrouped(token: string, offerID: number) {
    return axios.get<ICRMOfferItemGrouped[]>(`${apiUrl}/api/v1/crm/offer/item/offer/${offerID}/grouped`, config(token, { limit: -1 }));
  },
  async getCRMOneOfferItemsGroup(token: string, offerID: number, groupID: number) {
    return axios.get<ICRMOfferItem[]>(`${apiUrl}/api/v1/crm/offer/item/offer/${offerID}/grouped/${groupID}`, config(token, { limit: -1 }));
  },
  async getCRMOfferDiscounts(token: string, offerID: number) {
    return axios.get<ICRMOfferDiscount[]>(`${apiUrl}/api/v1/crm/offer/discount/offer/${offerID}`, config(token, { limit: -1 }));
  },
  async getCRMOfferDiscountsCalculated(token: string, offerID: number) {
    return axios.get<ICRMOfferDiscount[]>(`${apiUrl}/api/v1/crm/offer/discount/offer/${offerID}/calculated`, config(token, { limit: -1, sort: true }));
  },
  async getCRMPriceList(token: string) {
    return axios.get<ICRMPriceList[]>(`${apiUrl}/api/v1/crm/product_price_list`, authHeaders(token));
  },
  async getCRMOnePriceList(token: string, data: { id: number }) {
    return axios.get<ICRMPriceList>(`${apiUrl}/api/v1/crm/product_price_list/${data.id}`, authHeaders(token));
  },
  async isCRMPriceListEmpty(token: string, data: { id: number }) {
    return axios.get(`${apiUrl}/api/v1/crm/product_price_list/${data.id}/is_empty`, authHeaders(token));
  },
  async createCRMPriceList(token: string, data: ICRMPriceListCreate) {
    return axios.post(`${apiUrl}/api/v1/crm/product_price_list`, data, authHeaders(token));
  },
  async generateCRMPriceList(token: string, data: { listID: number } ) {
    return axios.post(`${apiUrl}/api/v1/crm/product_price_list/generate/${data.listID}`, data, authHeaders(token));
  },
  async getCRMPriceListGroups(token: string, data: { listID: number }) {
    return axios.get<ICRMProductGroup[]>(`${apiUrl}/api/v1/crm/product_price_list/${data.listID}/groups`, authHeaders(token));
  },
  async getCRMProductPrice(
      token: string
      , listID: number
      , groupID: number
      , criteria: IDataCriteria
  ) {
    const params = {
      limit: criteria.limit
      , skip: criteria.skip
      , search: criteria.search
      , order: criteria.order
      , group: groupID
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProductPrice[]>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/`
      , config
    );
  },
  async getCRMProductPriceDealer(
      token: string
      , listID: number
      , groupID: number
      , criteria: IDataCriteria
  ) {
    const params = {
      limit: criteria.limit
      , skip: criteria.skip
      , search: criteria.search
      , order: criteria.order
      , group: groupID
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProductPrice[]>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/dealer/`
      , config
    );
  },
  async getCRMProductPriceDA(
      token: string
      , listID: number
      , groupID: number
      , criteria: IDataCriteria
  ) {
    const params = {
      limit: criteria.limit
      , skip: criteria.skip
      , search: criteria.search
      , order: criteria.order
      , group: groupID
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProductPrice[]>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/da/`
      , config
    );
  },
  async getCRMProductPriceCRMA(
      token: string
      , listID: number
      , groupID: number
      , criteria: IDataCriteria
  ) {
    const params = {
      limit: criteria.limit
      , skip: criteria.skip
      , search: criteria.search
      , order: criteria.order
      , group: groupID
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProductPrice[]>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/crma/`
      , config
    );
  },
  async getCRMProductPricesCount(
      token: string
      , listID: number
      , groupID: number
      , search: string
  ) {
    const params = {
      search: search
      , group_id: groupID
    }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get(`${apiUrl}/api/v1/crm/product_price/list/${listID}/size/`, config);
  },
  async getCRMOneProductPrice(token: string, productPrice: ICRMProductPrice) {
    return axios.get<ICRMProductPrice>(`${apiUrl}/api/v1/crm/product_price/${productPrice.id}`, authHeaders(token));
  },
  async startPriceListXLSXExport(token: string, data: ICRMPriceListExport) {
    return axios.post<IExportXLSXTask>(`${apiUrl}/api/v1/crm/product_price_list/export/`, data, authHeaders(token));
  },
  async getPriceListXLSXExportStatus(token: string, taskID: number) {
    return axios.get<IExportXLSXTask>(`${apiUrl}/api/v1/crm/product_price_list/export/status/${taskID}`, authHeaders(token));
  },
  async downloadPriceListXLSX(token: string, taskID: number) {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob' as ResponseType,
    };
    return axios.get<Blob>(`${apiUrl}/api/v1/crm/product_price_list/export/download/${taskID}`, config);
  },
  async startPriceListXLSXImport(token: string, data: { listID: number, formData: FormData }) {
    return axios.post<IImportXLSXTask>(`${apiUrl}/api/v1/crm/product_price_list/import/${data.listID}`, data.formData, authHeaders(token));
  },
  async getPriceListXLSXImportStatus(token: string, taskID: number) {
    return axios.get<IImportXLSXTask>(`${apiUrl}/api/v1/crm/product_price_list/import/status/${taskID}`, authHeaders(token));
  },
  async startPriceListPopulation(token: string, data: ICRMPriceListPopulation) {
    return axios.post<IPopulationTask>(`${apiUrl}/api/v1/crm/product_price_list/populate/`, data, authHeaders(token));
  },
  async getPriceListPopulationStatus(token: string, taskID: number) {
    return axios.get<IPopulationTask>(`${apiUrl}/api/v1/crm/product_price_list/populate/status/${taskID}`, authHeaders(token));
  },
  async startPriceListSync(token: string, data: ICRMPriceListSync) {
    return axios.post<ISyncTask>(`${apiUrl}/api/v1/crm/product_price_list/sync/`, data, authHeaders(token));
  },
  async getPriceListSyncStatus(token: string, taskID: number) {
    return axios.get<ISyncTask>(`${apiUrl}/api/v1/crm/product_price_list/sync/status/${taskID}`, authHeaders(token));
  },
  async startPriceListPropagation(token: string, data: { 'list_id': number }) {
    return axios.post<IPropagationTask>(`${apiUrl}/api/v1/crm/product_price_list/propagate/${data.list_id}`, data, authHeaders(token));
  },
  async getPriceListPropagationStatus(token: string, taskID: number) {
    return axios.get<IPropagationTask>(`${apiUrl}/api/v1/crm/product_price_list/propagate/status/${taskID}`, authHeaders(token));
  },
  async startPriceListRecalculation(token: string, data: { 'list_id': number }) {
    return axios.post<IRecalculationTask>(`${apiUrl}/api/v1/crm/product_price_list/recalculate/${data.list_id}`, data, authHeaders(token));
  },
  async startAllPriceListRecalculation(token: string) {
    return axios.post<IRecalculationTask>(`${apiUrl}/api/v1/crm/product_price_list/recalculate/`, {}, authHeaders(token));
  },
  async getPriceListRecalculationStatus(token: string, taskID: number) {
    return axios.get<IRecalculationTask>(`${apiUrl}/api/v1/crm/product_price_list/recalculate/status/${taskID}`, authHeaders(token));
  },
  async getPriceListRecalculationResult(token: string, taskID: number) {
    return axios.get<IRecalculationTaskResult>(`${apiUrl}/api/v1/crm/product_price_list/recalculate/result/${taskID}`, authHeaders(token));
  },
  async getPriceListSyncResult(token: string, taskID: number) {
    return axios.get<ISyncTaskResult>(`${apiUrl}/api/v1/crm/product_price_list/sync/result/${taskID}`, authHeaders(token));
  },
  async getCRMOverheads(token: string) {
    return axios.get<ICRMOverheads[]>(`${apiUrl}/api/v1/crm/product_price_list/overheads/?limit=-1`, authHeaders(token));
  },
  async updateCRMOverheads(token: string, data: ICRMOverheadsUpdate) {
    return axios.put<ICRMOverheads>(`${apiUrl}/api/v1/crm/product_price_list/overheads/${data.id}`, data, authHeaders(token));
  },
  async startProductsXLSXExport(token: string, data: ICRMProductsExport) {
    return axios.post<IExportXLSXTask>(`${apiUrl}/api/v1/crm/product/export/`, data, authHeaders(token));
  },
  async getProductsXLSXExportStatus(token: string, taskID: number) {
    return axios.get<IExportXLSXTask>(`${apiUrl}/api/v1/crm/product/export/status/${taskID}`, authHeaders(token));
  },
  async downloadProductsXLSX(token: string, taskID: number) {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob' as ResponseType,
    };
    return axios.get<Blob>(`${apiUrl}/api/v1/crm/product/export/download/${taskID}`, config);
  },
  async startProductXLSXImport(token: string, data: { formData: FormData }) {
    return axios.post<IImportXLSXTask>(`${apiUrl}/api/v1/crm/product/import/`, data.formData, authHeaders(token));
  },
  async startProductXLSXImportCheck(token: string, data: { formData: FormData }) {
    return axios.post<IImportXLSXTask>(`${apiUrl}/api/v1/crm/product/import/check`, data.formData, authHeaders(token));
  },
  async getProductXLSXImportStatus(token: string, taskID: number) {
    return axios.get<IImportXLSXTask>(`${apiUrl}/api/v1/crm/product/import/status/${taskID}`, authHeaders(token));
  },
  async getProductXLSXImportResult(token: string, taskID: number) {
    return axios.get<IProductTaskResult>(`${apiUrl}/api/v1/crm/product/import/result/${taskID}`, authHeaders(token));
  },
  async getCRMGroupRabats(token: string, companyID: number) {
    return axios.get<ICRMProductGroupRabat[]>(`${apiUrl}/api/v1/crm/product_group/rabat/company/${companyID}?skip=0&limit=-1`, authHeaders(token));
  },
  async createCRMGroupRabat(token: string, data: ICRMProductGroupRabat) {
    return axios.post<ICRMProductGroupRabat>(`${apiUrl}/api/v1/crm/product_group/rabat/`, data, authHeaders(token));
  },
  async updateCRMGroupRabat(token: string, data: ICRMProductGroupRabat) {
    return axios.put<ICRMProductGroupRabat>(`${apiUrl}/api/v1/crm/product_group/rabat/`, data, authHeaders(token));
  },
  async deleteCRMGroupRabat(token: string, data: ICRMProductGroupRabat) {
    return axios.delete(`${apiUrl}/api/v1/crm/product_group/rabat/${data.company_id}?group_id=${data.group_id}`, authHeaders(token));
  },
  async getCRMNumberSeries(token: string) {
    return axios.get<ICRMNumberSeries[]>(`${apiUrl}/api/v1/crm/number_series/?skip=0&limit=-1`, authHeaders(token));
  },
  async useCRMNumberSeries(token: string) {
    return axios.get<ICRMNumberSeries>(`${apiUrl}/api/v1/crm/number_series/use`, authHeaders(token));
  },
  async createCRMNumberSeries(token: string, data: ICRMNumberSeriesCreate) {
    return axios.post<ICRMNumberSeries>(`${apiUrl}/api/v1/crm/number_series/`, data, authHeaders(token));
  },
  async updateCRMNumberSeries(token: string, seriesID: number, data: ICRMNumberSeriesUpdate) {
    return axios.put<ICRMNumberSeries>(`${apiUrl}/api/v1/crm/number_series/${seriesID}`, data, authHeaders(token));
  },
  async deleteCRMNumberSeries(token: string, data: ICRMNumberSeries) {
    return axios.delete(`${apiUrl}/api/v1/crm/number_series/${data.id}`, authHeaders(token));
  },
  async getCRMOfferState(token: string) {
    return axios.get<ICRMOfferState[]>(`${apiUrl}/api/v1/crm/offer/state/?skip=0&limit=-1`, authHeaders(token));
  },
  async getCRMOfferValidity(token: string) {
    return axios.get<ICRMOfferValidity[]>(`${apiUrl}/api/v1/crm/offer/validity/?skip=0&limit=-1`, authHeaders(token));
  },
  async getCRMOffers(token: string) {
    return axios.get<ICRMOffer[]>(`${apiUrl}/api/v1/crm/offer/?skip=0&limit=-1`, authHeaders(token));
  },
  async getCRMOneOffer(token: string, offerID: number) {
    return axios.get<ICRMOffer>(`${apiUrl}/api/v1/crm/offer/${offerID}`, authHeaders(token));
  },
  async getCRMOneOfferCalculated(token: string, offerID: number) {
    return axios.get<ICRMOffer>(`${apiUrl}/api/v1/crm/offer/${offerID}/calculated`, authHeaders(token));
  },
  async getCRMOffersByYear(token: string, year: number) {
    return axios.get<ICRMOffer[]>(`${apiUrl}/api/v1/crm/offer/year/${year}?skip=0&limit=-1`, authHeaders(token));
  },
  async getCRMOffersYearSeries(token: string) {
    return axios.get<number[]>(`${apiUrl}/api/v1/crm/offer/year/series`, authHeaders(token));
  },
  async createCRMOffer(token: string, data: ICRMOfferCreate) {
    return axios.post<ICRMOffer>(`${apiUrl}/api/v1/crm/offer/`, data, authHeaders(token));
  },
  async updateCRMOffer(token: string, offerID: number, data: ICRMOfferUpdate) {
    return axios.put<ICRMOffer>(`${apiUrl}/api/v1/crm/offer/${offerID}`, data, authHeaders(token));
  },
  async updateCRMOfferRecalculate(token: string, offerID: number, data: ICRMOfferUpdate) {
    return axios.put<ICRMOffer>(`${apiUrl}/api/v1/crm/offer/${offerID}/recalculate`, data, authHeaders(token));
  },
  async deleteCRMOffer(token: string, data: ICRMOffer) {
    return axios.delete(`${apiUrl}/api/v1/crm/offer/${data.id}`, authHeaders(token));
  },
  async sendCRMOffer(token: string, offerID: number, data: ICRMOfferSend) {
    return axios.put<ICRMOffer>(`${apiUrl}/api/v1/crm/offer/${offerID}/send`, data, authHeaders(token));
  },
  async getCRMOfferProductGroups(token: string, listID: number) {
    return axios.get<ICRMProductGroup[]>(`${apiUrl}/api/v1/crm/product_group/price_list/${listID}`, authHeaders(token));
  },
  async createCRMOfferItem(token: string, data: ICRMOfferItemCreate, discount: boolean = false) {
    if (discount) {
      return axios.post<ICRMOfferItem>(`${apiUrl}/api/v1/crm/offer/item/discount`, data, authHeaders(token));
    }
    return axios.post<ICRMOfferItem>(`${apiUrl}/api/v1/crm/offer/item/`, data, authHeaders(token));
  },
  async copyCRMOfferItem(token: string, itemID: number, count: number) {
    return axios.post<ICRMOfferItem[]>(`${apiUrl}/api/v1/crm/offer/item/copy/${itemID}/${count}`, {}, authHeaders(token));
  },
  async copyCRMOfferItemComposite(token: string, itemID: number, count: number) {
    return axios.post<ICRMOfferItem[]>(
      `${apiUrl}/api/v1/crm/offer/item/copy/${itemID}/${count}/composite`
      , {}
      , authHeaders(token)
    );
  },
  async updateCRMOfferItem(token: string, offerItemID: number, data: ICRMOfferItemUpdate) {
    return axios.put<ICRMOfferItem>(`${apiUrl}/api/v1/crm/offer/item/${offerItemID}/calc`, data, authHeaders(token));
  },
  async updateCRMOfferItemGrouped(token: string, offerItemID: number, data: ICRMOfferItemUpdate) {
    return axios.put<ICRMOfferItem>(`${apiUrl}/api/v1/crm/offer/item/${offerItemID}/calc`, data, authHeaders(token));
  },
  async deleteCRMOfferItem(token: string, data: ICRMOfferItem, discount: boolean = false) {
    if (discount) {
      return axios.delete(`${apiUrl}/api/v1/crm/offer/item/${data.id}/discount`, authHeaders(token));
    }
    return axios.delete(`${apiUrl}/api/v1/crm/offer/item/${data.id}`, authHeaders(token));
  },
  async getCRMProductSizes(token: string, groupID: number) {
    return axios.get<ICRMProductSize[]>(`${apiUrl}/api/v1/crm/product/size/${groupID}`, authHeaders(token));
  },
  async getCRMProductTypes(token: string, groupID: number, size: string) {
    const params = { size: size }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProductType[]>(`${apiUrl}/api/v1/crm/product/type/${groupID}`, config);
  },
  async getCRMProductPriceSizes(token: string, listID: number, groupID: number) {
    return axios.get<ICRMProductSize[]>(`${apiUrl}/api/v1/crm/product_price/list/${listID}/size/${groupID}`, authHeaders(token));
  },
  async getCRMProductPriceTypes(
    token: string
    , listID: number
    , groupID: number
    , size: string
    , composite: boolean
  ) {
    const params = { size: size, composite: composite }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProductType[]>(`${apiUrl}/api/v1/crm/product_price/list/${listID}/type/${groupID}`, config);
  },
  async getCRMSizeTypeProducts(token: string, groupID: number, size: string, type: string) {
    const params = { size: size, type: type }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMProduct[]>(`${apiUrl}/api/v1/crm/product/size-type/${groupID}`, config);
  },
  async getCRMProductPriceSizeTypes(
      token: string
      , listID: number
      , groupID: number
      , size: string
      , type: string
  ) {
    return axios.get<ICRMProductPrice[]>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/size-type/${groupID}`
      , config(token, { size: size, type: type })
    );
  },
  async getCRMProductPriceSizeTypesDA(
    token: string
    , listID: number
    , groupID: number
    , size: string
    , type: string
  ) {
    return axios.get<ICRMProductPrice[]>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/size-type/${groupID}/da/`
      , config(token, { size: size, type: type })
    );
  },
  async getCRMOfferGroupDefaults(token: string, groupID: number) {
    return axios.get<IRMOfferGroupDefaults>(`${apiUrl}/api/v1/crm/offer/defaults/group/${groupID}`, authHeaders(token));
  },
  async createCRMOfferGroupDefaults(token: string, data: IRMOfferGroupDefaultsCreate) {
    return axios.post<IRMOfferGroupDefaults>(`${apiUrl}/api/v1/crm/offer/defaults/group/`, data, authHeaders(token));
  },
  async updateCRMOfferGroupDefaults(token: string, groupID: number, data: IRMOfferGroupDefaultsUpdate) {
    return axios.put<IRMOfferGroupDefaults>(`${apiUrl}/api/v1/crm/offer/defaults/group/${groupID}`, data, authHeaders(token));
  },
  async createCRMOfferDiscount(token: string, data: ICRMOfferDiscountCreate) {
    return axios.post<ICRMOfferDiscount>(`${apiUrl}/api/v1/crm/offer/discount/`, data, authHeaders(token));
  },
  async updateCRMOfferDiscount(token: string, offerDiscountID: number, data: ICRMOfferDiscountUpdate) {
    return axios.put<ICRMOfferDiscount>(`${apiUrl}/api/v1/crm/offer/discount/${offerDiscountID}`, data, authHeaders(token));
  },
  async updateCRMOfferDiscountCalculated(token: string, offerDiscountID: number, data: ICRMOfferDiscountUpdate) {
    return axios.put<ICRMOfferDiscount>(`${apiUrl}/api/v1/crm/offer/discount/${offerDiscountID}/calculated`, data, authHeaders(token));
  },
  async deleteCRMOfferDiscount(token: string, data: ICRMOfferDiscount) {
    return axios.delete(`${apiUrl}/api/v1/crm/offer/discount/${data.id}`, authHeaders(token));
  },
  async deleteCRMOfferDiscountItems(token: string, data: ICRMOfferDiscount) {
    return axios.delete(`${apiUrl}/api/v1/crm/offer/discount/${data.id}/items`, authHeaders(token));
  },
  async getCRMOfferItemRecalculation(token: string, data: { item_id: number, calculation_6: number }) {
    const params = { calculation_6: data.calculation_6, }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMOfferItem>(`${apiUrl}/api/v1/crm/offer/item/${data.item_id}/recalculate`, config);
  },
  async getCRMOfferItemEstimation(token: string, data: { offer_id: number, calculation_6: number }) {
    const params = { calculation_6: data.calculation_6, }
    const config = { params: params, headers: authHeaders(token).headers }
    return axios.get<ICRMOfferItem>(`${apiUrl}/api/v1/crm/offer/item/offer/${data.offer_id}/estimate`, config);
  },
  async getCRMProductPriceDiscountGroup(token: string, listID: number) {
    return axios.get<ICRMProductPriceDiscountGroup[]>(`${apiUrl}/api/v1/crm/product_price/discount-groups/${listID}`, authHeaders(token));
  },
  async updateCRMProductPriceDiscountGroup(token: string, listID: number, data: ICRMProductPriceDiscountGroupUpdate) {
    return axios.put<ICRMProductPriceDiscountGroup>(`${apiUrl}/api/v1/crm/product_price/discount-groups/${listID}`, data, authHeaders(token));
  },
  async getCRMOfferMessageMulti(token: string) {
      return axios.get<ICRMOfferMessage[]>(`${apiUrl}/api/v1/crm/offer/message/?limit=-1`, authHeaders(token));
  },
  async getCRMOfferMessage(token: string, data: { offermessageID: number }) {
      return axios.get<ICRMOfferMessage>(`${apiUrl}/api/v1/crm/offer/message/${data.offermessageID}`, authHeaders(token));
  },
  async createCRMOfferMessage(token: string, data: ICRMOfferMessageCreate) {
      return axios.post<ICRMOfferMessage>(`${apiUrl}/api/v1/crm/offer/message/`, data, authHeaders(token));
  },
  async updateCRMOfferMessage(token: string, offermessageID: number, data: ICRMOfferMessageUpdate) {
      return axios.put<ICRMOfferMessage>(`${apiUrl}/api/v1/crm/offer/message/${offermessageID}`, data, authHeaders(token));
  },
  async deleteCRMOfferMessage(token: string, data: {id: number}) {
      return axios.delete(`${apiUrl}/api/v1/crm/offer/message/${data.id}`, authHeaders(token));
  },
  async getCRMPriceListDiscountMax(token: string, listID: number, groupID: number) {
    return axios.get<ICRMDiscountMax>(`${apiUrl}/api/v1/crm/product_price_list/${listID}/discount_max/${groupID}`, authHeaders(token));
  },
  async updateCRMProductPriceComposite(token: string, compositeID: number, listID: number) {
    // const config = {
    //   params: { price_list_id: listID }
    //   , headers: authHeaders(token).headers
    // }
    return axios.put<ICRMProductPrice>(
      `${apiUrl}/api/v1/crm/product_price/composite/${compositeID}`
      , { id: listID }
      , authHeaders(token)
    );
  },
  async getCRMProductPriceByProduct(token: string, productID: number, listID: number) {
    return axios.get<ICRMProductPrice>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/product/${productID}`
      , authHeaders(token)
    );
  },
  async getCRMProductPriceByProductDA(token: string, productID: number, listID: number) {
    return axios.get<ICRMProductPrice>(
      `${apiUrl}/api/v1/crm/product_price/list/${listID}/product/${productID}/da`
      , authHeaders(token)
    );
  },
  async updateCRMProductPrice(token: string, priceID: number, productPrice: ICRMProductPriceUpdate) {
    return axios.put<ICRMProductPrice>(
      `${apiUrl}/api/v1/crm/product_price/${priceID}`
      , productPrice
      , authHeaders(token)
    );
  },
  async getCRMProjectMulti(token: string) {
    const config = { params: { limit: -1 }, headers: authHeaders(token).headers }
    return axios.get<ICRMProject[]>(`${apiUrl}/api/v1/crm/project`, config);
  },
  async getCRMProject(token: string, projectID: number ) {
    return axios.get<ICRMProject>(`${apiUrl}/api/v1/crm/project/${projectID}`, authHeaders(token));
  },
  async createCRMProject(token: string, data: ICRMProjectCreate) {
    return axios.post<ICRMProject>(`${apiUrl}/api/v1/crm/project/`, data, authHeaders(token));
  },
  async updateCRMProject(token: string, projectID: number, data: ICRMProjectUpdate) {
    return axios.put<ICRMProject>(`${apiUrl}/api/v1/crm/project/${projectID}`, data, authHeaders(token));
  },
  async deleteCRMProject(token: string, data: {id: number}) {
    return axios.delete(`${apiUrl}/api/v1/crm/project/${data.id}`, authHeaders(token));
  },
  async getCRMOfferItemGAEBMulti(token: string, offerID: number) {
    const config = {
      params: { limit: -1 }
      , headers: authHeaders(token).headers
    }
    return axios.get<ICRMOfferItemGAEB[]>(
      `${apiUrl}/api/v1/crm/offer/item/gaeb/offer/${offerID}`
      , config
    );
  },
  async getCRMOfferItemGAEB(token: string, offerItemGAEB_ID: number ) {
    return axios.get<ICRMOfferItemGAEB>(
      `${apiUrl}/api/v1/crm/offer/item/gaeb/${offerItemGAEB_ID}`
      , authHeaders(token)
    );
  },
  async createCRMOfferItemGAEB(token: string, data: ICRMOfferItemGAEBCreate) {
    return axios.post<ICRMOfferItemGAEB>(
      `${apiUrl}/api/v1/crm/offer/item/gaeb/`
      , data
      , authHeaders(token)
    );
  },
  async updateCRMOfferItemGAEB(token: string, offerItemGAEB_ID: number, data: ICRMOfferItemGAEBUpdate) {
    return axios.put<ICRMOfferItemGAEB>(
      `${apiUrl}/api/v1/crm/offer/item/gaeb/${offerItemGAEB_ID}`
      , data
      , authHeaders(token)
    );
  },
  async deleteCRMOfferItemGAEB(token: string, data: {offerItemGAEB_ID: number}) {
    return axios.delete(
      `${apiUrl}/api/v1/crm/offer/item/gaeb/${data.offerItemGAEB_ID}`
      , authHeaders(token)
    );
  },

  // module CRM END


  // module Finance
  async getCurrencies(token: string) {
    return axios.get<IFinanceCurrency[]>(`${apiUrl}/api/v1/finance/currency/?limit=-1`, authHeaders(token));
  },
  async updateCurency(token: string, data: IFinanceCurrencyUpdate) {
    return axios.put(`${apiUrl}/api/v1/finance/currency/${data.id}`, data, authHeaders(token));
  },
  async getPaymentMethods(token: string) {
    return axios.get<IFinancePaymentMethod[]>(`${apiUrl}/api/v1/finance/payment_method/?limit=-1`, authHeaders(token));
  },
  async createPaymentMethod(token: string, data: IFinancePaymentMethodCreate) {
    return axios.post<IFinancePaymentMethod>(`${apiUrl}/api/v1/finance/payment_method`, data, authHeaders(token));
  },
  async updatePaymentMethod(token: string, methodID: number, data: IFinancePaymentMethodUpdate) {
    return axios.put<IFinancePaymentMethod>(`${apiUrl}/api/v1/finance/payment_method/${methodID}`, data, authHeaders(token));
  },
  async getTaxRates(token: string) {
    return axios.get<IFinanceTaxRate[]>(`${apiUrl}/api/v1/finance/tax_rate/?limit=-1`, authHeaders(token));
  },
  async createTaxRate(token: string, data: IFinanceTaxRateCreate) {
    return axios.post<IFinanceTaxRate>(`${apiUrl}/api/v1/finance/tax_rate`, data, authHeaders(token));
  },
  async updateTaxRate(token: string, taxRateID: number, data: IFinanceTaxRateUpdate) {
    return axios.put<IFinanceTaxRate>(`${apiUrl}/api/v1/finance/tax_rate/${taxRateID}`, data, authHeaders(token));
  },
  // module Finance END


  // module Project
  async getProjects(token: string) {
    return axios.get<IProject[]>(
      `${apiUrl}/api/v1/project/?limit=-1`
      , authHeaders(token)
    );
  },
  async getProject(token: string, data: { projectID: number }) {
    return axios.get<IProject>(
      `${apiUrl}/api/v1/project/${data.projectID}`
      , authHeaders(token)
    );
  },
  async createProject(token: string, data: IProjectCreate) {
    return axios.post<IProject>(
      `${apiUrl}/api/v1/project/`
      , data, authHeaders(token));
    },
  async updateProject(token: string, projectID: number, data: IProjectUpdate) {
    return axios.put<IProject>(
      `${apiUrl}/api/v1/project/${projectID}`
      , data
      , authHeaders(token)
    );
  },
  async deleteProject(token: string, data: { projectID: number }) {
    return axios.delete(
      `${apiUrl}/api/v1/project/${data.projectID}`
      , authHeaders(token)
    );
  },
  async getTimesheets(token: string) {
    return axios.get<ITimesheet[]>(
      `${apiUrl}/api/v1/project/timesheet/?limit=-1`
      , authHeaders(token)
    );
  },
  async getTimesheet(token: string, data: { timesheetID: number }) {
    return axios.get<ITimesheet>(
      `${apiUrl}/api/v1/project/timesheet/${data.timesheetID}`
      , authHeaders(token)
    );
  },
  async createTimesheet(token: string, data: ITimesheetCreate) {
    return axios.post<ITimesheet>(
      `${apiUrl}/api/v1/project/timesheet/`
      , data, authHeaders(token));
    },
  async updateTimesheet(token: string, timesheetID: number, data: ITimesheetUpdate) {
    return axios.put<ITimesheet>(
      `${apiUrl}/api/v1/project/timesheet/${timesheetID}`
      , data
      , authHeaders(token)
    );
  },
  async deleteTimesheet(token: string, data: { timesheetID: number }) {
    return axios.delete(
      `${apiUrl}/api/v1/project/timesheet/${data.timesheetID}`
      , authHeaders(token)
    );
  },

  async getProjectTaskMulti(token: string, projectID: number) {
    return axios.get<IProjectTask[]>(
      `${apiUrl}/api/v1/project/task/project/${projectID}/?limit=-1`
      , authHeaders(token)
    );
  },
  async getProjectTask(token: string, data: { projectTaskID: number }) {
    return axios.get<IProjectTask>(
      `${apiUrl}/api/v1/project/task/${data.projectTaskID}`
      , authHeaders(token)
    );
  },
  async createProjectTask(token: string, data: IProjectTaskCreate) {
    return axios.post<IProjectTask>(
      `${apiUrl}/api/v1/project/task/`
      , data, authHeaders(token));
    },
  async updateProjectTask(token: string, projectTaskID: number, data: IProjectTaskUpdate) {
    return axios.put<IProjectTask>(
      `${apiUrl}/api/v1/project/task/${projectTaskID}`
      , data
      , authHeaders(token)
    );
  },
  async deleteProjectTask(token: string, data: { projectTaskID: number }) {
    return axios.delete(
      `${apiUrl}/api/v1/project/task/${data.projectTaskID}`
      , authHeaders(token)
    );
  },

  async getProjectTaskStateMulti(token: string, projectID: number) {
    return axios.get<IProjectTaskState[]>(
      `${apiUrl}/api/v1/project/task/state/project/${projectID}/?limit=-1`
      , authHeaders(token)
    );
  },
  async getProjectTaskState(token: string, data: { taskStateID: number }) {
    return axios.get<IProjectTaskState>(
      `${apiUrl}/api/v1/project/task/state/${data.taskStateID}`
      , authHeaders(token)
    );
  },
  async createProjectTaskState(token: string, data: IProjectTaskStateCreate) {
    return axios.post<IProjectTaskState>(
      `${apiUrl}/api/v1/project/task/state/`
      , data, authHeaders(token));
    },
  async updateProjectTaskState(token: string, taskStateID: number, data: IProjectTaskStateUpdate) {
    return axios.put<IProjectTaskState>(
      `${apiUrl}/api/v1/project/task/state/${taskStateID}`
      , data
      , authHeaders(token)
    );
  },
  async deleteProjectTaskState(token: string, data: { taskStateID: number }) {
    return axios.delete(
      `${apiUrl}/api/v1/project/task/state/${data.taskStateID}`
      , authHeaders(token)
    );
  },
  // Project END


  // module LTU
  async getLTUPriceLists(token: string) {
    return axios.get<ILTUPriceList[]>(`${apiUrl}/api/v1/ltu/product_price_list/?limit=-1`, authHeaders(token));
  },
  // module LTU END

  // gallery
  async getGalleryOne(token: string, image: IImageFile) {
    return axios.get<IImageFile>(`${apiUrl}/api/v1/downloads/gallery/${image.id}`, authHeaders(token));
  },
  async getGalleryAll(token: string) {
    return axios.get<IImageFile[]>(`${apiUrl}/api/v1/downloads/gallery/all?public=true`, authHeaders(token));
  },
  // gallery END

  async getLocale(token: string) {
    return axios.get<LocaleMessages>(`${apiUrl}/api/v1/locale`, authHeaders(token));
  },
};
