
import { getStoreAccessors } from 'typesafe-vuex';
import { LTUState } from './state';
import { State } from '../state';


export const getters = {
    ltuPriceListMulti: (state: LTUState) => state.ltuPriceList,
    ltuPriceListOne: (state: LTUState) => (ltuPriceListID: string) => {
        const filteredLTUPriceList = state.ltuPriceList.filter(
            (list) => list.id === ltuPriceListID);
        if (filteredLTUPriceList.length > 0) {
            return { ...filteredLTUPriceList[0] };
        }
        return null;
    },
};

const { read } = getStoreAccessors<LTUState, State>('');
export const readLTUPriceListMulti = read(getters.ltuPriceListMulti);
export const readLTUPriceListOne = read(getters.ltuPriceListOne);
