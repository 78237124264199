import {
    IUserProfile
    , IActionProfile
    , IUserProfileCompany
    , IRole
} from '@/interfaces';
import {
    ILog
    , ILocaleDictionaryItem
} from '@/interfaces/admin';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfileCompany[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfileCompany) {
        const users = state.users.filter((user: IUserProfileCompany) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    setActions(state: AdminState, payload: IActionProfile[]) {
        state.actions = payload;
    },
    setAction(state: AdminState, payload: IActionProfile) {
        const actions = state.actions.filter((action: IActionProfile) => action.id !== payload.id);
        actions.push(payload);
        state.actions = actions;
    },
    setActionsMulti(state: AdminState, payload: IActionProfile[]) {
        let actions: IActionProfile[] = [];
        for (let actionNew of payload) {
            const found = state.actions.find(action => action.id == actionNew.id);
            if (found == null) {
                state.actions.push(actionNew);
            }
        }
        state.actions = actions;
    },
    setRoles(state: AdminState, payload: IRole[]) {
        state.roles = payload;
    },
    setRole(state: AdminState, payload: IRole) {
        const roles = state.roles.filter((role: IRole) => role.id !== payload.id);
        roles.push(payload);
        state.roles = roles;
    },
    setLog(state: AdminState, payload: ILog) {
        state.log = payload;
    },
    setLocaleDictionaryItems(state: AdminState, payload: ILocaleDictionaryItem[]) {
        state.dictionary = payload;
    },
    setLocaleDictionaryItem(state: AdminState, payload: ILocaleDictionaryItem) {
        const dictionary = state.dictionary.filter((item: ILocaleDictionaryItem) => item.id !== payload.id);
        dictionary.push(payload);
        state.dictionary = dictionary;
    },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetAction = commit(mutations.setAction);
export const commitSetActions = commit(mutations.setActions);
export const commitSetActionsMulti = commit(mutations.setActionsMulti);
export const commitSetRole = commit(mutations.setRole);
export const commitSetRoles = commit(mutations.setRoles);
export const commitSetLog = commit(mutations.setLog);

export const commitSetLocaleDictionaryItems = commit(mutations.setLocaleDictionaryItems);
export const commitSetLocaleDictionaryItem = commit(mutations.setLocaleDictionaryItem);
