import {
    ITask
    , ITaskSchedule
    , ITaskResult
    , ITaskType
    , IWorker
    , ITaskProgress
    , ITaskCreate
} from '@/interfaces';
import { TaskState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTasks(state: TaskState, payload: ITask[]) {
        state.tasks = payload;
    },
    setTaskSchedule(state: TaskState, payload: ITaskSchedule[]) {
        state.schedule = payload;
    },
    setLastTaskResult(state: TaskState, payload: ITaskResult | null) {
        state.lastResult = payload;
    },
    setStatusInfo(state: TaskState, payload: { [key: number]: string }) {
        state.statusInfo = payload;
    },
    setTaskTypes(state: TaskState, payload: Map<number, ITaskType>) {
        state.taskTypes = payload;
    },
    setTaskResults(state: TaskState, payload: ITaskResult[]) {
        state.results = payload;
    },
    setTaskWorkers(state: TaskState, payload: IWorker[]) {
        state.workers = payload;
    },
    setRunningTasks(state: TaskState, payload: ITaskProgress[]) {
        state.runningTasks = payload;
    },
    setLastUsedTask(state: TaskState, payload: ITaskCreate) {
        state.lastUsedTask = payload;
    },
    setOneRunningTask(state: TaskState, payload: ITask) {
        state.runningTaskMap.delete(payload.type);
        state.runningTaskMap.set(payload.type, payload);
    },
    removeOneRunningTask(state: TaskState, payload: ITask) {
        state.runningTaskMap.delete(payload.type);
    },
    setOneTaskResult(state: TaskState, payload: ITaskResult) {
        state.taskResultsMap.delete(payload.task_id);
        state.taskResultsMap.set(payload.task_id, payload);
    },
};

const { commit } = getStoreAccessors<TaskState, State>('');

export const commitSetTasks = commit(mutations.setTasks);
export const commitSetTaskSchedule = commit(mutations.setTaskSchedule);
export const commitSetLastTaskResult = commit(mutations.setLastTaskResult);
export const commitStatusInfo = commit(mutations.setStatusInfo);
export const commitTaskTypes = commit(mutations.setTaskTypes);
export const commitSetTaskResults = commit(mutations.setTaskResults);
export const commitSetTaskWorkers = commit(mutations.setTaskWorkers);
export const commitSetRunningTasks = commit(mutations.setRunningTasks);
export const commitSetLastUsedTask = commit(mutations.setLastUsedTask);
export const commitSetOneRunningTask = commit(mutations.setOneRunningTask);
export const commitRemoveOneRunningTask = commit(mutations.removeOneRunningTask);
export const commitSetOneTaskResult = commit(mutations.setOneTaskResult);
