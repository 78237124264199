import {
    ICRMCustomer
    , ICRMCompany
    , ICRMBranch
    , ICRMContactPerson
    , ICRMOfferTableViev
    , ICRMProductGroup
    , ICRMProduct
    , ICRMOfferItem
    , ICRMOfferDiscount
    , ICRMPriceList
    , ICRMProductCategory
    , ICRMProductSubcategory
    , ICRMProductPrice
    , IExportXLSXTask
    , IImportXLSXTask
    , IPopulationTask
    , ISyncTask
    , ISyncTaskResult
    , IPropagationTask
    , IRecalculationTask
    , IRecalculationTaskResult
    , IProductTaskResult
    , ICRMProductsExport
    , ICRMProductVariant
    , ICRMOverheads
    , ICRMProductGroupRabat
    , ICRMNumberSeries
    , ICRMOfferState
    , ICRMOfferValidity
    , ICRMOffer
    , ICRMContactPersonFull
    , ICRMProductSize
    , ICRMProductType
    , ICRMProductPriceDiscountGroup
    , ICRMOfferMessage
    , ICRMProductPriceSizeType
    , ICRMDiscountMax
    , ICRMOfferItemGrouped
    , ICRMProductComposite
    , IRMOfferGroupDefaults
    , ICRMProject
    , ICRMOfferItemGAEB
} from '@/interfaces/crm';
import { CRMState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCustomers(state: CRMState, payload: ICRMCustomer[]) {
        state.customers = payload;
    },
    setCRMCompanies(state: CRMState, payload: ICRMCompany[], sort: boolean = false) {
        state.companies = payload;
        if (sort) {
            state.companies.sort((one: ICRMCompany, two: ICRMCompany) => (one.name > two.name ? -1 : 1))
        }
    },
    setBranches(state: CRMState, payload: ICRMBranch[]) {
        state.branches = payload;
    },
    setContactPersons(state: CRMState, payload: ICRMContactPerson[]) {
        state.contactPersons = payload;
    },
    setContactPersonsFull(state: CRMState, payload: ICRMContactPersonFull[]) {
        state.contactPersonsFull = payload;
    },
    sortContactPersonsFull(state: CRMState) {
        state.contactPersonsFull.sort((one: ICRMContactPersonFull, two: ICRMContactPersonFull) => (one.company!.name > two.company!.name ? 1 : -1));
    },
    setOneContactPersonFull(state: CRMState, payload: ICRMContactPersonFull) {
        state.oneContactPersonFull = payload;
    },
    setOneCompany(state: CRMState, payload: ICRMCompany) {
        const companies = state.companies.filter((company: ICRMCompany) => company.id !== payload.id);
        companies.push(payload);
        state.companies = companies;
    },
    setOneBranch(state: CRMState, payload: ICRMBranch) {
        const branches = state.branches.filter((branch: ICRMBranch) => branch.id !== payload.id);
        branches.push(payload);
        state.branches = branches;
    },
    setOneContactPerson(state: CRMState, payload: ICRMContactPerson) {
        const contactPersons = state.contactPersons.filter((contact: ICRMContactPerson) => contact.id !== payload.id);
        contactPersons.push(payload);
        state.contactPersons = contactPersons;
    },
    setCompanyARES(state: CRMState, payload: ICRMCompany | null) {
        state.companyARES = payload;
    },
    removeOneBranch(state: CRMState, payload: ICRMBranch) {
        state.branches = state.branches.filter((branch: ICRMBranch) => branch.id !== payload.id);
    },
    removeOneContactPerson(state: CRMState, payload: ICRMContactPerson) {
        state.contactPersons = state.contactPersons.filter((contact: ICRMContactPerson) => contact.id !== payload.id);
    },
    setProductCategories(state: CRMState, payload: ICRMProductCategory[]) {
        state.productCategories = payload;
    },
    setOneProductCategory(state: CRMState, payload: ICRMProductCategory) {
        const productCategories = state.productCategories.filter((category: ICRMProductCategory) => category.id !== payload.id);
        productCategories.push(payload);
        state.productCategories = productCategories;
    },
    setProductSubcategories(state: CRMState, payload: ICRMProductSubcategory[]) {
        state.productSubcategories = payload;
    },
    setOneProductSubcategory(state: CRMState, payload: ICRMProductSubcategory) {
        const productSubcategories = state.productSubcategories.filter((subcategory: ICRMProductSubcategory) => subcategory.id !== payload.id);
        productSubcategories.push(payload);
        state.productSubcategories = productSubcategories;
    },
    setProductGroups(state: CRMState, payload: ICRMProductGroup[]) {
        state.productGroups = payload;
    },
    setNonEmptyProductGroups(state: CRMState, payload: ICRMProductGroup[]) {
        state.nonEmptyProductGroups = payload;
    },
    setSelectedProductGroupInfo(state: CRMState, payload: ICRMProductGroup) {
        state.selectedProductGroupInfo = payload;
    },
    setOneProductGroup(state: CRMState, payload: ICRMProductGroup) {
        const productGroups = state.productGroups.filter((group: ICRMProductGroup) => group.id !== payload.id);
        productGroups.push(payload);
        state.productGroups = productGroups;
    },
    setProductVariants(state: CRMState, payload: ICRMProductVariant[]) {
        state.productVariants = payload;
    },
    setProducts(state: CRMState, payload: ICRMProduct[]) {
        state.products = payload;
    },
    setSelectedProduct(state: CRMState, payload: ICRMProduct) {
        state.product = payload;
    },
    setOneProduct(state: CRMState, payload: ICRMProduct) {
        const products = state.products.filter((product: ICRMProduct) => product.id !== payload.id);
        products.push(payload);
        state.products = products;
    },
    setOffersTable(state: CRMState, payload: ICRMOfferTableViev[]) {
        state.offersTable = payload;
    },
    setOfferItems(state: CRMState, payload: ICRMOfferItem[]) {
        state.offerItems = payload;
    },
    setOneOfferItem(state: CRMState, payload: ICRMOfferItem) {
        const items = state.offerItems.filter((item: ICRMOfferItem) => item.id !== payload.id);
        items.push(payload);
        state.offerItems = items;
    },
    setOfferItemsGrouped(state: CRMState, payload: ICRMOfferItemGrouped[]) {
        state.offerItemsGrouped = payload;
    },
    setOneOfferItemGrouped(state: CRMState, payload: ICRMOfferItem) {
        let newGroup = true;
        for (let group of state.offerItemsGrouped) {
            if (payload.group_id === group.group_id) {
                const items = group.items.filter((item: ICRMOfferItem) => item.id !== payload.id);
                items.push(payload);
                items.sort((a, b) => a.row_number - b.row_number);
                group.items = items;
                newGroup = false;
                break;
            }
        }
        if (newGroup) {
            let group: ICRMOfferItemGrouped = {
                group_id: payload.group_id
                , group_name: '??'
                , isOpen: true
                , items: []
            }
            group.items.push(payload);
            state.offerItemsGrouped.push(group);
        }
    },
    setOneOfferItemsGroup(state: CRMState, payload: ICRMOfferItem[] | null) {
        if (payload == null) {
            return;
        }
        if (payload.length == 0) {
            return;
        }
        const group_id = payload[0].group_id;
        for (let g of state.offerItemsGrouped) {
            if (g.group_id == group_id) {
                g.items = payload;
                break;
            }
        }
    },
    addOfferItemsToGroup(state: CRMState, payload: ICRMOfferItem[] | null) {
        if (payload == null) {
            return;
        }
        if (payload.length == 0) {
            return;
        }
        const group_id = payload[0].group_id;
        for (let g of state.offerItemsGrouped) {
            if (g.group_id == group_id) {
                for (const item of payload) {
                    g.items.push(item);
                }
                break;
            }
        }
    },
    addOfferItemsUnrouped(state: CRMState, payload: ICRMOfferItem[] | null) {
        if (payload == null) {
            return;
        }
        if (payload.length == 0) {
            return;
        }
        for (const item of payload) {
            state.offerItems.push(item);
        }
    },
    setOfferDiscounts(state: CRMState, payload: ICRMOfferDiscount[]) {
        state.offerDiscounts = payload;
    },
    setPriceLists(state: CRMState, payload: ICRMPriceList[]) {
        state.priceLists = payload;
    },
    setCurrentPriceList(state: CRMState, payload: ICRMPriceList) {
        state.currentPriceList = payload;
    },
    setIsCurrentPriceListEmpty(state: CRMState, payload: boolean) {
        if (state.currentPriceList != null) {
            state.currentPriceList.is_empty = payload;
        }
    },
    setPriceList(state: CRMState, payload: ICRMPriceList) {
        const priceLists = state.priceLists.filter((priceList: ICRMPriceList) => priceList.id !== payload.id);
        priceLists.push(payload);
        state.priceLists = priceLists;
    },
    setProductPrices(state: CRMState, payload: ICRMProductPrice[]) {
        state.productPrices = payload;
    },
    setProductPrice(state: CRMState, payload: ICRMProductPrice) {
        for (let index = 0; index < state.productPrices.length; index++) {
            const element = state.productPrices[index];
            if (element.id == payload.id) {
                state.productPrices[index] = payload;
                break;
            }
        }
    },
    setProductPricesMerge(state: CRMState, payload: ICRMProductPrice[]) {
        state.productPricesMerge = payload;
    },
    addPriceListExportTask(state: CRMState, payload: IExportXLSXTask) {
        state.priceListExportTasks.push(payload);
    },
    updatePriceListExportTask(state: CRMState, payload: IExportXLSXTask) {
        for (let index = 0; index < state.priceListExportTasks.length; index++) {
            const task = state.priceListExportTasks[index];
            if (task.task_id == payload.task_id) {
                state.priceListExportTasks[index] = payload;
                break;
            }
        }
    },
    removePriceListExportTask(state: CRMState, payload: IExportXLSXTask) {
        const priceListExportTasks = state.priceListExportTasks.filter((task: IExportXLSXTask) => task.task_id !== payload.task_id);
        state.priceListExportTasks = priceListExportTasks;
    },
    addPriceListImportTask(state: CRMState, payload: IImportXLSXTask) {
        state.priceListImportTasks.push(payload);
    },
    updatePriceListImportTask(state: CRMState, payload: IImportXLSXTask) {
        for (let index = 0; index < state.priceListImportTasks.length; index++) {
            const task = state.priceListImportTasks[index];
            if (task.task_id == payload.task_id) {
                state.priceListImportTasks[index] = payload;
                break;
            }
        }
    },
    removePriceListImportTask(state: CRMState, payload: IImportXLSXTask) {
        const priceListImportTasks = state.priceListImportTasks.filter((task: IImportXLSXTask) => task.task_id !== payload.task_id);
        state.priceListImportTasks = priceListImportTasks;
    },
    addPriceListPopulationTask(state: CRMState, payload: IPopulationTask) {
        state.priceListPopulationTasks.push(payload);
    },
    updatePriceListPopulationTask(state: CRMState, payload: IPopulationTask) {
        for (let index = 0; index < state.priceListPopulationTasks.length; index++) {
            const task = state.priceListPopulationTasks[index];
            if (task.task_id == payload.task_id) {
                state.priceListPopulationTasks[index] = payload;
                break;
            }
        }
    },
    removePriceListPopulationTask(state: CRMState, payload: IPopulationTask) {
        const priceListPopulationTasks = state.priceListPopulationTasks.filter((task: IPopulationTask) => task.task_id !== payload.task_id);
        state.priceListPopulationTasks = priceListPopulationTasks;
    },
    addPriceListSyncTask(state: CRMState, payload: ISyncTask) {
        state.priceListSyncTasks.push(payload);
    },
    updatePriceListSyncTask(state: CRMState, payload: ISyncTask) {
        for (let index = 0; index < state.priceListSyncTasks.length; index++) {
            const task = state.priceListSyncTasks[index];
            if (task.task_id == payload.task_id) {
                state.priceListSyncTasks[index] = payload;
                break;
            }
        }
    },
    removePriceListSyncTask(state: CRMState, payload: ISyncTask) {
        const priceListSyncTasks = state.priceListSyncTasks.filter((task: ISyncTask) => task.task_id !== payload.task_id);
        state.priceListSyncTasks = priceListSyncTasks;
    },
    setPriceListSyncTaskResult(state: CRMState, payload: ISyncTaskResult) {
        state.priceListSyncTaskResult = payload;
    },
    addPriceListPropagationTask(state: CRMState, payload: IPropagationTask) {
        state.priceListPropagationTasks.push(payload);
    },
    updatePriceListPropagationTask(state: CRMState, payload: IPropagationTask) {
        for (let index = 0; index < state.priceListPropagationTasks.length; index++) {
            const task = state.priceListPropagationTasks[index];
            if (task.task_id == payload.task_id) {
                state.priceListPropagationTasks[index] = payload;
                break;
            }
        }
    },
    removePriceListPropagationTask(state: CRMState, payload: IPropagationTask) {
        const priceListPropagationTasks = state.priceListPropagationTasks.filter((task: IPropagationTask) => task.task_id !== payload.task_id);
        state.priceListPropagationTasks = priceListPropagationTasks;
    },
    addPriceListRecalculationTask(state: CRMState, payload: IRecalculationTask) {
        state.priceListRecalculationTasks.push(payload);
    },
    updatePriceListRecalculationTask(state: CRMState, payload: IRecalculationTask) {
        for (let index = 0; index < state.priceListRecalculationTasks.length; index++) {
            const task = state.priceListRecalculationTasks[index];
            if (task.task_id == payload.task_id) {
                state.priceListRecalculationTasks[index] = payload;
                break;
            }
        }
    },
    removePriceListRecalculationTask(state: CRMState, payload: IRecalculationTask) {
        const priceListRecalculationTasks = state.priceListRecalculationTasks.filter((task: IRecalculationTask) => task.task_id !== payload.task_id);
        state.priceListRecalculationTasks = priceListRecalculationTasks;
    },
    setPriceListRecalculationTaskReport(state: CRMState, payload: IRecalculationTaskResult) {
        state.priceListRecalculationTaskResult = payload;
    },
    addProductsExportTask(state: CRMState, payload: IExportXLSXTask) {
        state.productsExportTasks.push(payload);
    },
    updateProductsExportTask(state: CRMState, payload: IExportXLSXTask) {
        for (let index = 0; index < state.productsExportTasks.length; index++) {
            const task = state.productsExportTasks[index];
            if (task.task_id == payload.task_id) {
                state.productsExportTasks[index] = payload;
                break;
            }
        }
    },
    removeProductsExportTask(state: CRMState, payload: IExportXLSXTask) {
        const productsExportTasks = state.productsExportTasks.filter((task: IExportXLSXTask) => task.task_id !== payload.task_id);
        state.productsExportTasks = productsExportTasks;
    },
    addProductsImportTask(state: CRMState, payload: IImportXLSXTask) {
        state.productsImportTasks.push(payload);
    },
    updateProductsImportTask(state: CRMState, payload: IImportXLSXTask) {
        for (let index = 0; index < state.productsImportTasks.length; index++) {
            const task = state.productsImportTasks[index];
            if (task.task_id == payload.task_id) {
                state.productsImportTasks[index] = payload;
                break;
            }
        }
    },
    removeProductsImportTask(state: CRMState, payload: IImportXLSXTask) {
        const productsImportTasks = state.productsImportTasks.filter((task: IImportXLSXTask) => task.task_id !== payload.task_id);
        state.productsImportTasks = productsImportTasks;
    },
    addProductsImportCheckTask(state: CRMState, payload: IImportXLSXTask) {
        state.productsImportCheckTasks.push(payload);
    },
    updateProductsImportCheckTask(state: CRMState, payload: IImportXLSXTask) {
        for (let index = 0; index < state.productsImportCheckTasks.length; index++) {
            const task = state.productsImportCheckTasks[index];
            if (task.task_id == payload.task_id) {
                state.productsImportCheckTasks[index] = payload;
                break;
            }
        }
    },
    removeProductsImportCheckTask(state: CRMState, payload: IImportXLSXTask) {
        const productsImportCheckTasks = state.productsImportCheckTasks.filter((task: IImportXLSXTask) => task.task_id !== payload.task_id);
        state.productsImportCheckTasks = productsImportCheckTasks;
    },
    setProductsImportCheckTaskResult(state: CRMState, payload: IProductTaskResult) {
        state.productsImportCheckTaskResult = payload;
    },
    setOverheads(state: CRMState, payload: ICRMOverheads[]) {
        state.overheads = payload;
    },
    setCompanyGroupRabats(state: CRMState, payload: ICRMProductGroupRabat[]) {
        state.companyGroupRabats = payload;
    },
    setOneCompanyGroupRabat(state: CRMState, payload: ICRMProductGroupRabat) {
        const rabats = state.companyGroupRabats.filter((rabat: ICRMProductGroupRabat) => ((rabat.company_id !== payload.company_id) && (rabat.group_id !== payload.group_id)));
        rabats.push(payload);
        state.companyGroupRabats = rabats;
    },
    setNumberSeries(state: CRMState, payload: ICRMNumberSeries[]) {
        state.numberSeries = payload;
    },
    setOneNumberSeries(state: CRMState, payload: ICRMNumberSeries) {
        const series = state.numberSeries.filter((series: ICRMNumberSeries) => (series.id !== payload.id));
        series.push(payload);
        state.numberSeries = series;
    },
    useNumberSeries(state: CRMState, payload: ICRMNumberSeries) {
        state.useNumberSeries = payload;
    },
    setOfferState(state: CRMState, payload: ICRMOfferState[]) {
        state.offerState = payload;
    },
    setOfferValidity(state: CRMState, payload: ICRMOfferValidity[]) {
        state.offerValidity = payload;
    },
    setOffers(state: CRMState, payload: ICRMOffer[]) {
        state.offers = payload;
    },
    setOneOffer(state: CRMState, payload: ICRMOffer) {
        const offers = state.offers.filter((offer: ICRMOffer) => (offer.id !== payload.id));
        offers.push(payload);
        state.offers = offers;
    },
    setOfferYears(state: CRMState, payload: number[]) {
        state.offerYears = payload;
    },
    setOfferProductGroups(state: CRMState, payload: ICRMProductGroup[]) {
        state.offerProductGroups = payload;
    },
    removeOfferItem(state: CRMState, payload: ICRMOfferItem) {
        const offerItems = state.offerItems.filter((item: ICRMOfferItem) => (item.id !== payload.id));
        state.offerItems = offerItems;
    },
    removeOfferItemGrouped(state: CRMState, payload: ICRMOfferItem) {
        let removeGroup = false;
        for (let group of state.offerItemsGrouped) {
            if (group.group_id === payload.group_id) {
                const items = group.items.filter((item: ICRMOfferItem) => (item.id !== payload.id));
                group.items = items;
                if (items.length == 0) {
                    removeGroup = true;
                }
                break;
            }
        }
        if (removeGroup) {
            const groups = state.offerItemsGrouped.filter((group: ICRMOfferItemGrouped) => group.group_id !== payload.group_id)
            state.offerItemsGrouped = groups;
        }
    },
    removeOfferItemsGroup(state: CRMState, payload: { groupID: number }) {
        const groups = state.offerItemsGrouped.filter((group: ICRMOfferItemGrouped) => group.group_id !== payload.groupID)
        state.offerItemsGrouped = groups;
    },
    setOneOfferDiscount(state: CRMState, payload: ICRMOfferDiscount) {
        const offerDiscounts = state.offerDiscounts.filter((discount: ICRMOfferDiscount) => (discount.id !== payload.id));
        offerDiscounts.push(payload);
        offerDiscounts.sort((a, b) => {
            if (a.group_name && b.group_name) {
                return a.group_name.localeCompare(b.group_name);
            }
            return 0;
        })
        state.offerDiscounts = offerDiscounts;
    },
    removeOfferDiscount(state: CRMState, payload: ICRMOfferDiscount) {
        state.offerDiscounts = state.offerDiscounts.filter(
            (discount: ICRMOfferDiscount) => (discount.id !== payload.id));
    },
    setProductSizes(state: CRMState, payload: ICRMProductSize[]) {
        state.productSizes = payload;
    },
    setProductTypes(state: CRMState, payload: ICRMProductType[]) {
        state.productTypes = payload;
    },
    setSizeTypeProducts(state: CRMState, payload: ICRMProduct[]) {
        state.sizeTypeProducts = payload;
    },
    setRecalculatedOfferItem(state: CRMState, payload: ICRMOfferItem) {
        state.recalculatedOfferItem = payload;
    },
    setProductPriceDiscountGroups(state: CRMState, payload: ICRMProductPriceDiscountGroup[]) {
        state.productPriceDiscountGroups = payload;
    },
    setOneProductPriceDiscountGroup(state: CRMState, payload: ICRMProductPriceDiscountGroup) {
        const productPriceDiscountGroups = state.productPriceDiscountGroups
            .filter((group: ICRMProductPriceDiscountGroup) => group.group_id !== payload.group_id);
        productPriceDiscountGroups.push(payload);
        state.productPriceDiscountGroups = productPriceDiscountGroups;
    },
    setOfferMessageMulti(state: CRMState, payload: ICRMOfferMessage[]) {
        state.offerMessage = payload;
    },
    setOfferMessageOne(state: CRMState, payload: ICRMOfferMessage) {
        const offerMessage = state.offerMessage.filter((item: ICRMOfferMessage) => item.id !== payload.id);
        offerMessage.push(payload);
        state.offerMessage = offerMessage;
    },
    removeOfferMessage(state: CRMState, payload: ICRMOfferMessage) {
        state.offerMessage = state.offerMessage.filter(
            (item: ICRMOfferMessage) => (item.id !== payload.id));
    },
    setPriceListGroups(state: CRMState, payload: ICRMProductGroup[]) {
        state.priceListGroups = payload;
    },
    setProductPriceSizes(state: CRMState, payload: ICRMProductSize[]) {
        state.productPriceSizes = payload;
    },
    setProductPriceTypes(state: CRMState, payload: ICRMProductType[]) {
        state.productPriceTypes = payload;
    },
    setProductPriceSizeTypes(state: CRMState, payload: ICRMProductPrice[]) {
        state.productPriceSizeTypes = payload;
    },
    setPriceListDiscountMax(state: CRMState, payload: ICRMDiscountMax) {
        state.discountMax = payload;
    },
    setProductsCount(state: CRMState, payload: number) {
        state.productsCount = payload;
    },
    setProductPricesCount(state: CRMState, payload: number) {
        state.productPricesCount = payload;
    },
    setProductComposite(state: CRMState, payload: ICRMProductComposite | null) {
        state.productComposite = payload;
    },
    setOfferGroupDefaults(state: CRMState, payload: IRMOfferGroupDefaults | null) {
        state.offerGroupDefaults = payload;
    },
    setCreatedProduct(state: CRMState, payload: ICRMProduct | null) {
        state.createdProduct = payload;
    },
    setProductPriceComposite(state: CRMState, payload: ICRMProductPrice | null) {
        state.productPriceComposite = payload;
    },
    setProductAttributes(state: CRMState, payload: ICRMProduct[]) {
        state.productAttributes = payload;
    },
    setCRMProjectMulti(state: CRMState, payload: ICRMProject[]) {
        state.projects = payload;
    },
    setCRMProjectOne(state: CRMState, payload: ICRMProject) {
        const cRMProject = state.projects.filter((item: ICRMProject) => item.id !== payload.id);
        cRMProject.push(payload);
        state.projects = cRMProject;
    },
    removeCRMProject(state: CRMState, payload: ICRMProject) {
        state.projects = state.projects.filter(
            (item: ICRMProject) => (item.id !== payload.id));
    },
    setOfferItemGAEBMulti(state: CRMState, payload: ICRMOfferItemGAEB[]) {
        state.offerItemsGAEB = payload;
    },
    setOfferItemGAEBOne(state: CRMState, payload: ICRMOfferItemGAEB) {
        const offerItemGAEB = state.offerItemsGAEB.filter((item: ICRMOfferItemGAEB) => item.id !== payload.id);
        offerItemGAEB.push(payload);
        state.offerItemsGAEB = offerItemGAEB;
    },
    removeOfferItemGAEB(state: CRMState, payload: ICRMOfferItemGAEB) {
        state.offerItemsGAEB = state.offerItemsGAEB.filter(
            (item: ICRMOfferItemGAEB) => (item.id !== payload.id));
    },
};

const { commit } = getStoreAccessors<CRMState, State>('');

export const commitSetCustomers = commit(mutations.setCustomers);
export const commitSetCRMCompanies = commit(mutations.setCRMCompanies);
export const commitSetBranches = commit(mutations.setBranches);
export const commitSetOneCompany = commit(mutations.setOneCompany);
export const commitSetOneBranch = commit(mutations.setOneBranch);
export const commitRemoveOneBranch = commit(mutations.removeOneBranch);
export const commitSetOneContactPerson = commit(mutations.setOneContactPerson);
export const commitSetContactPersons = commit(mutations.setContactPersons);
export const commitSetContactPersonsFull = commit(mutations.setContactPersonsFull);
export const commitSetOneContactPersonFull = commit(mutations.setOneContactPersonFull);
export const commitSortContactPersonsFull = commit(mutations.sortContactPersonsFull);
export const commitRemoveOneContactPerson = commit(mutations.removeOneContactPerson);
export const commitSetCompanyARES = commit(mutations.setCompanyARES);

export const commitSetProductCategories = commit(mutations.setProductCategories);
export const commitSetOneProductCategory = commit(mutations.setOneProductCategory);
export const commitSetProductSubcategories = commit(mutations.setProductSubcategories);
export const commitSetOneProductSubcategory = commit(mutations.setOneProductSubcategory);
export const commitSetProductGroups = commit(mutations.setProductGroups);
export const commitSetNonEmptyProductGroups = commit(mutations.setNonEmptyProductGroups);
export const commitSetSelectedProductGroupInfo = commit(mutations.setSelectedProductGroupInfo);
export const commitSetOneProductGroup = commit(mutations.setOneProductGroup);
export const commitSetProductVariants = commit(mutations.setProductVariants);
export const commitSetProducts = commit(mutations.setProducts);
export const commitSetSelectedProduct = commit(mutations.setSelectedProduct);
export const commitSetProductsCount = commit(mutations.setProductsCount);
export const commitSetOneProduct = commit(mutations.setOneProduct);
export const commitSetOffersTable = commit(mutations.setOffersTable);
export const commitSetOfferItems = commit(mutations.setOfferItems);
export const commitSetOneOfferItem = commit(mutations.setOneOfferItem);
export const commitSetOfferItemsGrouped = commit(mutations.setOfferItemsGrouped);
export const commitSetOneOfferItemGrouped = commit(mutations.setOneOfferItemGrouped);
export const commitSetOneOfferItemsGroup = commit(mutations.setOneOfferItemsGroup);
export const commitAddOfferItemsToGroup = commit(mutations.addOfferItemsToGroup);
export const commitAddOfferItemsUnrouped = commit(mutations.addOfferItemsUnrouped);
export const commitSetOfferDiscounts = commit(mutations.setOfferDiscounts);
export const commitSetPriceLists = commit(mutations.setPriceLists);
export const commitSetPriceList = commit(mutations.setPriceList);
export const commitSetCurrentPriceList = commit(mutations.setCurrentPriceList);
export const commitSetIsCurrentPriceListEmpty = commit(mutations.setIsCurrentPriceListEmpty);
export const commitSetProductPrices = commit(mutations.setProductPrices);
export const commitSetProductPrice = commit(mutations.setProductPrice);
export const commitSetProductPriceDiscountGroups = commit(mutations.setProductPriceDiscountGroups);
export const commitSetOneProductPriceDiscountGroup = commit(mutations.setOneProductPriceDiscountGroup);
export const commitSetProductPricesMerge = commit(mutations.setProductPricesMerge);
export const commitAddPriceListExportTask = commit(mutations.addPriceListExportTask);
export const commitUpdatePriceListExportTask = commit(mutations.updatePriceListExportTask);
export const commitRemovePriceListExportTask = commit(mutations.removePriceListExportTask);
export const commitAddPriceListImportTask = commit(mutations.addPriceListImportTask);
export const commitUpdatePriceListImportTask = commit(mutations.updatePriceListImportTask);
export const commitRemovePriceListImportTask = commit(mutations.removePriceListImportTask);
export const commitAddPriceListPopulationTask = commit(mutations.addPriceListPopulationTask);
export const commitUpdatePriceListPopulationTask = commit(mutations.updatePriceListPopulationTask);
export const commitRemovePriceListPopulationTask = commit(mutations.removePriceListPopulationTask);
export const commitAddPriceListSyncTask = commit(mutations.addPriceListSyncTask);
export const commitUpdatePriceListSyncTask = commit(mutations.updatePriceListSyncTask);
export const commitRemovePriceListSyncTask = commit(mutations.removePriceListSyncTask);
export const commitAddPriceListPropagationTask = commit(mutations.addPriceListPropagationTask);
export const commitUpdatePriceListPropagationTask = commit(mutations.updatePriceListPropagationTask);
export const commitRemovePriceListPropagationTask = commit(mutations.removePriceListPropagationTask);
export const commitAddPriceListRecalculationTask = commit(mutations.addPriceListRecalculationTask);
export const commitUpdatePriceListRecalculationTask = commit(mutations.updatePriceListRecalculationTask);
export const commitRemovePriceListRecalculationTask = commit(mutations.removePriceListRecalculationTask);
export const commitSetPriceListRecalculationTaskReport = commit(mutations.setPriceListRecalculationTaskReport);
export const commitAddProductsExportTask = commit(mutations.addProductsExportTask);
export const commitUpdateProductsExportTask = commit(mutations.updateProductsExportTask);
export const commitRemoveProductsExportTask = commit(mutations.removeProductsExportTask);
export const commitAddProductsImportTask = commit(mutations.addProductsImportTask);
export const commitUpdateProductsImportTask = commit(mutations.updateProductsImportTask);
export const commitRemoveProductsImportTask = commit(mutations.removeProductsImportTask);
export const commitAddProductsImportCheckTask = commit(mutations.addProductsImportCheckTask);
export const commitUpdateProductsImportCheckTask = commit(mutations.updateProductsImportCheckTask);
export const commitRemoveProductsImportCheckTask = commit(mutations.removeProductsImportCheckTask);
export const commitSetProductsImportCheckTaskResult = commit(mutations.setProductsImportCheckTaskResult);
export const commitSetPriceListSyncTaskResult = commit(mutations.setPriceListSyncTaskResult);
export const commitSetOverheads = commit(mutations.setOverheads);
export const commitSetCompanyGroupRabats = commit(mutations.setCompanyGroupRabats);
export const commitSetOneCompanyGroupRabat = commit(mutations.setOneCompanyGroupRabat);

export const commitSetNumberSeries = commit(mutations.setNumberSeries);
export const commitUseNumberSeries = commit(mutations.useNumberSeries);
export const commitSetOneNumberSeries = commit(mutations.setOneNumberSeries);
export const commitSetOfferState = commit(mutations.setOfferState);
export const commitSetOfferValidity = commit(mutations.setOfferValidity);
export const commitSetOffers = commit(mutations.setOffers);
export const commitSetOneOffer = commit(mutations.setOneOffer);
export const commitSetOfferYears = commit(mutations.setOfferYears);
export const commitSetOfferProductGroups = commit(mutations.setOfferProductGroups);
export const commitSetOneOfferDiscount = commit(mutations.setOneOfferDiscount);
export const commitSetProductSizes = commit(mutations.setProductSizes);
export const commitSetProductTypes = commit(mutations.setProductTypes);
export const commitSetSizeTypeProducts = commit(mutations.setSizeTypeProducts);
export const commitRemoveOfferItem = commit(mutations.removeOfferItem);
export const commitRemoveOfferItemGrouped = commit(mutations.removeOfferItemGrouped);
export const commitRemoveOfferItemsGroup = commit(mutations.removeOfferItemsGroup);
export const commitRemoveOfferDiscount = commit(mutations.removeOfferDiscount);
export const commitSetRecalculatedOfferItem = commit(mutations.setRecalculatedOfferItem);
export const commitSetOfferMessageMulti = commit(mutations.setOfferMessageMulti);
export const commitSetOfferMessageOne = commit(mutations.setOfferMessageOne);
export const commitRemoveOfferMessage = commit(mutations.removeOfferMessage);
export const commitSetOfferGroupDefaults = commit(mutations.setOfferGroupDefaults);

export const commitSetPriceListGroups = commit(mutations.setPriceListGroups);
export const commitSetProductPriceSizes = commit(mutations.setProductPriceSizes);
export const commitSetProductPriceTypes = commit(mutations.setProductPriceTypes);
export const commitSetProductPriceSizeTypes = commit(mutations.setProductPriceSizeTypes);
export const commitSetPriceListDiscountMax = commit(mutations.setPriceListDiscountMax);
export const commitSetProductPricesCount = commit(mutations.setProductPricesCount);

export const commitSetProductComposite = commit(mutations.setProductComposite);
export const commitSetCreatedProduct = commit(mutations.setCreatedProduct);
export const commitSetProductPriceComposite = commit(mutations.setProductPriceComposite);

export const commitSetProductAttributes = commit(mutations.setProductAttributes);
export const commitSetCRMProjectMulti = commit(mutations.setCRMProjectMulti);
export const commitSetCRMProjectOne = commit(mutations.setCRMProjectOne);
export const commitRemoveCRMProject = commit(mutations.removeCRMProject);
export const commitSetOfferItemGAEBMulti = commit(mutations.setOfferItemGAEBMulti);
export const commitSetOfferItemGAEBOne = commit(mutations.setOfferItemGAEBOne);
export const commitRemoveOfferItemGAEB = commit(mutations.removeOfferItemGAEB);
