import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ITask, ITaskSchedule, ITaskResult, ITaskType, ITaskProgress, ITaskCreate } from '@/interfaces';
import { State } from '../state';
import { TaskState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetTasks
    , commitSetTaskSchedule
    , commitSetLastTaskResult
    , commitStatusInfo
    , commitTaskTypes
    , commitSetTaskWorkers
    , commitSetRunningTasks
    , commitSetOneRunningTask
    , commitSetOneTaskResult
} from './mutations';
import { readRunningTasks, readRunningTaskMap } from './getters'
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<TaskState, State>;

export const actions = {
    async actionGetTasks(context: MainContext) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getTasks(context.rootState.main.token);
            if (response) {
                //console.debug(response);
                commitSetTasks(context, response.data);
                let running: ITaskProgress[] = [];
                for (let task of response.data) {
                    if (task.status == 2) {
                        running.push({ task_id: task.id, progress: 0})
                    }
                }
                commitSetRunningTasks(context, running);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.tasksLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTaskSchedule(context: MainContext) {
        try {
            const response = await api.getSchedule(context.rootState.main.token);
            if (response) {
                commitSetTaskSchedule(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionLastTaskResult(context: MainContext, payload: { type: number }) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            // console.debug(payload);
            const response = await api.getLastTaskResult(context.rootState.main.token, payload);
            if (response) {
                // console.debug(response);
                commitSetLastTaskResult(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.resultLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTaskStatusInfo(context: MainContext) {
        try {
            const response = await api.getTaskStatusInfo(context.rootState.main.token);
            if (response) {
                // console.debug('action actionGetTaskStatusInfo');
                // console.debug(response.data)
                commitStatusInfo(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTaskTypes(context: MainContext) {
        try {
            const response = await api.getTaskTypes(context.rootState.main.token);
            if (response) {
                var taskTypes = new Map<number, ITaskType>();
                var types: ITaskType[] = response.data;
                types.forEach(function (value) {
                    taskTypes.set(value.type, value);
                });
                commitTaskTypes(context, taskTypes);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionTaskResult(context: MainContext, payload: { task_id: number }) {
        try {
            //const loadingNotification = { content: 'loading', showProgress: true };
            //commitAddNotification(context, loadingNotification);
            // console.debug(payload);
            const response = await api.getTaskResult(context.rootState.main.token, payload);
            if (response) {
                // console.debug(response.data);
                if (response.data.length > 0) {
                    commitSetLastTaskResult(context, response.data[0]);
                }
            }
            //commitRemoveNotification(context, loadingNotification);
            //commitAddNotification(context, { content: 'Result loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionTaskWorkers(context: MainContext) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            // console.debug(payload);
            const response = await api.getTaskWorkers(context.rootState.main.token);
            if (response) {
                // console.debug(response);
                commitSetTaskWorkers(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.workersLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionTaskProgress(context: MainContext) {
        try {
            let running: ITaskProgress[] = [];
            for (let progress of readRunningTasks(context)) {
                const response = await api.getTaskProgress(context.rootState.main.token, progress);
                if (response) {
                    running.push(response.data);
                }
            }
            commitSetRunningTasks(context, running);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionAddTask(context: MainContext, payload: ITaskCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.adding').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.addTask(context.rootState.main.token, payload);
            if (response) {
                await actions.actionGetTasks(context);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.taskAdded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionAddUserTask(context: MainContext, payload: ITaskCreate) {
        try {
            const response = await api.addTask(context.rootState.main.token, payload);
            if (response) {
                commitSetOneRunningTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionAddUserTaskType(context: MainContext, payload: { task_type: number, formData: FormData }) {
        try {
            const response = await api.addTaskType(context.rootState.main.token, payload);
            if (response) {
                commitSetOneRunningTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserTask(context: MainContext, payload: ITask) {
        try {
            const response = await api.getTask(context.rootState.main.token, { task_id: payload.id });
            if (response) {
                commitSetOneRunningTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUserTaskProgress(context: MainContext, payload: ITask) {
        try {
            const progress: ITaskProgress = { task_id: payload.id, progress: 0 };
            const response = await api.getTaskProgress(context.rootState.main.token, progress);
            if (response) {
                let task = readRunningTaskMap(context).get(payload.type);
                if (task == null) {
                    return;
                }
                if (task.progress == null) {
                    task['progress'] = 0;
                }
                task.progress = response.data.progress;
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUserTaskDownload(context: MainContext, payload: { task: ITask, fileName: string }) {
        try {
            const response = (await Promise.all([
                api.downloadTaskFile(context.rootState.main.token, payload.task),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            const now = new Date();

            fileLink.href = fileURL;
            fileLink.setAttribute('download', payload.fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetUserTaskResult(context: MainContext, payload: { task_id: number }) {
        try {
            const response = await api.getTaskResult(context.rootState.main.token, payload);
            if (response) {
                if (response.data.length > 0) {
                    commitSetOneTaskResult(context, response.data[0]);
                }
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<TaskState, State>('');

export const dispatchGetTasks = dispatch(actions.actionGetTasks);
export const dispatchGetTaskSchedule = dispatch(actions.actionGetTaskSchedule);
export const dispatchGetLastTaskResult = dispatch(actions.actionLastTaskResult);
export const dispatchGetTaskStatusInfo = dispatch(actions.actionGetTaskStatusInfo);
export const dispatchGetTaskTypes = dispatch(actions.actionGetTaskTypes);
export const dispatchGetTaskResult = dispatch(actions.actionTaskResult);
export const dispatchGetTaskWorkers = dispatch(actions.actionTaskWorkers);
export const dispatchGetTaskProgress = dispatch(actions.actionTaskProgress);
export const dispatchAddTask = dispatch(actions.actionAddTask);
export const dispatchAddUserTask = dispatch(actions.actionAddUserTask);
export const dispatchAddUserTaskType = dispatch(actions.actionAddUserTaskType);
export const dispatchGetUserTask = dispatch(actions.actionGetUserTask);
export const dispatchUserTaskProgress = dispatch(actions.actionUserTaskProgress);
export const dispatchUserTaskDownload = dispatch(actions.actionUserTaskDownload);
export const dispatchGetUserTaskResult = dispatch(actions.actionGetUserTaskResult);
