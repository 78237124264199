export const getLocalToken = () => localStorage.getItem('token');
export const saveLocalToken = (token: string) => localStorage.setItem('token', token);
export const removeLocalToken = () => localStorage.removeItem('token');

export const getLocalAttribute = (key: string) => localStorage.getItem(key);
export const saveLocalAttribute = (key: string, value: string) => localStorage.setItem(key, value);
export const removeLocalAttribute = (key: string) => localStorage.removeItem(key);

export const getLocalAttributeBool = (key: string) => {
    const value = localStorage.getItem(key);
    if (value)  {
        return (+value != 0) ? true : false;
    }
    return false;
}
export const saveLocalAttributeBool = (key: string, value: boolean) => {
     localStorage.setItem(key, value ? '1' : '0');
}
