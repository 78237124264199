import {
    IImageFile
} from '@/interfaces/gallery';
import { GalleryState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setImages(state: GalleryState, payload: IImageFile[]) {
        state.images = payload;
    },
};

const { commit } = getStoreAccessors<GalleryState, State>('');

export const commitSetImages = commit(mutations.setImages);
