import {
    IFinanceCurrency
    , IFinancePaymentMethod
    , IFinanceTaxRate
} from '@/interfaces/finance';
import { FinanceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setCurrencies(state: FinanceState, payload: IFinanceCurrency[]) {
        state.currencies = payload;
    },
    setPaymentMethod(state: FinanceState, payload: IFinancePaymentMethod[]) {
        state.paymentMethods = payload;
    },
    setOnePaymentMethod(state: FinanceState, payload: IFinancePaymentMethod) {
        const paymentMethods = state.paymentMethods.filter((method: IFinancePaymentMethod) => method.id !== payload.id);
        paymentMethods.push(payload);
        state.paymentMethods = paymentMethods;
    },
    setTaxRatesMulti(state: FinanceState, payload: IFinanceTaxRate[]) {
        state.taxRates = payload;
    },
    setTaxRatesOne(state: FinanceState, payload: IFinanceTaxRate) {
        const taxRates = state.taxRates.filter((item: IFinanceTaxRate) => item.id !== payload.id);
        taxRates.push(payload);
        state.taxRates = taxRates;
    },
    removeTaxRates(state: FinanceState, payload: IFinanceTaxRate) {
        state.taxRates = state.taxRates.filter(
            (item: IFinanceTaxRate) => (item.id !== payload.id));
    },
};

const { commit } = getStoreAccessors<FinanceState, State>('');

export const commitSetCurrencies = commit(mutations.setCurrencies);
export const commitSetPaymentMethod = commit(mutations.setPaymentMethod);
export const commitSetOnePaymentMethod = commit(mutations.setOnePaymentMethod);
export const commitSetTaxRatesMulti = commit(mutations.setTaxRatesMulti);
export const commitSetTaxRatesOne = commit(mutations.setTaxRatesOne);
export const commitRemoveTaxRates = commit(mutations.removeTaxRates);
