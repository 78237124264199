import { getStoreAccessors } from 'typesafe-vuex';
import { IUserProfile } from '@/interfaces';
import { LogisticsState } from './state';
import { State } from '../state';


export const getters = {
    expeditions: (state: LogisticsState) => state.expeditions,
    oneExpedition: (state: LogisticsState) => (expeditionID: number) => {
        const filteredexpeditions = state.expeditions.filter((expedition) => expedition.id === expeditionID);
        if (filteredexpeditions.length > 0) {
            return { ...filteredexpeditions[0] };
        }
    },
    ahutranFile: (state: LogisticsState) => state.ahutranFile,
    transports: (state: LogisticsState) => state.transports,
};

const { read } = getStoreAccessors<LogisticsState, State>('');

export const readExpeditions = read(getters.expeditions);
export const readOneExpedition = read(getters.oneExpedition);
export const readAHUtranFile = read(getters.ahutranFile);
export const readTransports = read(getters.transports);
