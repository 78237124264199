import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminActions: (state: AdminState) => state.actions,
    adminOneAction: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            //return { ...filteredUsers[0].actions };
            return { };
        }
    },
    adminRoles: (state: AdminState) => state.roles,
    adminOneRole: (state: AdminState) => (roleID: number) => {
        const filteredRoles = state.roles.filter((role) => role.id === roleID);
        if (filteredRoles.length > 0) {
            return { ...filteredRoles[0] };
        }
    },
    adminLog: (state: AdminState) => state.log,
    dictionary: (state: AdminState) => state.dictionary,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminActions = read(getters.adminActions);
export const readAdminOneAction = read(getters.adminOneAction);
export const readAdminRoles = read(getters.adminRoles);
export const readAdminOneRole = read(getters.adminOneRole);
export const readAdminLog = read(getters.adminLog);

export const readLocaleDictionary = read(getters.dictionary);
