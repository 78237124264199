import { ServiceState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    cases: (state: ServiceState) => state.cases,
    caseStatusInfo: (state: ServiceState) => state.statusInfo,
    oneCase: (state: ServiceState) => (caseID: number) => {
        const filteredCases = state.cases.filter((_case) => _case.id === caseID);
        if (filteredCases.length > 0) {
            return { ...filteredCases[0] };
        }
    },
    stepTypeInfo: (state: ServiceState) => state.stepTypeInfo,
    steps: (state: ServiceState) => state.steps,
    payers: (state: ServiceState) => state.payers,
    report: (state: ServiceState) => state.report,
    servicePriceLists: (state: ServiceState) => state.servicePriceLists,
    servicePriceListOne: (state: ServiceState) => (servicePriceListID: number) => {
        const filteredServicePriceList = state.servicePriceLists.filter((servicePriceList) => servicePriceList.id === servicePriceListID);
        if (filteredServicePriceList.length > 0) {
            return { ...filteredServicePriceList[0] };
        }
    },
    operations: (state: ServiceState) => state.operations,
};

const { read } = getStoreAccessors<ServiceState, State>('');

export const readCases = read(getters.cases);
export const readCaseStatusInfo = read(getters.caseStatusInfo);
export const readOneCase = read(getters.oneCase);
export const readStepTypeInfo = read(getters.stepTypeInfo);
export const readSteps = read(getters.steps);
export const readPayers = read(getters.payers);
export const readReport = read(getters.report);
export const readServicePriceLists = read(getters.servicePriceLists);
export const readServicePriceListOne = read(getters.servicePriceListOne);
export const readServiceOperations = read(getters.operations);
