import { getStoreAccessors } from 'typesafe-vuex';
import { CRMState } from './state';
import { State } from '../state';


export const getters = {
    customers: (state: CRMState) => state.customers,
    companiesCRM: (state: CRMState) => state.companies,
    oneCompany: (state: CRMState) => (companyID: number) => {
        const filteredCompanies = state.companies.filter((company) => company.id === companyID);
        if (filteredCompanies.length > 0) {
            return { ...filteredCompanies[0] };
        }
    },
    companyARES: (state: CRMState) => state.companyARES,
    branches: (state: CRMState) => state.branches,
    oneBranch: (state: CRMState) => (branchID: number) => {
        const filteredBranches = state.branches.filter((branch) => branch.id === branchID);
        if (filteredBranches.length > 0) {
            return { ...filteredBranches[0] };
        }
    },
    contact_persons: (state: CRMState) => state.contactPersons,
    contactPersonsFull: (state: CRMState) => state.contactPersonsFull,
    oneContactPerson: (state: CRMState) => (contactID: number) => {
        const filteredContacts = state.contactPersons.filter((contact) => contact.id === contactID);
        if (filteredContacts.length > 0) {
            return { ...filteredContacts[0] };
        }
    },
    oneContactPersonFull: (state: CRMState) => state.oneContactPersonFull,
    productCategories: (state: CRMState) => state.productCategories,
    oneProductCategory: (state: CRMState) => (categoryID: number) => {
        const filteredCategories = state.productCategories.filter((category) => category.id === categoryID);
        if (filteredCategories.length > 0) {
            return { ...filteredCategories[0] };
        }
    },
    productSubcategories: (state: CRMState) => state.productSubcategories,
    oneProductSubcategory: (state: CRMState) => (subcategoryID: number) => {
        const filteredSubcategories = state.productSubcategories.filter((subcategory) => subcategory.id === subcategoryID);
        if (filteredSubcategories.length > 0) {
            return { ...filteredSubcategories[0] };
        }
    },
    productSubcategoriesByCategory: (state: CRMState) => (categoryID: number) => {
        return state.productSubcategories.filter((subcategory) => subcategory.category_id === categoryID);
    },
    productGroups: (state: CRMState) => state.productGroups,
    oneProductGroup: (state: CRMState) => (groupID: number) => {
        const filteredGroups = state.productGroups.filter((group) => group.id === groupID);
        if (filteredGroups.length > 0) {
            return { ...filteredGroups[0] };
        }
    },
    nonEmptyProductGroups: (state: CRMState) => state.nonEmptyProductGroups,
    selectedProductGroupInfo: (state: CRMState) => state.selectedProductGroupInfo,
    productVariants: (state: CRMState) => state.productVariants,
    products: (state: CRMState) => state.products,
    product: (state: CRMState) => state.product,
    offersTable: (state: CRMState) => state.offersTable,
    offerItems: (state: CRMState) => state.offerItems,
    offerItemsGrouped: (state: CRMState) => state.offerItemsGrouped,
    offerDiscounts: (state: CRMState) => state.offerDiscounts,
    priceLists: (state: CRMState) => state.priceLists,
    currentPriceList: (state: CRMState) => state.currentPriceList,
    productPrices: (state: CRMState) => state.productPrices,
    productPricesMerge: (state: CRMState) => state.productPricesMerge,
    priceListExportTasks: (state: CRMState) => state.priceListExportTasks,
    priceListImportTasks: (state: CRMState) => state.priceListImportTasks,
    priceListPopulationTasks: (state: CRMState) => state.priceListPopulationTasks,
    priceListSyncTasks: (state: CRMState) => state.priceListSyncTasks,
    priceListSyncTaskResult: (state: CRMState) => state.priceListSyncTaskResult,
    priceListPropagationTasks: (state: CRMState) => state.priceListPropagationTasks,
    priceListRecalculationTasks: (state: CRMState) => state.priceListRecalculationTasks,
    priceListRecalculationTaskResult: (state: CRMState) => state.priceListRecalculationTaskResult,
    productsExportTasks: (state: CRMState) => state.productsExportTasks,
    productsImportTasks: (state: CRMState) => state.productsImportTasks,
    productsImportCheckTasks: (state: CRMState) => state.productsImportCheckTasks,
    productsImportCheckTaskResult: (state: CRMState) => state.productsImportCheckTaskResult,
    overheads: (state: CRMState) => state.overheads,
    companyGroupRabats: (state: CRMState) => state.companyGroupRabats,
    numberSeries: (state: CRMState) => state.numberSeries,
    useNumberSeries: (state: CRMState) => state.useNumberSeries,
    offerState: (state: CRMState) => state.offerState,
    offerValidity: (state: CRMState) => state.offerValidity,
    offers: (state: CRMState) => state.offers,
    oneOffer: (state: CRMState) => (offerID: number) => {
        const filteredOffers = state.offers.filter((offer) => offer.id === offerID);
        if (filteredOffers.length > 0) {
            return { ...filteredOffers[0] };
        }
    },
    offerYears: (state: CRMState) => state.offerYears,
    offerProductGroups: (state: CRMState) => state.offerProductGroups,
    productSizes: (state: CRMState) => state.productSizes,
    productTypes: (state: CRMState) => state.productTypes,
    sizeTypeProducts: (state: CRMState) => state.sizeTypeProducts,
    recalculatedOfferItem: (state: CRMState) => state.recalculatedOfferItem,
    productPriceDiscountGroups: (state: CRMState) => state.productPriceDiscountGroups,
    offerMessageMulti: (state: CRMState) => state.offerMessage,
    offerMessageOne: (state: CRMState) => (offerMessageID: number) => {
        const filteredOfferMessage = state.offerMessage.filter((offerMessage) => offerMessage.id === offerMessageID);
        if (filteredOfferMessage.length > 0) {
            return { ...filteredOfferMessage[0] };
        }
    },
    priceListGroups: (state: CRMState) => state.priceListGroups,
    productPriceSizes: (state: CRMState) => state.productPriceSizes,
    productPriceTypes: (state: CRMState) => state.productPriceTypes,
    productPriceSizeTypes: (state: CRMState) => state.productPriceSizeTypes,
    discountMax: (state: CRMState) => state.discountMax,
    productsCount: (state: CRMState) => state.productsCount,
    productPricesCount: (state: CRMState) => state.productPricesCount,
    productComposite: (state: CRMState) => state.productComposite,
    offerGroupDefaults: (state: CRMState) => state.offerGroupDefaults,
    createdProduct: (state: CRMState) => state.createdProduct,
    productPriceComposite: (state: CRMState) => state.productPriceComposite,
    productAttributes: (state: CRMState) => state.productAttributes,
    crmProjects: (state: CRMState) => state.projects,
    crmProjectOne: (state: CRMState) => (projectID: number) => {
        const filteredCRMProject = state.projects.filter((project) => project.id === projectID);
        if (filteredCRMProject.length > 0) {
            return { ...filteredCRMProject[0] };
        }
    },
    offerItemGAEBMulti: (state: CRMState) => state.offerItemsGAEB,
    offerItemGAEBOne: (state: CRMState) => (offerItemGAEB_ID: number) => {
        const filteredOfferItemsGAEB = state.offerItemsGAEB.filter((offerItemGAEB) => offerItemGAEB.id === offerItemGAEB_ID);
        if (filteredOfferItemsGAEB.length > 0) {
            return { ...filteredOfferItemsGAEB[0] };
        }
    },
};

const { read } = getStoreAccessors<CRMState, State>('');

export const readCustomers = read(getters.customers);
export const readCRMCompanies = read(getters.companiesCRM);
export const readBranches = read(getters.branches);
export const readContactPersons = read(getters.contact_persons);
export const readContactPersonsFull = read(getters.contactPersonsFull);
export const readOneCompany = read(getters.oneCompany);
export const readOneBranch = read(getters.oneBranch);
export const readOneContactPerson = read(getters.oneContactPerson);
export const readOneContactPersonFull = read(getters.oneContactPersonFull);
export const readCompanyARES = read(getters.companyARES);

export const readProductCategories = read(getters.productCategories);
export const readOneProductCategory = read(getters.oneProductCategory);
export const readProductSubcategories = read(getters.productSubcategories);
export const readOneProductSubcategory = read(getters.oneProductSubcategory);
export const readProductSubcategoriesByCategory = read(getters.productSubcategoriesByCategory);
export const readProductGroups = read(getters.productGroups);
export const readOneProductGroup = read(getters.oneProductGroup);
export const readNonEmptyProductGroups = read(getters.nonEmptyProductGroups);
export const readSelectedProductGroupInfo = read(getters.selectedProductGroupInfo);
export const readProductVariants = read(getters.productVariants);
export const readProducts = read(getters.products);
export const readSelectedProduct = read(getters.product);
export const readProductsCount = read(getters.productsCount);
export const readOffersTable = read(getters.offersTable);
export const readOfferItems = read(getters.offerItems);
export const readOfferItemsGrouped = read(getters.offerItemsGrouped);
export const readOfferDiscounts = read(getters.offerDiscounts);
export const readPriceLists = read(getters.priceLists);
export const readCurrentPriceList = read(getters.currentPriceList);
export const readProductPrices = read(getters.productPrices);
export const readProductPricesMerge = read(getters.productPricesMerge);
export const readPriceListExportTasks = read(getters.priceListExportTasks);
export const readPriceListImportTasks = read(getters.priceListImportTasks);
export const readPriceListPopulationTasks = read(getters.priceListPopulationTasks);
export const readPriceListSyncTasks = read(getters.priceListSyncTasks);
export const readPriceListSyncTaskResult = read(getters.priceListSyncTaskResult);
export const readPriceListPropagationTasks = read(getters.priceListPropagationTasks);
export const readPriceListRecalculationTasks = read(getters.priceListRecalculationTasks);
export const readPriceListRecalculationTaskResult = read(getters.priceListRecalculationTaskResult);
export const readProductsExportTasks = read(getters.productsExportTasks);
export const readProductsImportTasks = read(getters.productsImportTasks);
export const readProductsImportCheckTasks = read(getters.productsImportCheckTasks);
export const readProductsImportCheckTaskResult = read(getters.productsImportCheckTaskResult);
export const readOverheads = read(getters.overheads);
export const readCompanyGroupRabats = read(getters.companyGroupRabats);
export const readProductPriceDiscountGroups = read(getters.productPriceDiscountGroups);

export const readNumberSeries = read(getters.numberSeries);
export const readUsedNumberSeries = read(getters.useNumberSeries);
export const readOfferState = read(getters.offerState);
export const readOfferValidity = read(getters.offerValidity);
export const readOffers = read(getters.offers);
export const readOneOffer = read(getters.oneOffer);
export const readOfferYears = read(getters.offerYears);
export const readOfferProductGroups = read(getters.offerProductGroups);
export const readProductSizes = read(getters.productSizes);
export const readProductTypes = read(getters.productTypes);
export const readSizeTypeProducts = read(getters.sizeTypeProducts);
export const readRecalculatedOfferItem = read(getters.recalculatedOfferItem);
export const readOfferMessageMulti = read(getters.offerMessageMulti);
export const readOfferMessageOne = read(getters.offerMessageOne);
export const readOfferGroupDefaults = read(getters.offerGroupDefaults);

export const readPriceListGroups = read(getters.priceListGroups);
export const readProductPriceSizes = read(getters.productPriceSizes);
export const readProductPriceTypes = read(getters.productPriceTypes);
export const readProductPriceSizeTypes = read(getters.productPriceSizeTypes);
export const readPriceListDiscountMax = read(getters.discountMax);
export const readProductPricesCount = read(getters.productPricesCount);

export const readProductComposite = read(getters.productComposite);
export const readCreatedProduct = read(getters.createdProduct);
export const readProductPriceComposite = read(getters.productPriceComposite);

export const readProductAttributes = read(getters.productAttributes);

export const readCRMProjectMulti = read(getters.crmProjects);
export const readCRMProjectOne = read(getters.crmProjectOne);
export const readOfferItemGAEBMulti = read(getters.offerItemGAEBMulti);
export const readOfferItemGAEBOne = read(getters.offerItemGAEBOne);
