import { api } from '@/api';
import { ActionContext } from 'vuex';
import {
    ICRMProductGroup
    , ICRMCompany
    , ICRMBranch
    , ICRMContactPerson
    , ICRMCompanyCreate
    , ICRMBranchCreate
    , ICRMContactPersonCreate
    , ICRMCompanyUpdate
    , ICRMBranchUpdate
    , ICRMContactPersonUpdate
    , ICRMProductGroupCreate
    , ICRMOfferTableViev
    , ICRMPriceListCreate
    , ICRMProductPrice
    , IExportXLSXTask
    , IImportXLSXTask
    , ISyncTask
    , IPropagationTask
    , IRecalculationTask
    , ICRMPriceListExport
    , ICRMPriceListPopulation
    , ICRMProductCreate
    , ICRMProductGroupUpdate
    , ICRMProductCategoryUpdate
    , ICRMProductSubategoryUpdate
    , ICRMProductsExport
    , ICRMPriceListSync
    , ICRMOverheadsUpdate
    , ICRMProductGroupRabat
    , ICRMProductCategorySwap
    , ICRMProductGroupSwap
    , ICRMNumberSeries
    , ICRMNumberSeriesCreate
    , ICRMOfferCreate
    , ICRMOfferUpdate
    , ICRMOffer
    , ICRMOfferItem
    , ICRMOfferItemCreate
    , ICRMOfferItemUpdate
    , ICRMOfferDiscount
    , ICRMOfferDiscountCreate
    , ICRMOfferDiscountUpdate
    , ICRMProductPriceDiscountGroup
    , ICRMProductPriceDiscountGroupUpdate
    , ICRMOfferSend
    , ICRMOfferMessage
    , ICRMOfferMessageCreate
    , ICRMOfferMessageUpdate
    , ICRMDiscountMax
    , ICRMProductComposite
    , ICRMProductCompositeUpdate
    , IRMOfferGroupDefaultsCreate
    , IRMOfferGroupDefaultsUpdate
    , ICRMProductPriceUpdate
    , ICRMProject
    , ICRMProjectCreate
    , ICRMProjectUpdate
    , ICRMOfferItemGAEB
    , ICRMOfferItemGAEBCreate
    , ICRMOfferItemGAEBUpdate
} from '@/interfaces/crm';
import { State } from '../state';
import { CRMState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetCustomers
    , commitSetCRMCompanies
    , commitSetBranches
    , commitSetContactPersons
    , commitSetContactPersonsFull
    , commitSortContactPersonsFull
    , commitSetOneContactPersonFull
    , commitSetOneCompany
    , commitSetOneBranch
    , commitSetOneContactPerson
    , commitSetProducts
    , commitSetOneProduct
    , commitSetSelectedProduct
    , commitSetProductGroups
    , commitSetOneProductGroup
    , commitSetOffersTable
    , commitSetOfferItems
    , commitSetOfferItemsGrouped
    , commitSetOfferDiscounts
    , commitSetPriceLists
    , commitSetProductCategories
    , commitSetPriceList
    , commitSetCurrentPriceList
    , commitSetIsCurrentPriceListEmpty
    , commitSetProductPrices
    , commitSetProductPrice
    , commitSetProductPriceComposite
    , commitSetProductPricesMerge
    , commitAddPriceListExportTask
    , commitUpdatePriceListExportTask
    , commitRemovePriceListExportTask
    , commitAddPriceListImportTask
    , commitUpdatePriceListImportTask
    , commitAddPriceListPopulationTask
    , commitUpdatePriceListPopulationTask
    , commitSetProductVariants
    , commitAddPriceListPropagationTask
    , commitUpdatePriceListPropagationTask
    , commitAddPriceListRecalculationTask
    , commitUpdatePriceListRecalculationTask
    , commitSetPriceListRecalculationTaskReport
    , commitSetOverheads
    , commitUpdateProductsExportTask
    , commitSetNonEmptyProductGroups
    , commitRemoveProductsExportTask
    , commitAddProductsExportTask
    , commitAddProductsImportTask
    , commitUpdateProductsImportTask
    , commitAddProductsImportCheckTask
    , commitUpdateProductsImportCheckTask
    , commitSetProductsImportCheckTaskResult
    , commitAddPriceListSyncTask
    , commitUpdatePriceListSyncTask
    , commitSetPriceListSyncTaskResult
    , commitSetOneProductCategory
    , commitSetOneProductSubcategory
    , commitSetProductSubcategories
    , commitSetCompanyGroupRabats
    , commitSetOneCompanyGroupRabat
    , commitSetSelectedProductGroupInfo
    , commitSetCompanyARES
    , commitSetNumberSeries
    , commitUseNumberSeries
    , commitSetOneNumberSeries
    , commitSetOfferState
    , commitSetOfferValidity
    , commitSetOffers
    , commitSetOneOffer
    , commitSetOfferYears
    , commitSetOfferProductGroups
    , commitSetOneOfferItem
    , commitSetOneOfferItemsGroup
    , commitSetProductSizes
    , commitSetProductTypes
    , commitSetSizeTypeProducts
    , commitSetOneOfferDiscount
    , commitRemoveOfferItem
    , commitRemoveOfferItemGrouped
    , commitRemoveOfferDiscount
    , commitSetRecalculatedOfferItem
    , commitSetProductPriceDiscountGroups
    , commitSetOneProductPriceDiscountGroup
    , commitSetOfferMessageMulti
    , commitSetOfferMessageOne
    , commitRemoveOfferMessage
    , commitSetPriceListGroups
    , commitSetProductPriceSizes
    , commitSetProductPriceTypes
    , commitSetProductPriceSizeTypes
    , commitSetPriceListDiscountMax
    , commitSetProductsCount
    , commitSetProductPricesCount
    , commitSetOneOfferItemGrouped
    , commitAddOfferItemsToGroup
    , commitAddOfferItemsUnrouped
    , commitSetProductComposite
    , commitSetOfferGroupDefaults
    , commitSetCreatedProduct
    , commitSetProductAttributes
    , commitSetCRMProjectMulti
    , commitSetCRMProjectOne
    , commitRemoveCRMProject
    , commitSetOfferItemGAEBMulti
    , commitSetOfferItemGAEBOne
    , commitRemoveOfferItemGAEB
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification
        , commitRemoveNotification
} from '../main/mutations';
import { formatDateCompact } from '@/tools/datetime';
import { i18n } from "@/plugins/i18n";
import { IDataCriteria } from '@/interfaces';


type MainContext = ActionContext<CRMState, State>;

export const actions = {
    async actionGetCustomers(context: MainContext) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getCRMCustomers(context.rootState.main.token);
            if (response) {
                commitSetCustomers(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.customersLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCRMCompanies(context: MainContext, payload: { active: number }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMCompanies(context.rootState.main.token, payload.active);
            if (response) {
                commitSetCRMCompanies(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Companies loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetBranches(context: MainContext, payload: { active: number }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMBranches(context.rootState.main.token, payload.active);
            if (response) {
                commitSetBranches(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Branches loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetContactPersons(context: MainContext, payload: { active: number }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMContactPersons(context.rootState.main.token, payload.active);
            if (response) {
                commitSetContactPersons(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Contact persons loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetContactPersonsFull(context: MainContext, payload: { active: number }, sort: boolean = false) {
        try {
            const response = await api.getCRMContactPersonsFull(context.rootState.main.token, payload.active);
            if (response) {
                commitSetContactPersonsFull(context, response.data);
                commitSortContactPersonsFull(context);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneContactPersonFull(context: MainContext, payload: { contactID: number }) {
        try {
            const response = await api.getCRMOneContactPersonFull(context.rootState.main.token, payload.contactID);
            if (response) {
                commitSetOneContactPersonFull(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneCompany(context: MainContext, payload: { companyID: number }) {
        try {
            const response = await api.getCRMOneCompany(context.rootState.main.token, payload);
            if (response) {
                commitSetOneCompany(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCompanyARES(context: MainContext, payload: string) {
        try {
            if (payload == null) {
                commitAddNotification(context, { content: i18n.t('notification.regNumberNotValid').toString(), color: 'warning' });
                return;
            }
            const rex = /\d\d\d\d\d\d\d\d/;
            if (payload.match(rex) == null) {
                commitAddNotification(context, { content: i18n.t('notification.regNumberNotValid').toString(), color: 'warning' });
                return;
            }
            const response = await api.getCRMCompanyARES(context.rootState.main.token, payload);
            if (response) {
                commitSetCompanyARES(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCompany(context: MainContext, payload: ICRMCompanyCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMCompany(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            if (response.data) {
                commitSetOneCompany(context, response.data);
                commitRemoveNotification(context, savingNotification);
            }
            commitAddNotification(context, { content: i18n.t('notification.companyCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCompanyBranch(context: MainContext, payload: ICRMCompanyCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMCompanyBranch(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            if (response.data) {
                commitSetOneCompany(context, response.data.company);
                commitSetOneBranch(context, response.data.branch);
                commitRemoveNotification(context, savingNotification);
            }
            commitAddNotification(context, { content: i18n.t('notification.companyCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateBranch(context: MainContext, payload: ICRMBranchCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMBranch(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneBranch(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.branchCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateContactPerson(context: MainContext, payload: ICRMContactPersonCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMContactPerson(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneContactPerson(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.contactPersonCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCompany(context: MainContext, payload: { id: number, company: ICRMCompanyUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCRMCompany(context.rootState.main.token, payload.id, payload.company),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneCompany(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.companyUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateBranch(context: MainContext, payload: { id: number, branch: ICRMBranchUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCRMBranch(context.rootState.main.token, payload.id, payload.branch),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneBranch(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.branchUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateContactPerson(context: MainContext, payload: { id: number, contact: ICRMContactPersonUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCRMContactPerson(context.rootState.main.token, payload.id, payload.contact),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneContactPerson(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.contactPersonUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductCategories(context: MainContext) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMProductCategories(context.rootState.main.token);
            if (response) {
                commitSetProductCategories(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Product categories loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductCategory(context: MainContext, payload: { id: number, category: ICRMProductCategoryUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCRMProductCategory(context.rootState.main.token, payload.id, payload.category),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneProductCategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.categoryUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSwapProductCategoriesIndex(context: MainContext, payload: ICRMProductCategorySwap) {
        try {
            const response = (await Promise.all([
                api.swapCRMProductCategoriesIndex(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            // commitSetOneProductCategory(context, response.data);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductSubcategories(context: MainContext) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMProductSubcategories(context.rootState.main.token);
            if (response) {
                commitSetProductSubcategories(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Product subcategories loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductSubcategory(context: MainContext, payload: { id: number, subcategory: ICRMProductSubategoryUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCRMProductSubcategory(context.rootState.main.token, payload.id, payload.subcategory),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneProductSubcategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.subcategoryUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductGroups(context: MainContext, only_active: boolean = true) {
        try {
            const response = await api.getCRMProductGroups(context.rootState.main.token, only_active);
            if (response) {
                commitSetProductGroups(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductGroupInfo(context: MainContext, payload: ICRMProductGroup) {
        try {
            const response = await api.getCRMProductGroupWithLinks(context.rootState.main.token, payload.id);
            if (response) {
                commitSetSelectedProductGroupInfo(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetNonEmptyProductGroups(context: MainContext) {
        try {
            const response = await api.getCRMNonEmptyProductGroups(context.rootState.main.token);
            if (response) {
                commitSetNonEmptyProductGroups(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateProductGroup(context: MainContext, payload: ICRMProductGroupCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMProductGroup(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneProductGroup(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.productGroupCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductGroup(context: MainContext, payload: { id: number, group: ICRMProductGroupUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateCRMProductGroup(context.rootState.main.token, payload.id, payload.group),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneProductGroup(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.groupUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSwapProductGroupsIndex(context: MainContext, payload: ICRMProductGroupSwap) {
        try {
            const response = (await Promise.all([
                api.swapCRMProductGroupsIndex(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductVariants(context: MainContext) {
        try {
            const response = await api.getCRMProductVariants(context.rootState.main.token);
            if (response) {
                commitSetProductVariants(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProducts(context: MainContext) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMProducts(context.rootState.main.token);
            if (response) {
                commitSetProducts(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Products loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetSelectedProduct(context: MainContext, payload: number) {
        try {
            const response = await api.getCRMSelectedProduct(context.rootState.main.token, payload);
            if (response) {
                commitSetSelectedProduct(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateProduct(context: MainContext, payload: ICRMProductCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            commitSetCreatedProduct(context, null);
            const response = (await Promise.all([
                api.createCRMProduct(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOneProduct(context, response.data[0]);
            commitSetCreatedProduct(context, response.data[0]);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.productCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductsByGroup(context: MainContext, payload: { data: ICRMProductGroup, criteria: IDataCriteria }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMProductsByGroup(context.rootState.main.token, payload.data, payload.criteria);
            if (response) {
                commitSetProducts(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Products loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCompositeProductsByGroup(context: MainContext, payload: { data: ICRMProductGroup, criteria: IDataCriteria }) {
        try {
            const response = await api.getCRMProductsByGroup(
                context.rootState.main.token
                , payload.data
                , payload.criteria
                , true
            );
            if (response) {
                commitSetProducts(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductsByGroupFull(context: MainContext, payload: { data: ICRMProductGroup, criteria: IDataCriteria }) {
        try {
            const response = await api.getCRMProductsByGroupFull(context.rootState.main.token, payload.data, payload.criteria);
            if (response) {
                commitSetProducts(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOffersTable(context: MainContext, payload: { year: number }) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getCRMOffersTable(context.rootState.main.token, payload.year);
            if (response) {
                commitSetOffersTable(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.offersLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferItems(context: MainContext, payload: { offerID: number }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMOfferItems(context.rootState.main.token, payload.offerID);
            if (response) {
                commitSetOfferItems(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Offer items loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferItemsGrouped(context: MainContext, payload: { offerID: number }) {
        try {
            const response = await api.getCRMOfferItemsGrouped(context.rootState.main.token, payload.offerID);
            if (response) {
                commitSetOfferItemsGrouped(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneOfferItemsGroup(context: MainContext, payload: { offerID: number, groupID: number }) {
        try {
            const response = await api.getCRMOneOfferItemsGroup(context.rootState.main.token, payload.offerID, payload.groupID);
            if (response) {
                commitSetOneOfferItemsGroup(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferDiscounts(context: MainContext, payload: { offerID: number }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMOfferDiscounts(context.rootState.main.token, payload.offerID);
            if (response) {
                commitSetOfferDiscounts(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Offer discounts loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferDiscountsCalculated(context: MainContext, payload: { offerID: number }) {
        try {
            const response = await api.getCRMOfferDiscountsCalculated(context.rootState.main.token, payload.offerID);
            if (response) {
                commitSetOfferDiscounts(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceLists(context: MainContext) {
        try {
            //const loadingNotification = { content: 'loading', showProgress: true };
            //commitAddNotification(context, loadingNotification);
            const response = await api.getCRMPriceList(context.rootState.main.token);
            if (response) {
                commitSetPriceLists(context, response.data);
            }
            //commitRemoveNotification(context, loadingNotification);
            //commitAddNotification(context, { content: 'Price lists loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOnePriceList(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.getCRMOnePriceList(context.rootState.main.token, payload);
            if (response) {
                commitSetCurrentPriceList(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckPriceListEmpty(context: MainContext, payload: { id: number }) {
        try {
            const response = await api.isCRMPriceListEmpty(context.rootState.main.token, payload);
            if (response) {
                commitSetIsCurrentPriceListEmpty(context, response.data.is_empty);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceListGroups(context: MainContext, payload: { listID: number }) {
        try {
            const response = await api.getCRMPriceListGroups(context.rootState.main.token, payload);
            if (response) {
                commitSetPriceListGroups(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreatePriceList(context: MainContext, payload: ICRMPriceListCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMPriceList(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetPriceList(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.priceListCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGeneratePriceList(context: MainContext, payload: { listID: number }) {
        try {
            const savingNotification = { content: i18n.t('notification.generating').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.generateCRMPriceList(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetPriceList(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.priceListGenerated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPrices(context: MainContext, payload: { listID: number, groupID: number, criteria: IDataCriteria }) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMProductPrice(context.rootState.main.token, payload.listID, payload.groupID, payload.criteria);
            if (response) {
                commitSetProductPrices(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Product prices loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPricesDealer(context: MainContext, payload: { listID: number, groupID: number, criteria: IDataCriteria }) {
        try {
            const response = await api.getCRMProductPriceDealer(context.rootState.main.token, payload.listID, payload.groupID, payload.criteria);
            if (response) {
                commitSetProductPrices(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPricesDA(context: MainContext, payload: { listID: number, groupID: number, criteria: IDataCriteria }) {
        try {
            const response = await api.getCRMProductPriceDA(context.rootState.main.token, payload.listID, payload.groupID, payload.criteria);
            if (response) {
                commitSetProductPrices(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPricesCRMA(context: MainContext, payload: { listID: number, groupID: number, criteria: IDataCriteria }) {
        try {
            const response = await api.getCRMProductPriceCRMA(context.rootState.main.token, payload.listID, payload.groupID, payload.criteria);
            if (response) {
                commitSetProductPrices(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneProductPrice(context: MainContext, payload: ICRMProductPrice) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMOneProductPrice(context.rootState.main.token, payload);
            if (response) {
                commitSetProductPrice(context, response.data);
            }
            //commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPricesMerge(context: MainContext, payload: { listID: number, groupID: number, criteria: IDataCriteria }) {
        try {
            const response = await api.getCRMProductPrice(context.rootState.main.token, payload.listID, payload.groupID, payload.criteria);
            if (response) {
                commitSetProductPricesMerge(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionStartPriceListXLSXExport(context: MainContext, payload: ICRMPriceListExport) {
        try {
            const loadingNotification = { content: i18n.t('notification.exportingPriceList').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startPriceListXLSXExport(context.rootState.main.token, payload);
            if (response) {
                commitAddPriceListExportTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceListXLSXExportStatus(context: MainContext, payload: IExportXLSXTask) {
        try {
            const response = await api.getPriceListXLSXExportStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdatePriceListExportTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.XLSXNotExported').toString(), color: 'error' });
        }
    },
    async actionDownloadPriceListXLSX(context: MainContext, payload: IExportXLSXTask) {
        try {
            const response = (await Promise.all([
                api.downloadPriceListXLSX(context.rootState.main.token, payload.task_id),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            const now = new Date();

            fileLink.href = fileURL;
            if (payload.name != null) {
                fileLink.setAttribute('download', 'price-list-' + payload.name + '_' + formatDateCompact(now) + '.xlsx');
            } else {
                fileLink.setAttribute('download', 'price-list-' + formatDateCompact(now) + '.xlsx');
            }
            document.body.appendChild(fileLink);

            fileLink.click();
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
        commitRemovePriceListExportTask(context, payload);
    },
    async actionStartPriceListXLSXImport(context: MainContext, payload: { listID: number, formData: FormData }) {
        try {
            const loadingNotification = { content: i18n.t('notification.importingPriceList').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startPriceListXLSXImport(context.rootState.main.token, payload);
            if (response) {
                commitAddPriceListImportTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceListXLSXImportStatus(context: MainContext, payload: IImportXLSXTask) {
        try {
            const response = await api.getPriceListXLSXImportStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdatePriceListImportTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.XLSXNotImported').toString(), color: 'error' });
        }
    },
    async actionStartPriceListPopulation(context: MainContext, payload: ICRMPriceListPopulation) {
        try {
            const loadingNotification = { content: i18n.t('notification.populatingPriceList').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startPriceListPopulation(context.rootState.main.token, payload);
            if (response) {
                commitAddPriceListPopulationTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceListPopulationStatus(context: MainContext, payload: IImportXLSXTask) {
        try {
            const response = await api.getPriceListPopulationStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdatePriceListPopulationTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.priceListNotPopulated').toString(), color: 'error' });
        }
    },
    async actionStartPriceListSync(context: MainContext, payload: ICRMPriceListSync) {
        try {
            const loadingNotification = { content: i18n.t('notification.synchronizingPriceList').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startPriceListSync(context.rootState.main.token, payload);
            if (response) {
                commitAddPriceListSyncTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceListSyncStatus(context: MainContext, payload: ISyncTask) {
        try {
            const response = await api.getPriceListSyncStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdatePriceListSyncTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.priceListNotSynchronized').toString(), color: 'error' });
        }
    },
    async actionGetPriceListSyncResult(context: MainContext, payload: ISyncTask) {
        try {
            const response = await api.getPriceListSyncResult(context.rootState.main.token, payload.task_id);
            if (response) {
                commitSetPriceListSyncTaskResult(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionStartPriceListPropagation(context: MainContext, payload: { list_id: number }) {
        try {
            const loadingNotification = { content: i18n.t('notification.propagatingPriceList').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startPriceListPropagation(context.rootState.main.token, payload);
            if (response) {
                commitAddPriceListPropagationTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.priceListNotPropagated').toString(), color: 'error' });
        }
    },
    async actionGetPriceListPropagationStatus(context: MainContext, payload: IPropagationTask) {
        try {
            const response = await api.getPriceListPropagationStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdatePriceListPropagationTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionStartAllPriceListRecalculation(context: MainContext) {
        try {
            const loadingNotification = { content: i18n.t('notification.recalculatingAllPriceLists').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startAllPriceListRecalculation(context.rootState.main.token);
            if (response) {
                commitAddPriceListRecalculationTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.priceListsNotRecalculated').toString(), color: 'error' });
        }
    },
    async actionStartPriceListRecalculation(context: MainContext, payload: { list_id: number }) {
        try {
            const loadingNotification = { content: i18n.t('notification.recalculatingPriceList').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startPriceListRecalculation(context.rootState.main.token, payload);
            if (response) {
                commitAddPriceListRecalculationTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.priceListNotRecalculated').toString(), color: 'error' });
        }
    },
    async actionGetPriceListRecalculationStatus(context: MainContext, payload: IRecalculationTask) {
        try {
            const response = await api.getPriceListRecalculationStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdatePriceListRecalculationTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetPriceListRecalculationResult(context: MainContext, payload: IRecalculationTask) {
        try {
            const response = await api.getPriceListRecalculationResult(context.rootState.main.token, payload.task_id);
            if (response) {
                commitSetPriceListRecalculationTaskReport(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOverheads(context: MainContext) {
        try {
            const response = await api.getCRMOverheads(context.rootState.main.token);
            if (response) {
                commitSetOverheads(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateOverheads(context: MainContext, payload: ICRMOverheadsUpdate) {
        try {
            const updatingNotification = { content: i18n.t('notification.updating').toString(), showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateCRMOverheads(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: i18n.t('notification.overheadsUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.overheadsNotUpdated').toString(), color: 'error' });
        }
    },
    async actionStartProductsXLSXExport(context: MainContext, payload: ICRMProductsExport) {
        try {
            const loadingNotification = { content: i18n.t('notification.exportingProducts').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startProductsXLSXExport(context.rootState.main.token, payload);
            if (response) {
                commitAddProductsExportTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductsXLSXExportStatus(context: MainContext, payload: IExportXLSXTask) {
        try {
            const response = await api.getProductsXLSXExportStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdateProductsExportTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetExportStatus').toString(), color: 'error' });
        }
    },
    async actionDownloadProductsXLSX(context: MainContext, payload: IExportXLSXTask) {
        try {
            const response = (await Promise.all([
                api.downloadProductsXLSX(context.rootState.main.token, payload.task_id),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            const now = new Date();

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'products-' + formatDateCompact(now) + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
        commitRemoveProductsExportTask(context, payload);
    },
    async actionStartProductsXLSXImport(context: MainContext, payload: { formData: FormData }) {
        try {
            const loadingNotification = { content: i18n.t('notification.importingProducts').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startProductXLSXImport(context.rootState.main.token, payload);
            if (response) {
                commitAddProductsImportTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductsXLSXImportStatus(context: MainContext, payload: IImportXLSXTask) {
        try {
            const response = await api.getProductXLSXImportStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdateProductsImportTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetImportStatus').toString(), color: 'error' });
        }
    },
    async actionStartProductsXLSXImportCheck(context: MainContext, payload: { formData: FormData }) {
        try {
            const loadingNotification = { content: i18n.t('notification.checkingProducts').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.startProductXLSXImportCheck(context.rootState.main.token, payload);
            if (response) {
                commitAddProductsImportCheckTask(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductsXLSXImportCheckStatus(context: MainContext, payload: IImportXLSXTask) {
        try {
            const response = await api.getProductXLSXImportStatus(context.rootState.main.token, payload.task_id);
            if (response) {
                commitUpdateProductsImportCheckTask(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetCheckStatus').toString(), color: 'error' });
        }
    },
    async actionGetProductsXLSXImportCheckResult(context: MainContext, payload: IImportXLSXTask) {
        try {
            const response = await api.getProductXLSXImportResult(context.rootState.main.token, payload.task_id);
            if (response) {
                commitSetProductsImportCheckTaskResult(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductGroupRabats(context: MainContext, payload: { companyID: number }) {
        try {
            const response = await api.getCRMGroupRabats(context.rootState.main.token, payload.companyID);
            if (response) {
                commitSetCompanyGroupRabats(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateProductGroupRabat(context: MainContext, payload: ICRMProductGroupRabat) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.createCRMGroupRabat(context.rootState.main.token, payload);
            if (response) {
                commitSetOneCompanyGroupRabat(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.rabatGroupNotCreated').toString(), color: 'error' });
        }
    },
    async actionUpdateProductGroupRabat(context: MainContext, payload: ICRMProductGroupRabat) {
        try {
            const savingNotification = { content: i18n.t('notification.updating').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.updateCRMGroupRabat(context.rootState.main.token, payload);
            if (response) {
                commitSetOneCompanyGroupRabat(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.rabatGroupNotUpdated').toString(), color: 'error' });
        }
    },
    async actionDeleteProductGroupRabat(context: MainContext, payload: ICRMProductGroupRabat) {
        try {
            const savingNotification = { content: i18n.t('notification.removing').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.deleteCRMGroupRabat(context.rootState.main.token, payload);
            if (response) {
                //commitSetOneCompanyGroupRabat(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
            const response2 = await api.getCRMGroupRabats(context.rootState.main.token, payload.company_id);
            if (response2) {
                commitSetCompanyGroupRabats(context, response2.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.rabatGroupNotRemoved').toString(), color: 'error' });
        }
    },

    async actionGetNumberSeries(context: MainContext) {
        try {
            const response = await api.getCRMNumberSeries(context.rootState.main.token);
            if (response) {
                commitSetNumberSeries(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUseNumberSeries(context: MainContext) {
        try {
            const response = await api.useCRMNumberSeries(context.rootState.main.token);
            if (response) {
                commitUseNumberSeries(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateNumberSeries(context: MainContext, payload: ICRMNumberSeriesCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.createCRMNumberSeries(context.rootState.main.token, payload);
            if (response) {
                commitSetOneNumberSeries(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.numberSeriesNotCreated').toString(), color: 'error' });
        }
    },
    async actionGetOfferState(context: MainContext) {
        try {
            const response = await api.getCRMOfferState(context.rootState.main.token);
            if (response) {
                commitSetOfferState(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferValidity(context: MainContext) {
        try {
            const response = await api.getCRMOfferValidity(context.rootState.main.token);
            if (response) {
                commitSetOfferValidity(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOffers(context: MainContext) {
        try {
            const response = await api.getCRMOffers(context.rootState.main.token);
            if (response) {
                commitSetOffers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneOffer(context: MainContext, payload: { offerID: number }) {
        try {
            const response = await api.getCRMOneOffer(context.rootState.main.token, payload.offerID);
            if (response) {
                commitSetOneOffer(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneOfferCalculated(context: MainContext, payload: { offerID: number }) {
        try {
            const response = await api.getCRMOneOfferCalculated(context.rootState.main.token, payload.offerID);
            if (response) {
                commitSetOneOffer(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOffersByYear(context: MainContext, payload: { year: number }) {
        try {
            const response = await api.getCRMOffersByYear(context.rootState.main.token, payload.year);
            if (response) {
                commitSetOffers(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateOffer(context: MainContext, payload: ICRMOfferCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.createCRMOffer(context.rootState.main.token, payload);
            if (response) {
                commitSetOneOffer(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerNotCreated').toString(), color: 'error' });
        }
    },
    async actionUpdateOffer(context: MainContext, payload: { id: number, offer: ICRMOfferUpdate, notification: number, recalculate: boolean }) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            if (payload.notification >= 1) {
                commitAddNotification(context, loadingNotification);
            }
            if (payload.recalculate) {
                const response = await api.updateCRMOfferRecalculate(context.rootState.main.token, payload.id, payload.offer);
                if (response) {
                    commitSetOneOffer(context, response.data);
                }
            } else {
                const response = await api.updateCRMOffer(context.rootState.main.token, payload.id, payload.offer);
                if (response) {
                    commitSetOneOffer(context, response.data);
                }
            }
            if (payload.notification >= 1) {
                commitRemoveNotification(context, loadingNotification);
            }
            if (payload.notification >= 2) {
                commitAddNotification(context, { content: i18n.t('notification.offerUpdated').toString(), color: 'success' });
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionSendOffer(context: MainContext, payload: { offer_id: number, data: ICRMOfferSend }) {
        try {
            const loadingNotification = { content: i18n.t('notification.sending').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.sendCRMOffer(context.rootState.main.token, payload.offer_id,
                payload.data);
            if (response) {
                commitSetOneOffer(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.offerSent').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferYears(context: MainContext) {
        try {
            const response = await api.getCRMOffersYearSeries(context.rootState.main.token);
            if (response) {
                commitSetOfferYears(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferProductGroups(context: MainContext, payload: { listID: number}) {
        try {
            const response = await api.getCRMOfferProductGroups(context.rootState.main.token, payload.listID);
            if (response) {
                commitSetOfferProductGroups(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateOfferItem(context: MainContext, payload: ICRMOfferItemCreate) {
        const savingNotification = { content: i18n.t('notification.creating').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.createCRMOfferItem(context.rootState.main.token, payload, true);
            if (response) {
                commitSetOneOfferItem(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerNotCreated').toString(), color: 'error' });
        }
    },
    async actionCreateOfferItemGrouped(context: MainContext, payload: ICRMOfferItemCreate) {
        const savingNotification = { content: i18n.t('notification.creating').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.createCRMOfferItem(context.rootState.main.token, payload, true);
            if (response) {
                commitSetOneOfferItemGrouped(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotCreated').toString(), color: 'error' });
        }
    },
    async actionCopyOfferItemGrouped(context: MainContext, payload: { itemID: number, count: number }) {
        const savingNotification = { content: i18n.t('notification.copying').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.copyCRMOfferItem(context.rootState.main.token, payload.itemID, payload.count);
            if (response) {
                commitAddOfferItemsToGroup(context, response.data);
                commitAddOfferItemsUnrouped(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotCopied').toString(), color: 'error' });
        }
    },
    async actionCopyOfferItemComposite(context: MainContext, payload: { itemID: number, count: number }) {
        const savingNotification = { content: i18n.t('notification.copying').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.copyCRMOfferItemComposite(
                context.rootState.main.token
                , payload.itemID
                , payload.count
            );
            if (response) {
                commitAddOfferItemsToGroup(context, response.data);
                commitAddOfferItemsUnrouped(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotCopied').toString(), color: 'error' });
        }
    },
    async actionUpdateOfferItem(context: MainContext, payload: { offerItemID: number, data: ICRMOfferItemUpdate }) {
        const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.updateCRMOfferItem(context.rootState.main.token, payload.offerItemID, payload.data);
            if (response) {
                commitSetOneOfferItem(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotUpdated').toString(), color: 'error' });
        }
    },
    async actionUpdateOfferItemGrouped(context: MainContext, payload: { offerItemID: number, data: ICRMOfferItemUpdate }) {
        const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.updateCRMOfferItemGrouped(context.rootState.main.token, payload.offerItemID, payload.data);
            if (response) {
                commitSetOneOfferItemGrouped(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotUpdated').toString(), color: 'error' });
        }
    },
    async actionDeleteOfferItem(context: MainContext, payload: { item: ICRMOfferItem, discount: boolean }) {
        const savingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.deleteCRMOfferItem(context.rootState.main.token, payload.item, payload.discount);
            if (response) {
                commitRemoveOfferItem(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotDeleted').toString(), color: 'error' });
        }
    },
    async actionDeleteOfferItemGrouped(context: MainContext, payload: ICRMOfferItem) {
        const savingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.deleteCRMOfferItem(context.rootState.main.token, payload, true);
            if (response) {
                commitRemoveOfferItemGrouped(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotDeleted').toString(), color: 'error' });
        }
    },
    async actionRecalculateOfferItem(context: MainContext, payload: { item_id: number, calculation_6: number } ) {
        try {
            const response = await api.getCRMOfferItemRecalculation(context.rootState.main.token, payload);
            if (response) {
                commitSetRecalculatedOfferItem(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemNotRecalculated').toString(), color: 'error' });
        }
    },
    async actionEstimateOfferItem(context: MainContext, payload: { offer_id: number, calculation_6: number } ) {
        try {
            const response = await api.getCRMOfferItemEstimation(context.rootState.main.token, payload);
            if (response) {
                commitSetRecalculatedOfferItem(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerItemPricesNotEstimated').toString(), color: 'error' });
        }
    },
    async actionCreateOfferDiscount(context: MainContext, payload: ICRMOfferDiscountCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.createCRMOfferDiscount(context.rootState.main.token, payload);
            if (response) {
                commitSetOneOfferDiscount(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerDiscountNotCreated').toString(), color: 'error' });
        }
    },
    async actionUpdateOfferDiscount(context: MainContext, payload: { offerDiscountID: number, data: ICRMOfferDiscountUpdate }) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.updateCRMOfferDiscount(context.rootState.main.token, payload.offerDiscountID, payload.data);
            if (response) {
                commitSetOneOfferDiscount(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerDiscountNotUpdated').toString(), color: 'error' });
        }
    },
    async actionUpdateOfferDiscountCalculated(context: MainContext, payload: { offerDiscountID: number, data: ICRMOfferDiscountUpdate }) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = await api.updateCRMOfferDiscountCalculated(context.rootState.main.token, payload.offerDiscountID, payload.data);
            if (response) {
                commitSetOneOfferDiscount(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerDiscountNotUpdated').toString(), color: 'error' });
        }
    },
    async actionDeleteOfferDiscount(context: MainContext, payload: ICRMOfferDiscount) {
        const savingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.deleteCRMOfferDiscount(context.rootState.main.token, payload);
            if (response) {
                commitRemoveOfferDiscount(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerDiscountNotDeleted').toString(), color: 'error' });
        }
    },
    async actionDeleteOfferDiscountItems(context: MainContext, payload: ICRMOfferDiscount) {
        const savingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, savingNotification);
            const response = await api.deleteCRMOfferDiscountItems(context.rootState.main.token, payload);
            if (response) {
                commitRemoveOfferDiscount(context, response.data);
            }
            commitRemoveNotification(context, savingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, savingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerGroupNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetProductSizes(context: MainContext, payload: { groupID: number}) {
        try {
            const response = await api.getCRMProductSizes(context.rootState.main.token, payload.groupID);
            if (response) {
                commitSetProductSizes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductTypes(context: MainContext, payload: { groupID: number, size: string}) {
        try {
            const response = await api.getCRMProductTypes(context.rootState.main.token, payload.groupID, payload.size);
            if (response) {
                commitSetProductTypes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetSizeTypeProducts(context: MainContext, payload: { groupID: number, size: string, type: string}) {
        try {
            const response = await api.getCRMSizeTypeProducts(context.rootState.main.token, payload.groupID, payload.size, payload.type);
            if (response) {
                commitSetSizeTypeProducts(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceSizes(context: MainContext, payload: { listID: number, groupID: number}) {
        try {
            const response = await api.getCRMProductPriceSizes(
                context.rootState.main.token
                , payload.listID
                , payload.groupID);
            if (response) {
                commitSetProductPriceSizes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceTypes(
        context: MainContext
        , payload: {
            listID: number
            , groupID: number
            , size: string
            , composite: boolean
        }
    ) {
        try {
            const response = await api.getCRMProductPriceTypes(
                context.rootState.main.token
                , payload.listID
                , payload.groupID
                , payload.size
                , payload.composite);
            if (response) {
                commitSetProductPriceTypes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceSizeTypes(context: MainContext, payload: { listID: number, groupID: number, size: string, type: string }) {
        try {
            const response = await api.getCRMProductPriceSizeTypes(
                context.rootState.main.token
                , payload.listID
                , payload.groupID
                , payload.size
                , payload.type);
            if (response) {
                commitSetProductPriceSizeTypes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceSizeTypesDA(context: MainContext, payload: { listID: number, groupID: number, size: string, type: string }) {
        try {
            const response = await api.getCRMProductPriceSizeTypesDA(
                context.rootState.main.token
                , payload.listID
                , payload.groupID
                , payload.size
                , payload.type);
            if (response) {
                commitSetProductPriceSizeTypes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceDiscountGroups(context: MainContext, payload: { listID: number }) {
        try {
            const response = await api.getCRMProductPriceDiscountGroup(context.rootState.main.token, payload.listID);
            if (response) {
                commitSetProductPriceDiscountGroups(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductPriceDiscountGroups(context: MainContext, payload: { listID: number , data: ICRMProductPriceDiscountGroupUpdate }) {
        try {
            const response = await api.updateCRMProductPriceDiscountGroup(context.rootState.main.token, payload.listID, payload.data);
            if (response) {
                commitSetOneProductPriceDiscountGroup(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferMessageMulti(context: MainContext) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMOfferMessageMulti(context.rootState.main.token);
            if (response) {
                commitSetOfferMessageMulti(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'OfferMessages loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetOfferMessages').toString(), color: 'error' });
        }
    },
    async actionGetOfferMessageOne(context: MainContext, payload: { offermessageID: number }) {
        try {
            const response = await api.getCRMOfferMessage(context.rootState.main.token, payload);
            if (response) {
                commitSetOfferMessageOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateOfferMessage(context: MainContext, payload: ICRMOfferMessageCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMOfferMessage(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOfferMessageOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.offerMessageCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerMessageNotCreated').toString(), color: 'error' });
        }
    },
    async actionUpdateOfferMessage(context: MainContext, payload: { messageID: number, data: ICRMOfferMessageUpdate }) {
        try {
            const updatingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateCRMOfferMessage(context.rootState.main.token, payload.messageID, payload.data),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOfferMessageOne(context, response.data);
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: i18n.t('notification.offerMessageSaved').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerMessageNotSaved').toString(), color: 'error' });
        }
    },
    async actionDeleteOfferMessage(context: MainContext, payload: ICRMOfferMessage ) {
        const removingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteCRMOfferMessage(context.rootState.main.token, payload);
            if (response) {
                commitRemoveOfferMessage(context, response.data);
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.offerMessageNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetPriceListDiscountMax(context: MainContext, payload: { listID: number, groupID: number }) {
        try {
            const response = await api.getCRMPriceListDiscountMax(context.rootState.main.token, payload.listID, payload.groupID);
            if (response) {
                commitSetPriceListDiscountMax(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductsCount(context: MainContext, payload: { groupID: number, search: string }) {
        try {
            const response = await api.getCRMProductsCount(
                context.rootState.main.token
                , payload.groupID
                , payload.search
            );
            if (response.data.size != null) {
                commitSetProductsCount(context, response.data.size);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPricesCount(context: MainContext, payload: { listID: number, groupID: number, search: string }) {
        try {
            const response = await api.getCRMProductPricesCount(
                context.rootState.main.token
                , payload.listID
                , payload.groupID
                , payload.search
            );
            if (response.data.size != null) {
                commitSetProductPricesCount(context, response.data.size);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductComposite(context: MainContext, payload: { compositeID: number }) {
        try {
            const response = await api.getCRMProductComposite(
                context.rootState.main.token
                , payload.compositeID
            );
            if (response) {
                commitSetProductComposite(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductComposite(context: MainContext, payload: { compositeID: number, composite: ICRMProductCompositeUpdate }) {
        try {
            const response = await api.updateCRMProductComposite(
                context.rootState.main.token
                , payload.compositeID
                , payload.composite
            );
            if (response) {
                commitSetProductComposite(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOfferGroupDefaults(context: MainContext, payload: { groupID: number }) {
        try {
            const response = await api.getCRMOfferGroupDefaults(
                context.rootState.main.token
                , payload.groupID
            );
            commitSetOfferGroupDefaults(context, null);
            if (response) {
                commitSetOfferGroupDefaults(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateOfferGroupDefaults(context: MainContext, payload: IRMOfferGroupDefaultsCreate) {
        try {
            const response = await api.createCRMOfferGroupDefaults(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitSetOfferGroupDefaults(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateOfferGroupDefaults(context: MainContext, payload: { groupID: number, data: IRMOfferGroupDefaultsUpdate }) {
        try {
            const response = await api.updateCRMOfferGroupDefaults(
                context.rootState.main.token
                , payload.groupID
                , payload.data
            );
            if (response) {
                commitSetOfferGroupDefaults(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductPriceComposite(
        context: MainContext
        , payload: { compositeID: number, listID: number }
    ) {
        try {
            const response = await api.updateCRMProductPriceComposite(
                context.rootState.main.token
                , payload.compositeID
                , payload.listID
            );
            if (response) {
                commitSetProductPriceComposite(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceComposite(
        context: MainContext
        , payload: { compositeID: number, listID: number }
    ) {
        try {
            const response = await api.getCRMProductPriceByProduct(
                context.rootState.main.token
                , payload.compositeID
                , payload.listID
            );
            if (response) {
                commitSetProductPriceComposite(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductPriceCompositeDA(
        context: MainContext
        , payload: { compositeID: number, listID: number }
    ) {
        try {
            const response = await api.getCRMProductPriceByProductDA(
                context.rootState.main.token
                , payload.compositeID
                , payload.listID
            );
            if (response) {
                commitSetProductPriceComposite(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductPrice(
        context: MainContext
        , payload: { priceID: number, productPrice: ICRMProductPriceUpdate }
    ) {
        try {
            const response = await api.updateCRMProductPrice(
                context.rootState.main.token
                , payload.priceID
                , payload.productPrice
            );
            if (response) {
                commitSetProductPrice(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetProductAttributes(
        context: MainContext
        , payload: { data: ICRMProductGroup, criteria: IDataCriteria }
    ) {
        try {
            const response = await api.getCRMProductsByGroupAttributes(
                context.rootState.main.token
                , payload.data
                , payload.criteria
            );
            if (response) {
                commitSetProductAttributes(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetCRMProjectMulti(context: MainContext) {
        try {
            const response = await api.getCRMProjectMulti(context.rootState.main.token);
            if (response) {
                commitSetCRMProjectMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to get CRMProjects!', color: 'error' });
        }
    },
    async actionGetCRMProjectOne(context: MainContext, payload: { projectID: number }) {
        try {
            const response = await api.getCRMProject(context.rootState.main.token, payload.projectID);
            if (response) {
                commitSetCRMProjectOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCRMProject(context: MainContext, payload: ICRMProjectCreate) {
        try {
            const savingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMProject(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetCRMProjectOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: 'CRMProject created', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to create Project!', color: 'error' });
        }
    },
    async actionUpdateCRMProject(context: MainContext, payload: ICRMProjectUpdate) {
        try {
            const updatingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateCRMProject(context.rootState.main.token, payload.id, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetCRMProjectOne(context, response.data);
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: 'CRMProject successfully saved', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to save Project!', color: 'error' });
        }
    },
    async actionDeleteCRMProject(context: MainContext, payload: ICRMProject ) {
        const removingNotification = { content: 'deleting ...', showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteCRMProject(context.rootState.main.token, payload);
            if (response) {
                commitRemoveCRMProject(context, response.data);
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to delete Project', color: 'error' });
        }
    },
    async actionGetOfferItemGAEBMulti(
        context: MainContext
        , payload: { offerID: number }
    ) {
        try {
            // const loadingNotification = { content: 'loading', showProgress: true };
            // commitAddNotification(context, loadingNotification);
            const response = await api.getCRMOfferItemGAEBMulti(
                context.rootState.main.token
                , payload.offerID
            );
            if (response) {
                commitSetOfferItemGAEBMulti(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'OfferItemGAEBs loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to get OfferItemGAEBs!', color: 'error' });
        }
    },
    async actionGetOfferItemGAEBOne(context: MainContext, payload: { offerItemGAEB_ID: number }) {
        try {
            const response = await api.getCRMOfferItemGAEB(
                context.rootState.main.token
                , payload.offerItemGAEB_ID
            );
            if (response) {
                commitSetOfferItemGAEBOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateOfferItemGAEB(context: MainContext, payload: ICRMOfferItemGAEBCreate) {
        try {
            const savingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCRMOfferItemGAEB(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOfferItemGAEBOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: 'OfferItemGAEB created', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to create OfferItemGAEB!', color: 'error' });
        }
    },
    async actionUpdateOfferItemGAEB(context: MainContext, payload: { id: number, data: ICRMOfferItemGAEBUpdate }) {
        try {
            const updatingNotification = { content: 'saving', showProgress: true };
            // commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateCRMOfferItemGAEB(
                    context.rootState.main.token
                    , payload.id
                    , payload.data
                ),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOfferItemGAEBOne(context, response.data);
            // commitRemoveNotification(context, updatingNotification);
            // commitAddNotification(context, { content: 'OfferItemGAEB successfully saved', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to save OfferItemGAEB!', color: 'error' });
        }
    },
    async actionDeleteOfferItemGAEB(context: MainContext, payload: { offerItemGAEB_ID: number } ) {
        const removingNotification = { content: 'deleting ...', showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteCRMOfferItemGAEB(
                context.rootState.main.token
                , payload
            );
            if (response) {
                commitRemoveOfferItemGAEB(context, response.data);
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Unable to delete OfferItemGAEB', color: 'error' });
        }
    },
};

const { dispatch } = getStoreAccessors<CRMState, State>('');

export const dispatchGetCustomers = dispatch(actions.actionGetCustomers);

export const dispatchGetCRMCompanies = dispatch(actions.actionGetCRMCompanies);
export const dispatchGetBranches = dispatch(actions.actionGetBranches);
export const dispatchGetContactPersons = dispatch(actions.actionGetContactPersons);
export const dispatchGetContactPersonsFull = dispatch(actions.actionGetContactPersonsFull);
export const dispatchGetOneContactPersonFull = dispatch(actions.actionGetOneContactPersonFull);
export const dispatchGetOneCompany = dispatch(actions.actionGetOneCompany);
export const dispatchGetCompanyARES = dispatch(actions.actionGetCompanyARES);
export const dispatchCreateCompany = dispatch(actions.actionCreateCompany);
export const dispatchCreateCompanyBranch = dispatch(actions.actionCreateCompanyBranch);
export const dispatchCreateBranch = dispatch(actions.actionCreateBranch);
export const dispatchCreateContactPerson = dispatch(actions.actionCreateContactPerson);
export const dispatchUpdateCompany = dispatch(actions.actionUpdateCompany);
export const dispatchUpdateBranch = dispatch(actions.actionUpdateBranch);
export const dispatchUpdateContactPerson = dispatch(actions.actionUpdateContactPerson);

export const dispatchGetProductCategories = dispatch(actions.actionGetProductCategories);
export const dispatchUpdateProductCategory = dispatch(actions.actionUpdateProductCategory);
export const dispatchSwapProductCategoriesIndex = dispatch(actions.actionSwapProductCategoriesIndex);
export const dispatchGetProductSubcategories = dispatch(actions.actionGetProductSubcategories);
export const dispatchUpdateProductSubcategory = dispatch(actions.actionUpdateProductSubcategory);
export const dispatchGetProductGroups = dispatch(actions.actionGetProductGroups);
export const dispatchGetProductGroupInfo = dispatch(actions.actionGetProductGroupInfo);
export const dispatchCreateProductGroup = dispatch(actions.actionCreateProductGroup);
export const dispatchUpdateProductGroup = dispatch(actions.actionUpdateProductGroup);
export const dispatchSwapProductGroupsIndex = dispatch(actions.actionSwapProductGroupsIndex);
export const dispatchGetProductVariants = dispatch(actions.actionGetProductVariants);
export const dispatchGetProducts = dispatch(actions.actionGetProducts);
export const dispatchGetSelectedProduct = dispatch(actions.actionGetSelectedProduct);
export const dispatchCreateProduct = dispatch(actions.actionCreateProduct);
export const dispatchGetProductsByGroup = dispatch(actions.actionGetProductsByGroup);
export const dispatchGetCompositeProductsByGroup = dispatch(actions.actionGetCompositeProductsByGroup);
export const dispatchGetProductsByGroupFull = dispatch(actions.actionGetProductsByGroupFull);

export const dispatchGetOffersTable = dispatch(actions.actionGetOffersTable);
export const dispatchGetOfferItems = dispatch(actions.actionGetOfferItems);
export const dispatchGetOfferItemsGrouped = dispatch(actions.actionGetOfferItemsGrouped);
export const dispatchGetOneOfferItemsGroup = dispatch(actions.actionGetOneOfferItemsGroup);
export const dispatchGetOfferDiscounts = dispatch(actions.actionGetOfferDiscounts);
export const dispatchGetOfferDiscountsCalculated = dispatch(actions.actionGetOfferDiscountsCalculated);
export const dispatchGetPriceLists = dispatch(actions.actionGetPriceLists);
export const dispatchGetOnePriceList = dispatch(actions.actionGetOnePriceList);
export const dispatchCheckPriceListEmpty = dispatch(actions.actionCheckPriceListEmpty);
export const dispatchCreatePriceList = dispatch(actions.actionCreatePriceList);
export const dispatchGeneratePriceList = dispatch(actions.actionGeneratePriceList);
export const dispatchGetProductPrices = dispatch(actions.actionGetProductPrices);
export const dispatchGetProductPricesDealer = dispatch(actions.actionGetProductPricesDealer);
export const dispatchGetProductPricesDA = dispatch(actions.actionGetProductPricesDA);
export const dispatchGetProductPricesCRMA = dispatch(actions.actionGetProductPricesCRMA);
export const dispatchGetOneProductPrice = dispatch(actions.actionGetOneProductPrice);
export const dispatchGetProductPriceDiscountGroups = dispatch(actions.actionGetProductPriceDiscountGroups);
export const dispatchUpdateProductPriceDiscountGroups = dispatch(actions.actionUpdateProductPriceDiscountGroups);
export const dispatchGetProductPricesMerge = dispatch(actions.actionGetProductPricesMerge);
export const dispatchStartPriceListXLSXExport = dispatch(actions.actionStartPriceListXLSXExport);
export const dispatchGetPriceListXLSXExportStatus = dispatch(actions.actionGetPriceListXLSXExportStatus);
export const dispatchDownloadPriceListXLSX = dispatch(actions.actionDownloadPriceListXLSX);
export const dispatchStartPriceListXLSXImport = dispatch(actions.actionStartPriceListXLSXImport);
export const dispatchGetPriceListXLSXImportStatus = dispatch(actions.actionGetPriceListXLSXImportStatus);
export const dispatchStartPriceListPopulation = dispatch(actions.actionStartPriceListPopulation);
export const dispatchGetPriceListPopulationStatus = dispatch(actions.actionGetPriceListPopulationStatus);
export const dispatchStartPriceListSync = dispatch(actions.actionStartPriceListSync);
export const dispatchGetPriceListSyncStatus = dispatch(actions.actionGetPriceListSyncStatus);
export const dispatchStartPriceListPropagation = dispatch(actions.actionStartPriceListPropagation);
export const dispatchGetPriceListPropagationStatus = dispatch(actions.actionGetPriceListPropagationStatus);
export const dispatchStartPriceListRecalculation = dispatch(actions.actionStartPriceListRecalculation);
export const dispatchStartAllPriceListRecalculation = dispatch(actions.actionStartAllPriceListRecalculation);
export const dispatchGetPriceListRecalculationStatus = dispatch(actions.actionGetPriceListRecalculationStatus);
export const dispatchGetPriceListRecalculationResult = dispatch(actions.actionGetPriceListRecalculationResult);
export const dispatchGetOverheads = dispatch(actions.actionGetOverheads);
export const dispatchUpdateOverheads = dispatch(actions.actionUpdateOverheads);
export const dispatchStartProductsXLSXExport = dispatch(actions.actionStartProductsXLSXExport);
export const dispatchGetProductsXLSXExportStatus = dispatch(actions.actionGetProductsXLSXExportStatus);
export const dispatchDownloadProductsXLSX = dispatch(actions.actionDownloadProductsXLSX);
export const dispatchStartProductsXLSXImport = dispatch(actions.actionStartProductsXLSXImport);
export const dispatchGetProductsXLSXImportStatus = dispatch(actions.actionGetProductsXLSXImportStatus);
export const dispatchStartProductsXLSXImportCheck = dispatch(actions.actionStartProductsXLSXImportCheck);
export const dispatchGetProductsXLSXImportCheckStatus = dispatch(actions.actionGetProductsXLSXImportCheckStatus);
export const dispatchGetProductsXLSXImportCheckResult = dispatch(actions.actionGetProductsXLSXImportCheckResult);
export const dispatchGetPriceListSyncResult = dispatch(actions.actionGetPriceListSyncResult);

export const dispatchGetProductGroupRabats = dispatch(actions.actionGetProductGroupRabats);
export const dispatchCreateProductGroupRabat = dispatch(actions.actionCreateProductGroupRabat);
export const dispatchUpdateProductGroupRabat = dispatch(actions.actionUpdateProductGroupRabat);
export const dispatchDeleteProductGroupRabat = dispatch(actions.actionDeleteProductGroupRabat);

export const dispatchGetNumberSeries = dispatch(actions.actionGetNumberSeries);
export const dispatchUseNumberSeries = dispatch(actions.actionUseNumberSeries);
export const dispatchCreateNumberSeries = dispatch(actions.actionCreateNumberSeries);
export const dispatchGetOfferState = dispatch(actions.actionGetOfferState);
export const dispatchGetOfferValidity = dispatch(actions.actionGetOfferValidity);
export const dispatchGetOffers = dispatch(actions.actionGetOffers);
export const dispatchGetOneOffer = dispatch(actions.actionGetOneOffer);
export const dispatchGetOneOfferCalculated = dispatch(actions.actionGetOneOfferCalculated);
export const dispatchGetOffersByYear = dispatch(actions.actionGetOffersByYear);
export const dispatchCreateOffer = dispatch(actions.actionCreateOffer);
export const dispatchUpdateOffer = dispatch(actions.actionUpdateOffer);
export const dispatchSendOffer = dispatch(actions.actionSendOffer);
export const dispatchGetOfferYears = dispatch(actions.actionGetOfferYears);
export const dispatchGetOfferProductGroups = dispatch(actions.actionGetOfferProductGroups);
export const dispatchCreateOfferItem = dispatch(actions.actionCreateOfferItem);
export const dispatchCreateOfferItemGrouped = dispatch(actions.actionCreateOfferItemGrouped);
export const dispatchCopyOfferItemGrouped = dispatch(actions.actionCopyOfferItemGrouped);
export const dispatchCopyOfferItemComposite = dispatch(actions.actionCopyOfferItemComposite);
export const dispatchUpdateOfferItem = dispatch(actions.actionUpdateOfferItem);
export const dispatchUpdateOfferItemGrouped = dispatch(actions.actionUpdateOfferItemGrouped);
export const dispatchCreateOfferDiscount = dispatch(actions.actionCreateOfferDiscount);
export const dispatchUpdateOfferDiscount = dispatch(actions.actionUpdateOfferDiscount);
export const dispatchUpdateOfferDiscountCalculated = dispatch(actions.actionUpdateOfferDiscountCalculated);
export const dispatchGetProductSizes = dispatch(actions.actionGetProductSizes);
export const dispatchGetProductTypes = dispatch(actions.actionGetProductTypes);
export const dispatchGetSizeTypeProducts = dispatch(actions.actionGetSizeTypeProducts);
export const dispatchGetProductPriceSizeTypes = dispatch(actions.actionGetProductPriceSizeTypes);
export const dispatchGetProductPriceSizeTypesDA = dispatch(actions.actionGetProductPriceSizeTypesDA);
export const dispatchGetProductPriceSizes = dispatch(actions.actionGetProductPriceSizes);
export const dispatchGetProductPriceTypes = dispatch(actions.actionGetProductPriceTypes);
export const dispatchDeleteOfferItem = dispatch(actions.actionDeleteOfferItem);
export const dispatchDeleteOfferItemGrouped = dispatch(actions.actionDeleteOfferItemGrouped);
export const dispatchDeleteOfferDiscount = dispatch(actions.actionDeleteOfferDiscount);
export const dispatchDeleteOfferDiscountItems = dispatch(actions.actionDeleteOfferDiscountItems);
export const dispatchRecalculateOfferItem = dispatch(actions.actionRecalculateOfferItem);
export const dispatchEstimateOfferItem = dispatch(actions.actionEstimateOfferItem);
export const dispatchGetOfferMessageMulti = dispatch(actions.actionGetOfferMessageMulti);
export const dispatchGetOfferMessageOne = dispatch(actions.actionGetOfferMessageOne);
export const dispatchCreateOfferMessage = dispatch(actions.actionCreateOfferMessage);
export const dispatchUpdateOfferMessage = dispatch(actions.actionUpdateOfferMessage);
export const dispatchDeleteOfferMessage = dispatch(actions.actionDeleteOfferMessage);
export const dispatchGetPriceListGroups = dispatch(actions.actionGetPriceListGroups);
export const dispatchGetPriceListDiscountMax = dispatch(actions.actionGetPriceListDiscountMax);

export const dispatchGetProductsCount = dispatch(actions.actionGetProductsCount);
export const dispatchGetProductPricesCount = dispatch(actions.actionGetProductPricesCount);

export const dispatchGetProductComposite = dispatch(actions.actionGetProductComposite);
export const dispatchUpdateProductComposite = dispatch(actions.actionUpdateProductComposite);

export const dispatchGetOfferGroupDefaults = dispatch(actions.actionGetOfferGroupDefaults);
export const dispatchCreateOfferGroupDefaults = dispatch(actions.actionCreateOfferGroupDefaults);
export const dispatchUpdateOfferGroupDefaults = dispatch(actions.actionUpdateOfferGroupDefaults);
export const dispatchUpdateProductPriceComposite = dispatch(actions.actionUpdateProductPriceComposite);
export const dispatchGetProductPriceComposite = dispatch(actions.actionGetProductPriceComposite);
export const dispatchGetProductPriceCompositeDA = dispatch(actions.actionGetProductPriceCompositeDA);
export const dispatchUpdateProductPrice = dispatch(actions.actionUpdateProductPrice);

export const dispatchGetProductAttributes = dispatch(actions.actionGetProductAttributes);

export const dispatchGetCRMProjectMulti = dispatch(actions.actionGetCRMProjectMulti);
export const dispatchGetCRMProjectOne = dispatch(actions.actionGetCRMProjectOne);
export const dispatchCreateCRMProject = dispatch(actions.actionCreateCRMProject);
export const dispatchUpdateCRMProject = dispatch(actions.actionUpdateCRMProject);
export const dispatchDeleteCRMProject = dispatch(actions.actionDeleteCRMProject);
export const dispatchGetOfferItemGAEBMulti = dispatch(actions.actionGetOfferItemGAEBMulti);
export const dispatchGetOfferItemGAEBOne = dispatch(actions.actionGetOfferItemGAEBOne);
export const dispatchCreateOfferItemGAEB = dispatch(actions.actionCreateOfferItemGAEB);
export const dispatchUpdateOfferItemGAEB = dispatch(actions.actionUpdateOfferItemGAEB);
export const dispatchDeleteOfferItemGAEB = dispatch(actions.actionDeleteOfferItemGAEB);
