import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { LTUState } from './state';


const defaultState: LTUState = {
    ltuPriceList: [],
};

export const ltuModule = {
    state: defaultState,
    mutations,
    actions,
    getters,
};
