import { getStoreAccessors } from 'typesafe-vuex';
import { CommonState } from './state';
import { State } from '../state';


export const getters = {
    measurementUnits: (state: CommonState) => state.measurementUnits,
    ralMulti: (state: CommonState) => state.ral,
    ralOne: (state: CommonState) => (ralID: number) => {
        const filteredRAL = state.ral.filter((ral) => ral.id === ralID);
        if (filteredRAL.length > 0) {
            return { ...filteredRAL[0] };
        }
    },
    countries:  (state: CommonState) => state.countries,
};

const { read } = getStoreAccessors<CommonState, State>('');

export const readMeasurementUnits = read(getters.measurementUnits);
export const readRALMulti = read(getters.ralMulti);
export const readRALOne = read(getters.ralOne);
export const readCountries = read(getters.countries);
