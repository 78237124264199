import { api } from '@/api';
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { CommonState } from './state';
import {
    IRAL
    , IRALCreate
    , IRALUpdate
} from '@/interfaces/common';
import {
    commitSetMeasurementUnits
    , commitSetRALMulti
    , commitSetRALOne
    , commitRemoveRAL
    , commitSetCountries
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
    commitAddNotification
    , commitRemoveNotification
} from '../main/mutations';
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<CommonState, State>;

export const actions = {
    async actionGetMeasurementUnits(context: MainContext) {
        try {
            const response = await api.getMeasurementUnits(context.rootState.main.token);
            if (response) {
                commitSetMeasurementUnits(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetRALMulti(context: MainContext) {
        try {
            const response = await api.getCommonRALMulti(context.rootState.main.token);
            if (response) {
                commitSetRALMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetRALs').toString(), color: 'error' });
        }
    },
    async actionGetRALOne(context: MainContext, payload: { ralID: number }) {
        try {
            const response = await api.getCommonRAL(context.rootState.main.token, payload);
            if (response) {
                commitSetRALOne(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateRAL(context: MainContext, payload: IRALCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createCommonRAL(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetRALOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.RALCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.RALNotCreated').toString(), color: 'error' });
        }
    },
    async actionUpdateRAL(context: MainContext, payload: { ralID: number, ral: IRALUpdate }) {
        try {
            const updatingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateCommonRAL(context.rootState.main.token, payload.ralID, payload.ral),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetRALOne(context, response.data);
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: i18n.t('notification.RALSaved').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.RALNotSaved').toString(), color: 'error' });
        }
    },
    async actionDeleteRAL(context: MainContext, payload: IRAL ) {
        const removingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
        try {
            commitAddNotification(context, removingNotification);
            const response = await api.deleteCommonRAL(context.rootState.main.token, payload);
            if (response) {
                commitRemoveRAL(context, response.data);
            }
            commitRemoveNotification(context, removingNotification);
        } catch (error: any) {
            commitRemoveNotification(context, removingNotification);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.RALNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetCountries(context: MainContext) {
        try {
            const response = await api.getCommonCountries(context.rootState.main.token);
            if (response) {
                commitSetCountries(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.unableGetCountries').toString(), color: 'error' });
        }
    },
}

const { dispatch } = getStoreAccessors<CommonState, State>('');

export const dispatchGetMeasurementUnits = dispatch(actions.actionGetMeasurementUnits);
export const dispatchGetRALMulti = dispatch(actions.actionGetRALMulti);
export const dispatchGetRALOne = dispatch(actions.actionGetRALOne);
export const dispatchCreateRAL = dispatch(actions.actionCreateRAL);
export const dispatchUpdateRAL = dispatch(actions.actionUpdateRAL);
export const dispatchDeleteRAL = dispatch(actions.actionDeleteRAL);
export const dispatchGetCountries = dispatch(actions.actionGetCountries);
