import { api } from '@/api';
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { GalleryState } from './state';
import { commitSetImages } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
    commitAddNotification
    , commitRemoveNotification
} from '../main/mutations';
import { i18n } from "@/plugins/i18n";


type MainContext = ActionContext<GalleryState, State>;

export const actions = {
    async actionGetImages(context: MainContext) {
        try {
            const loadingNotification = { content: 'loading', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getGalleryAll(context.rootState.main.token);
            if (response) {
                commitSetImages(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.imagesLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
}

const { dispatch } = getStoreAccessors<GalleryState, State>('');

export const dispatchGetImages = dispatch(actions.actionGetImages);
