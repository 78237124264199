import { SCADAState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    heliosDevices: (state: SCADAState) => state.heliosDevices,
    recordsHelios: (state: SCADAState) => state.recordsHelios,
    currentHeliosRecord: (state: SCADAState) => state.currentHeliosRecord,
    currentHeliosRecordReduced: (state: SCADAState) => state.currentHeliosRecordReduced,
    oneHeliosDevice: (state: SCADAState) => (deviceID: number) => {
        const filteredDevices = state.heliosDevices.filter((device) => device.id === deviceID);
        if (filteredDevices.length > 0) {
            return { ...filteredDevices[0] };
        }
    },
};

const { read } = getStoreAccessors<SCADAState, State>('');

export const readHeliosDevices = read(getters.heliosDevices);
export const readHeliosRecords = read(getters.recordsHelios);
export const readCurrentHeliosRecord = read(getters.currentHeliosRecord);
export const readCurrentHeliosRecordReduced = read(getters.currentHeliosRecordReduced);
export const readOneHeliosDevice = read(getters.oneHeliosDevice);
