import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { GalleryState } from './state';


const defaultState: GalleryState = {
    images: [],
};

export const galleryModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
