import { TaskState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    tasks: (state: TaskState) => state.tasks,
    schedule: (state: TaskState) => state.schedule,
    lastResult: (state: TaskState) => state.lastResult,
    statusInfo: (state: TaskState) => state.statusInfo,
    taskTypes: (state: TaskState) => state.taskTypes,
    results: (state: TaskState) => state.results,
    workers: (state: TaskState) => state.workers,
    runningTasks: (state: TaskState) => state.runningTasks,
    lastUsedTask: (state: TaskState) => state.lastUsedTask,
    runningTaskMap: (state: TaskState) => state.runningTaskMap,
    taskResultsMap: (state: TaskState) => state.taskResultsMap,
};

const { read } = getStoreAccessors<TaskState, State>('');

export const readTasks = read(getters.tasks);
export const readTaskSchedule = read(getters.schedule);
export const readLastTaskResult = read(getters.lastResult);
export const readStatusInfo = read(getters.statusInfo);
export const readTaskTypes = read(getters.taskTypes);
export const readTaskResults = read(getters.results);
export const readTaskWorkers = read(getters.workers);
export const readRunningTasks = read(getters.runningTasks);
export const readLastUsedTask = read(getters.lastUsedTask);
export const readRunningTaskMap = read(getters.runningTaskMap);
export const readTaskResultsMap = read(getters.taskResultsMap);
