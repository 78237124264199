import {
    IMeasurementUnit
    , IRAL
    , ICountry
} from '@/interfaces/common';
import { CommonState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setMeasurementUnits(state: CommonState, payload: IMeasurementUnit[]) {
        state.measurementUnits = payload;
    },
    setOneMeasurementUnit(state: CommonState, payload: IMeasurementUnit) {
        const measurementUnits = state.measurementUnits.filter((unit: IMeasurementUnit) => unit.id !== payload.id);
        measurementUnits.push(payload);
        state.measurementUnits = measurementUnits;
    },
    setRALMulti(state: CommonState, payload: IRAL[]) {
        state.ral = payload;
    },
    setRALOne(state: CommonState, payload: IRAL) {
        const ral = state.ral.filter((item: IRAL) => item.id !== payload.id);
        ral.push(payload);
        state.ral = ral;
    },
    removeRAL(state: CommonState, payload: IRAL) {
        state.ral = state.ral.filter(
            (item: IRAL) => (item.id !== payload.id));
    },
    setCountries(state: CommonState, payload: ICountry[]) {
        state.countries = payload;
    },
};

const { commit } = getStoreAccessors<CommonState, State>('');

export const commitSetMeasurementUnits = commit(mutations.setMeasurementUnits);
export const commitSetOneMeasurementUnit = commit(mutations.setOneMeasurementUnit);
export const commitSetRALMulti = commit(mutations.setRALMulti);
export const commitSetRALOne = commit(mutations.setRALOne);
export const commitRemoveRAL = commit(mutations.removeRAL);
export const commitSetCountries = commit(mutations.setCountries);
