import { getStoreAccessors } from 'typesafe-vuex';
import { GalleryState } from './state';
import { State } from '../state';


export const getters = {
    images: (state: GalleryState) => state.images,
};

const { read } = getStoreAccessors<GalleryState, State>('');

export const readImages = read(getters.images);
