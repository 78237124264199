import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_superuser && state.userProfile.is_active);
    },
    hasAdminGroupAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            var group = roles[i].name.split('.')[0];
            if (group == 'admin') {
              return true;
            }
        }
      }
      return false;
    },
    loginError: (state: MainState) => state.logInError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
    hasSCADAAccess: (state: MainState) => {
        if (!state.userProfile || !state.userProfile.is_active) {
          return false;
        }

        if (state.userProfile.is_superuser) {
          return true;
        }
        let roles = state.userProfile.roles;
        if (roles) {
          for(var i in roles) {
              var group = roles[i].name.split('.')[0];
              if (group == 'scada') {
                return true;
              }
          }
        }
        return false;
    },
    hasTaskManagementAccess: (state: MainState) => {
        if (!state.userProfile || !state.userProfile.is_active) {
          return false;
        }

        if (state.userProfile.is_superuser) {
          return true;
        }

        let roles = state.userProfile.roles;
        if (roles) {
          for(var i in roles) {
              var parts = roles[i].name.split('.');
              if (parts[0] == 'administrators' && parts[1] == 'task_management') {
                return true;
              }
          }
        }
        return false;
    },
    hasManufacturingAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      return false;
    },
    hasServiceAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            var group = roles[i].name.split('.')[0];
            if (group == 'service') {
              return true;
            }
        }
      }
      return false;
    },
    isServiceAdmin: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if (roles[i].name == 'service.admin') {
              return true;
            }
        }
      }
      return false;
    },
    hasServiceDealer: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'service.admin')
                || (roles[i].name == 'service.dealer')) {
              return true;
            }
        }
      }
      return false;
    },
    hasLogisticsAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
          var group = roles[i].name.split('.')[0];
          if (group == 'logistics') {
            return true;
          }
        }
      }
      return false;
    },
    hasCRMAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            var module = roles[i].name.split('.')[0];
            if (module == 'crm') {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMDealerAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMDealerAdvancedAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer_advanced')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMProductAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.products')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMAddressBookAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMNumberSeriesAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMOffersAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMPriceListAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMDiscountGroupsAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMOfferManagerAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    isCRMAdmin: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if (roles[i].name == 'crm.admin') {
              return true;
            }
        }
      }
      return false;
    },
    isPriceListAdmin: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        // for(var i in roles) {
        //     var module = roles[i].name.split('.')[0];
        //     if (module == 'crm') {
        //       return true;
        //     }
        // }
      }
      return false;
    },
    hasCRMDepartmentAdminAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.department_admin')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasCRMProjectAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'crm.dealer')
              || (roles[i].name == 'crm.offer_manager')
              || (roles[i].name == 'crm.admin')) {
              return true;
            }
        }
      }
      return false;
    },
    hasFinanceAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
          for (var j in roles[i].actions) {
            if (roles[i].name === 'finance.currency.update') {
              return true;
            }
          }
        }
      }
      return false;
    },
    hasDepartmentMembership: (state: MainState) => (departmentID: number) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }
      if (state.userProfile.department_id == departmentID) {
        return true;
      }
      return false;
    },
    backendVersion: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return null;
      }
      if (state.userProfile.is_superuser) {
        return state.backendVersion;
      }
      return null;
    },
    hasLTUAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            var module = roles[i].name.split('.')[0];
            if (module == 'ltu') {
              return true;
            }
        }
      }
      return false;
    },
    hasLTUPriceListAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'ltu.product_price_list')) {
              return true;
            }
        }
      }
      return false;
    },
    hasManselAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if ((roles[i].name == 'mansel.base') || (roles[i].name == 'ltu.mansel')) {
              return true;
            }
        }
      }
      return false;
    },
    hasLocaleAccess: (state: MainState) => {
      if (!state.userProfile || !state.userProfile.is_active) {
        return false;
      }

      if (state.userProfile.is_superuser) {
        return true;
      }

      let roles = state.userProfile.roles;
      if (roles) {
        for(var i in roles) {
            if (roles[i].name == 'admin.locale') {
              return true;
            }
        }
      }
      return false;
    },
    locale: (state: MainState) => { return state.locale },
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readHasAdminGroupAccess = read(getters.hasAdminGroupAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);

export const readHasSCADAAccess = read(getters.hasSCADAAccess);
export const readHasTaskManagementAccess = read(getters.hasTaskManagementAccess);
export const readHasManufacturingAccess = read(getters.hasManufacturingAccess);

export const readHasServiceAccess = read(getters.hasServiceAccess);
export const readIsServiceAdmin = read(getters.isServiceAdmin);
export const readHasServiceDealer = read(getters.hasServiceDealer);

export const readHasLogisticsAccess = read(getters.hasLogisticsAccess);

export const readHasCRMAccess = read(getters.hasCRMAccess);
export const readHasCRMDealerAccess = read(getters.hasCRMDealerAccess);
export const readHasCRMDealerAdvancedAccess = read(getters.hasCRMDealerAdvancedAccess);
export const readHasCRMProductAccess = read(getters.hasCRMProductAccess);
export const readHasCRMOfferManagerAccess = read(getters.hasCRMOfferManagerAccess);
export const readHasCRMAddressBookAccess = read(getters.hasCRMAddressBookAccess);
export const readHasCRMNumberSeriesAccess = read(getters.hasCRMNumberSeriesAccess);
export const readHasCRMOffersAccess = read(getters.hasCRMOffersAccess);
export const readHasCRMPriceListAccess = read(getters.hasCRMPriceListAccess);
export const readHasCRMDiscountGroupsAccess = read(getters.hasCRMDiscountGroupsAccess);
export const readHasCRMDepartmentAdminAccess = read(getters.hasCRMDepartmentAdminAccess);
export const readHasCRMProjectAccess = read(getters.hasCRMProjectAccess);


export const readIsCRMAdmin = read(getters.isCRMAdmin);

export const readIsPriceListAdmin = read(getters.isPriceListAdmin);

export const readHasFinanceAccess = read(getters.hasFinanceAccess);

export const readHasDepartmentMembership = read(getters.hasDepartmentMembership);

export const readBackendVersion = read(getters.backendVersion);

export const readHasLTUAccess = read(getters.hasLTUAccess);
export const readHasLTUPriceListAccess = read(getters.hasLTUPriceListAccess);

export const readHasManselAccess = read(getters.hasManselAccess);

export const readLocale = read(getters.locale);
export const readHasLocaleAccess = read(getters.hasLocaleAccess);
