import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

import RouterComponent from '../components/RouterComponent.vue';

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "start" */ '../views/main/Start.vue'),
    children: [
      {
        path: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "main" */ '../views/main/Main.vue'),
        children: [
          {
            path: 'dashboard',
            component: () => import(/* webpackChunkName: "main-dashboard" */ '../views/main/Dashboard.vue'),
          },
          {
            path: 'profile',
            component: RouterComponent,
            redirect: 'profile/view',
            children: [
              {
                path: 'view',
                component: () => import(
                  /* webpackChunkName: "main-profile" */ '../views/main/profile/UserProfile.vue'),
              },
              {
                path: 'edit',
                component: () => import(
                  /* webpackChunkName: "main-profile-edit" */ '../views/main/profile/UserProfileEdit.vue'),
              },
              {
                path: 'password',
                component: () => import(
                  /* webpackChunkName: "main-profile-password" */ '../views/main/profile/UserProfileEditPassword.vue'),
              },
            ],
          },
          {
            path: 'admin',
            component: () => import(/* webpackChunkName: "main-admin" */ '../views/main/admin/Admin.vue'),
            redirect: 'admin/users/all',
            children: [
              {
                path: 'users',
                redirect: 'users/all',
              },
              {
                path: 'users/all',
                component: () => import(
                  /* webpackChunkName: "main-admin-users" */ '../views/main/admin/AdminUsers.vue'),
              },
              {
                path: 'users/edit/:id',
                name: 'main-admin-users-edit',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-edit" */ '../views/main/admin/EditUser.vue'),
              },
              {
                path: 'users/create',
                name: 'main-admin-users-create',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-create" */ '../views/main/admin/CreateUser.vue'),
              },
              {
                path: 'roles',
                redirect: 'roles/all',
              },
              {
                path: 'roles/all',
                component: () => import(
                  /* webpackChunkName: "main-admin-roles" */ '../views/main/admin/AdminRoles.vue'),
              },
              {
                path: 'roles/create',
                name: 'main-admin-roles-create',
                component: () => import(
                  /* webpackChunkName: "main-admin-roles-create" */ '../views/main/admin/CreateRole.vue'),
              },
              {
                path: 'locale',
                name: 'main-admin-locale',
                component: () => import(
                  /* webpackChunkName: "main-admin-locale" */ '../views/main/admin/AdminLocaleUser.vue'),
              },
              {
                path: 'locale/admin',
                name: 'main-admin-locale-admin',
                component: () => import(
                  /* webpackChunkName: "main-admin-locale-admin" */ '../views/main/admin/AdminLocale.vue'),
              },
              {
                path: 'actions',
                redirect: 'actions/all',
              },
              {
                path: 'actions/all',
                component: () => import(
                  /* webpackChunkName: "main-admin-actions" */ '../views/main/admin/AdminActions.vue'),
              },
              {
                path: 'actions/create',
                name: 'main-admin-actions-create',
                component: () => import(
                  /* webpackChunkName: "main-admin-users-create" */ '../views/main/admin/CreateAction.vue'),
              },
              {
                path: 'actions/create/multi',
                name: 'main-admin-actions-create-multi',
                component: () => import(
                  /* webpackChunkName: "main-admin-actions-create-multi" */ '../views/main/admin/CreateMultipleActions.vue'),
              },
              {
                path: 'log',
                name: 'main-admin-log',
                component: () => import(
                  /* webpackChunkName: "main-admin-log" */ '../views/main/admin/AdminLog.vue'),
              },
            ],
          },
          {
            path: 'crm',
            component: () => import(/* webpackChunkName: "main-crm" */ '../views/main/crm/CRM.vue'),
            redirect: 'address_book',
            children: [
              {
                path: 'address_book',
                name: 'main-crm-address_book',
                component: () => import(
                  /* webpackChunkName: "main-crm-address_book" */ '../views/main/crm/Companies.vue'),
              },
              {
                path: 'projects',
                name: 'main-crm-projects',
                component: () => import(
                  /* webpackChunkName: "main-crm-projects" */ '../views/main/crm/Projects.vue'),
              },
              {
                path: 'number-series',
                name: 'main-crm-number-series',
                component: () => import(
                  /* webpackChunkName: "main-crm-offers" */ '../views/main/crm/NumberSeries.vue'),
              },
              {
                path: 'offers',
                name: 'main-crm-offers',
                component: () => import(
                  /* webpackChunkName: "main-crm-offers" */ '../views/main/crm/Offers.vue'),
              },
              {
                path: 'offers/:id',
                name: 'main-crm-offers-id',
                component: () => import(
                  /* webpackChunkName: "main-crm-offers-id" */ '../views/main/crm/Offers.vue'),
              },
              {
                path: 'offer/create',
                name: 'main-crm-offer-create',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-create" */ '../views/main/crm/OfferCreate.vue'),
              },
              {
                path: 'offer-detail/:id',
                name: 'main-crm-offer-detail',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-detail" */ '../views/main/crm/OfferDetail.vue'),
              },
              {
                path: 'offer/email/:id',
                name: 'main-crm-offer-email',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-email" */ '../views/main/crm/OfferEmail.vue'),
              },
              {
                path: 'product-categories',
                name: 'main-crm-product-categories',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-categories" */ '../views/main/crm/ProductCategories.vue'),
              },
              {
                path: 'product-categories/edit/:id',
                name: 'main-crm-product-categories-edit',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-categories-edit" */ '../views/main/crm/ProductCategoryEdit.vue'),
              },
              {
                path: 'product-subcategories/edit/:id',
                name: 'main-crm-product-subcategories-edit',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-subcategories-edit" */ '../views/main/crm/ProductSubcategoryEdit.vue'),
              },
              {
                path: 'product-groups',
                name: 'main-crm-product-groups',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-groups" */ '../views/main/crm/ProductGroups.vue'),
              },
              {
                path: 'product-groups/create',
                name: 'main-crm-product-groups-create',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-groups-create" */ '../views/main/crm/ProductGroupCreate.vue'),
              },
              {
                path: 'product-groups/edit/:id',
                name: 'main-crm-product-groups-edit',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-groups-edit" */ '../views/main/crm/ProductGroupEdit.vue'),
              },
              {
                path: 'product-groups/header-footer',
                name: 'main-crm-product-groups-header-footer',
                component: () => import(
                  /* webpackChunkName: "main-crm-product-groups-header-footer" */ '../views/main/crm/ProductGroupHeaderFooter.vue'),
              },
              {
                path: 'products',
                name: 'main-crm-products',
                component: () => import(
                  /* webpackChunkName: "main-crm-products" */ '../views/main/crm/Products.vue'),
              },
              {
                path: 'products/create',
                name: 'main-crm-products-create',
                component: () => import(
                  /* webpackChunkName: "main-crm-products-create" */ '../views/main/crm/ProductCreate.vue'),
              },
              {
                path: 'products/composite',
                name: 'main-crm-products-composite',
                component: () => import(
                  /* webpackChunkName: "main-crm-products-composite" */ '../views/main/crm/ProductCompositeView.vue'),
              },
              {
                path: 'price-lists',
                name: 'main-crm-price-lists',
                component: () => import(
                  /* webpackChunkName: "main-crm-price-lists" */ '../views/main/crm/PriceLists.vue'),
              },
              {
                path: 'price-lists/create',
                name: 'main-crm-price-lists-create',
                component: () => import(
                  /* webpackChunkName: "main-crm-price-lists-create" */ '../views/main/crm/PriceListCreate.vue'),
              },
              {
                path: 'overheads',
                name: 'main-crm-overheads',
                component: () => import(
                  /* webpackChunkName: "main-crm-overheads" */ '../views/main/crm/Overheads.vue'),
              },
              {
                path: 'price-lists/detail/:id',
                name: 'main-crm-price-lists-detail',
                component: () => import(
                  /* webpackChunkName: "main-crm-price-lists-detail" */ '../views/main/crm/PriceListDetail.vue'),
              },
              {
                path: 'price-lists/merge/:id',
                name: 'main-crm-price-lists-merge',
                component: () => import(
                  /* webpackChunkName: "main-crm-price-lists-merge" */ '../views/main/crm/PriceListMerge.vue'),
              },
              {
                path: 'price-lists/discount-groups/:id',
                name: 'main-crm-price-lists-discount-groups',
                component: () => import(
                  /* webpackChunkName: "main-crm-price-lists-discount-groups" */ '../views/main/crm/ProductPriceGroupDiscounts.vue'),
              },
              {
                path: 'discount-groups/:id',
                name: 'main-crm-discount-groups',
                component: () => import(
                  /* webpackChunkName: "main-crm-discount-groups" */ '../views/main/crm/ProductGroupRabats.vue'),
              },
              {
                path: 'offer-messages/create',
                name: 'main-crm-offer-messages-create',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-messages-create" */ '../views/main/crm/OfferMessageCreate.vue'),
              },
              {
                path: 'offer-messages/edit/:id',
                name: 'main-crm-offer-messages-edit',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-messages-edit" */ '../views/main/crm/OfferMessageEdit.vue'),
              },
              {
                path: 'offer-messages',
                name: 'main-crm-offer-messages',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-messages" */ '../views/main/crm/OfferMessages.vue'),
              },
              {
                path: 'offer-items-gaeb/:id',
                name: 'main-crm-offer-items-gaeb',
                component: () => import(
                  /* webpackChunkName: "main-crm-offer-items-gaeb" */ '../views/main/crm/OfferItemsGAEB.vue'),
              },
            ],
          },
          {
            path: 'scada',
            component: () => import(/* webpackChunkName: "main-scada" */ '../views/main/scada/Scada.vue'),
            redirect: 'scada/helios',
            children: [
              {
                path: 'helios',
                name: 'main-scada-helios',
                component: () => import(
                  /* webpackChunkName: "main-scada-helios" */ '../views/main/scada/HeliosDevices.vue'),
              },
              {
                path: 'helios/records',
                name: 'main-scada-helios-records',
                component: () => import(
                  /* webpackChunkName: "main-scada-helios-records" */ '../views/main/scada/HeliosRecords.vue'),
              },
              {
                path: 'helios/charts',
                name: 'main-scada-helios-charts',
                component: () => import(
                  /* webpackChunkName: "main-scada-helios-records" */ '../views/main/scada/HeliosCharts.vue'),
              },
              {
                path: 'helios/create',
                name: 'main-scada-helios-create',
                component: () => import(
                  /* webpackChunkName: "main-scada-helios-create" */ '../views/main/scada/HeliosCreate.vue'),
              },
              {
                path: 'helios/edit/:id',
                name: 'main-scada-helios-edit',
                component: () => import(
                  /* webpackChunkName: "main-scada-helios-edit" */ '../views/main/scada/HeliosEdit.vue'),
              },
            ],
          },
          {
            path: 'tasks',
            component: () => import(/* webpackChunkName: "main-tasks" */ '../views/main/tasks/TaskManagement.vue'),
            redirect: 'list',
            children: [
              {
                path: 'workers',
                name: 'main-tasks-workers',
                component: () => import(
                  /* webpackChunkName: "main-tasks-workers" */ '../views/main/tasks/Workers.vue'),
              },
              {
                path: 'list',
                name: 'main-tasks-list',
                component: () => import(
                  /* webpackChunkName: "main-tasks-list" */ '../views/main/tasks/Tasks.vue'),
              },
              {
                path: 'create',
                name: 'main-tasks-create',
                component: () => import(
                  /* webpackChunkName: "main-tasks-create" */ '../views/main/tasks/TaskCreate.vue'),
              },
              {
                path: 'schedule',
                name: 'main-tasks-schedule',
                component: () => import(
                  /* webpackChunkName: "main-tasks-schedule" */ '../views/main/tasks/Schedule.vue'),
              },
              {
                path: 'result/:id',
                name: 'main-tasks-result',
                component: () => import(
                  /* webpackChunkName: "main-tasks-result" */ '../views/main/tasks/TaskResult.vue'),
              },
            ]
          },
          {
            path: 'service',
            component: () => import(/* webpackChunkName: "main-service" */ '../views/main/service/Service.vue'),
            redirect: 'cases',
            children: [
              {
                path: 'cases',
                name: 'main-service-cases',
                component: () => import(
                  /* webpackChunkName: "main-service-cases" */ '../views/main/service/Cases.vue'),
              },
              {
                path: 'cases/create',
                name: 'main-service-cases-create',
                component: () => import(
                  /* webpackChunkName: "main-service-cases-create" */ '../views/main/service/CreateCase.vue'),
              },
              {
                path: 'cases/:id',
                name: 'main-service-cases-view',
                component: () => import(
                  /* webpackChunkName: "main-service-cases-view" */ '../views/main/service/Case.vue'),
              },
              {
                path: 'cases/edit/:id',
                name: 'main-service-cases-edit',
                component: () => import(
                  /* webpackChunkName: "main-service-cases-edit" */ '../views/main/service/EditCase.vue'),
              },
              {
                path: 'cases/report/add/:id',
                name: 'main-service-cases-report-add',
                component: () => import(
                  /* webpackChunkName: "main-service-cases-report-add" */ '../views/main/service/CreateReport.vue'),
              },
              {
                path: 'price-lists',
                name: 'main-service-price-lists',
                component: () => import(
                  /* webpackChunkName: "main-service-price-lists" */ '../views/main/service/PriceList.vue'),
              },
              {
                path: 'cost-estimation',
                name: 'main-service-cost-estimation',
                component: () => import(
                  /* webpackChunkName: "main-service-cost-estimation" */ '../views/main/service/CostEstimation.vue'),
              },
            ]
          },
          {
            path: 'manufacturing',
            component: () => import(/* webpackChunkName: "main-manufacturing" */ '../views/main/manufacturing/Manufacturing.vue'),
            redirect: 'capacity-table',
            children: [
              {
                path: 'capacity-table',
                name: 'main-manufacturing-capacity-table',
                component: () => import(
                  /* webpackChunkName: "main-manufacturing-capacity-table" */ '../views/main/manufacturing/CapacityTable.vue'),
              },
            ]
          },
          {
            path: 'logistics',
            component: () => import(/* webpackChunkName: "main-logistics" */ '../views/main/logistics/Logistics.vue'),
            redirect: 'expedition',
            children: [
              {
                path: 'expedition',
                name: 'main-logistics-expedition',
                component: () => import(
                  /* webpackChunkName: "main-logistics-expedition" */ '../views/main/logistics/Expedition.vue'),
              },
            ]
          },
          {
            path: 'finance',
            component: () => import(/* webpackChunkName: "main-finance" */ '../views/main/finance/Finance.vue'),
            redirect: 'currency',
            children: [
              {
                path: 'currency',
                name: 'main-finance-currency',
                component: () => import(
                  /* webpackChunkName: "main-finance-currency" */ '../views/main/finance/Currency.vue'),
              },
              {
                path: 'payment-methods',
                name: 'main-finance-payment-methods',
                component: () => import(
                  /* webpackChunkName: "main-finance-payment-methods" */ '../views/main/finance/PaymentMethods.vue'),
              },
            ]
          },
          {
            path: 'ltu',
            component: () => import(/* webpackChunkName: "main-ltu" */ '../views/main/ltu/LTU.vue'),
            redirect: 'products',
            children: [
              {
                path: 'products',
                name: 'main-ltu-products',
                component: () => import(
                  /* webpackChunkName: "main-ltu-products" */ '../views/main/ltu/Products.vue'),
              },
              {
                path: 'price-lists',
                name: 'main-ltu-price-lists',
                component: () => import(
                  /* webpackChunkName: "main-ltu-price-lists" */ '../views/main/ltu/PriceLists.vue'),
              },
              {
                path: 'price-lists/detail/:id',
                name: 'main-ltu-price-lists-detail',
                component: () => import(
                  /* webpackChunkName: "main-ltu-price-lists-detail" */ '../views/main/ltu/PriceListDetail.vue'),
              },
            ]
          },
          {
            path: 'mansel',
            component: () => import(/* webpackChunkName: "main-mansel" */ '../views/main/mansel/Mansel.vue'),
            redirect: 'vck-test',
            children: [
              {
                path: 'vck-test',
                name: 'main-mansel-vck-test',
                component: () => import(
                  /* webpackChunkName: "main-mansel-vck-test" */ '../views/main/mansel/VCKTest.vue'),
              },
            ]
          },
          {
            path: 'project',
            component: () => import(/* webpackChunkName: "main-project" */ '../views/main/project/Project.vue'),
            redirect: 'summary',
            children: [
              {
                path: 'summary',
                name: 'main-project-summary',
                component: () => import(
                  /* webpackChunkName: "main-project-summary" */ '../views/main/project/ProjectsView.vue'),
              },
              {
                path: 'timesheets',
                name: 'main-project-timesheets',
                component: () => import(
                  /* webpackChunkName: "main-project-timesheets" */ '../views/main/project/Timesheets.vue'),
              },
            ]
          },
        ]
      }
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.onError(error => {
  console.error(error);
  Vue.prototype.$log.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    Vue.prototype.$log.error('Reloading Window 1');
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    Vue.prototype.$log.error('Reloading Window 2');
    window.location.reload();
  }
});

export default router
