import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ISCADAHelios, ISCADAHeliosRecord, ISCADAHeliosCreate } from '@/interfaces';
import { State } from '../state';
import { SCADAState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetHeliosRecords, commitCurrentHeliosRecord, commitCurrentHeliosRecordReduced, commitSetHeliosDevices } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<SCADAState, State>;

export const actions = {
    async actionGetHeliosDevices(context: MainContext) {
        try {
            const response = await api.getHeliosDevices(context.rootState.main.token);
            if (response) {
                console.debug(response);
                commitSetHeliosDevices(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetHeliosRecords(context: MainContext) {
        try {
            const response = await api.getHeliosRecords(context.rootState.main.token);
            if (response) {
                commitSetHeliosRecords(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneHeliosRecord(context: MainContext, payload: ISCADAHeliosRecord) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            //console.debug(payload);
            const response = await api.getOneHeliosRecord(context.rootState.main.token, payload);
            if (response) {
                //console.debug(response);
                commitCurrentHeliosRecord(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.chartsLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetOneHeliosRecordReduced(context: MainContext, payload: ISCADAHeliosRecord) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            //console.debug(payload);
            const response = await api.getOneHeliosRecordReduced(context.rootState.main.token, payload);
            if (response) {
                //console.debug(response);
                commitCurrentHeliosRecordReduced(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.chartsLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateHelios(context: MainContext, payload: ISCADAHeliosCreate) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createHelios(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            //commitSetHeliosDevices(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.heliosCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteHelios(context: MainContext, payload: ISCADAHelios) {
        try {
            const loadingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteHelios(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.heliosDeleted').toString(), color: 'success' });
            // const response2 = await api.getActions(context.rootState.main.token);
            // if (response2) {
            //     commitSetActions(context, response2.data);
            // }
            // //await this.actionGetActions(context);
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.heliosNotDeleted').toString(), color: 'error' });
        }
    },
    async actionUpdateHelios(context: MainContext, payload: ISCADAHelios) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateHelios(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            //commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.heliosUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUploadHeliosRecord(context: MainContext, payload: FormData) {
        try {
            const uploadingNotification = { content: i18n.t('notification.uploading').toString(), showProgress: true };
            commitAddNotification(context, uploadingNotification);
            const response = (await Promise.all([
                api.uploadHeliosRecord(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            // console.debug('upload response');
            // console.debug(response);
            commitRemoveNotification(context, uploadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.recordUploaded').toString(), color: 'success' });
        } catch (error: any) {
            // console.debug('upload error');
            // console.debug(error: any);
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.uploadFailed').toString(), color: 'error' });
        }
    },
};

const { dispatch } = getStoreAccessors<SCADAState, State>('');

export const dispatchGetHeliosDevices = dispatch(actions.actionGetHeliosDevices);
export const dispatchGetHeliosRecords = dispatch(actions.actionGetHeliosRecords);
export const dispatchGetOneHeliosRecord = dispatch(actions.actionGetOneHeliosRecord);
export const dispatchGetOneHeliosRecordReduced = dispatch(actions.actionGetOneHeliosRecordReduced);
export const dispatchCreateHelios = dispatch(actions.actionCreateHelios);
export const dispatchDeleteHelios = dispatch(actions.actionDeleteHelios);
export const dispatchUpdateHelios = dispatch(actions.actionUpdateHelios);
export const dispatchUploadHeliosRecord = dispatch(actions.actionUploadHeliosRecord);
