import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { FinanceState } from './state';


const defaultState: FinanceState = {
    currencies: [],
    paymentMethods: [],
    taxRates: [],
};

export const financeModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
