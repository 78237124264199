import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IAHUTranFile, ILogisticsExpedition, ILogisticsExpeditionCreate, ILogisticsExpeditionUpdate } from '@/interfaces/logistics';
import { State } from '../state';
import { LogisticsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetAHUtranFile, commitSetExpeditions, commitSetTransports } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification
        , commitRemoveNotification
    } from '../main/mutations';
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<LogisticsState, State>;

export const actions = {
    async actionGetExpeditions(context: MainContext) {
        try {
            const loadingNotification = { content: i18n.t('notification.loading').toString(), showProgress: true };
            const response = await api.getLogisticsExpeditions(context.rootState.main.token);
            if (response) {
                //console.debug(response);
                commitSetExpeditions(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.expeditionsLoaded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateExpedition(context: MainContext, payload: ILogisticsExpeditionCreate ) {
        try {
            const loadingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createLogisticsExpedition(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('notification.expeditionAdded').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.expeditionNotSaved').toString(), color: 'error' });
        }
    },
    async actionUpdateExpedition(context: MainContext, payload: {id: number, expedition: ILogisticsExpeditionUpdate }) {
        try {
            const updatingNotification = { content: i18n.t('notification.updating').toString(), showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateLogisticsExpedition(context.rootState.main.token, payload.id, payload.expedition),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: i18n.t('notification.expeditionUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.expeditionNotDeleted').toString(), color: 'error' });
        }
    },
    async actionDeleteExpedition(context: MainContext, payload: {id: number}) {
        try {
            const deletingNotification = { content: i18n.t('notification.deleting').toString(), showProgress: true };
            commitAddNotification(context, deletingNotification);
            const response = (await Promise.all([
                api.deleteLogisticsExpedition(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, deletingNotification);
            commitAddNotification(context, { content: i18n.t('notification.expeditionDeleted').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.expeditionNotDeleted').toString(), color: 'error' });
        }
    },
    async actionGetAHUTranFile(context: MainContext, payload: IAHUTranFile) {
        try {
            const loadingNotification = { content: i18n.t('notification.downloadingAHUTRan').toString(), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.getAHUTranFile(context.rootState.main.token, payload.id),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', payload.path);
            document.body.appendChild(fileLink);

            fileLink.click();
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUploadAHUTranFile(context: MainContext, payload: FormData) {
        try {
            console.debug('AHUTran upload');
            const uploadingNotification = { content: i18n.t('notification.uploadingAHUTRan').toString(), showProgress: true };
            commitAddNotification(context, uploadingNotification);
            const response = (await Promise.all([
                api.uploadAHUTranFile(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];

            console.debug(response.data);
            commitSetAHUtranFile(context, { path: response.data.path, id: response.data.id });
            commitRemoveNotification(context, uploadingNotification);

        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTransports(context: MainContext) {
        try {
            const response = await api.getLogisticsTransports(context.rootState.main.token);
            if (response) {
                commitSetTransports(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<LogisticsState, State>('');

export const dispatchGetExpeditions = dispatch(actions.actionGetExpeditions);
export const dispatchCreateExpedition = dispatch(actions.actionCreateExpedition);
export const dispatchUpdateExpedition = dispatch(actions.actionUpdateExpedition);
export const dispatchDeleteExpedition = dispatch(actions.actionDeleteExpedition);
export const dispatchGetAHUTranFile = dispatch(actions.actionGetAHUTranFile);
export const dispatchUploadAHUTranFile = dispatch(actions.actionUploadAHUTranFile);
export const dispatchGetTransports = dispatch(actions.actionGetTransports);
