import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { SCADAState } from './state';

const defaultState: SCADAState = {
    heliosDevices: [],
    recordsHelios: [],
    currentHeliosRecord: [],
    currentHeliosRecordReduced: Object(),
};

export const scadaModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
