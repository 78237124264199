import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { CommonState } from './state';


const defaultState: CommonState = {
    measurementUnits: [],
    ral: [],
    countries: [],
};

export const commonModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
