
import { getStoreAccessors } from 'typesafe-vuex';
import { ProjectState } from './state';
import { State } from '../state';


export const getters = {
    projectMulti: (state: ProjectState) => state.projects,
    projectOne: (state: ProjectState) => (projectID: number) => {
        const filteredProjects = state.projects.filter(
            (project) => project.id === projectID);
        if (filteredProjects.length > 0) {
            return { ...filteredProjects[0] };
        }
    },
    timesheetMulti: (state: ProjectState) => state.timesheets,
    timesheetOne: (state: ProjectState) => (timesheetID: number) => {
        const filteredTS = state.timesheets.filter(
            (timesheet) => timesheet.id === timesheetID);
        if (filteredTS.length > 0) {
            return { ...filteredTS[0] };
        }
    },
    projectTaskMulti: (state: ProjectState) => state.tasks,
    projectTaskOne: (state: ProjectState) => (taskID: number) => {
        const filteredTasks = state.tasks.filter(
            (task) => task.id === taskID);
        if (filteredTasks.length > 0) {
            return { ...filteredTasks[0] };
        }
    },
    projectTaskStateMulti: (state: ProjectState) => state.taskStates,
    projectTaskStateOne: (state: ProjectState) => (taskStateID: number) => {
        const filteredStates = state.taskStates.filter(
            (state) => state.id === taskStateID);
        if (filteredStates.length > 0) {
            return { ...filteredStates[0] };
        }
    },
};

const { read } = getStoreAccessors<ProjectState, State>('');

export const readProjectMulti = read(getters.projectMulti);
export const readProjectOne = read(getters.projectOne);
export const readTimesheetMulti = read(getters.timesheetMulti);
export const readTimesheetOne = read(getters.timesheetOne);
export const readProjectTaskMulti = read(getters.projectTaskMulti);
export const readProjectTaskOne = read(getters.projectTaskOne);
export const readProjectTaskStateMulti = read(getters.projectTaskStateMulti);
export const readProjectTaskStateOne = read(getters.projectTaskStateOne);
