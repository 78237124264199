import {
  ILTUPriceList
} from '@/interfaces/ltu';
import { LTUState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {

    setLTUPriceListMulti(state: LTUState, payload: ILTUPriceList[]) {
        state.ltuPriceList = payload;
    },
    setLTUPriceListOne(state: LTUState, payload: ILTUPriceList) {
        const ltuPriceList = state.ltuPriceList.filter((item: ILTUPriceList) => item.id !== payload.id);
        ltuPriceList.push(payload);
        state.ltuPriceList = ltuPriceList;
    },
    removeLTUPriceList(state: LTUState, payload: { priceListID: string }) {
        state.ltuPriceList = state.ltuPriceList.filter(
            (item: ILTUPriceList) => (item.id !== payload.priceListID));
    },
};

const { commit } = getStoreAccessors<LTUState, State>('');
export const commitSetLTUPriceListMulti = commit(mutations.setLTUPriceListMulti);
export const commitSetLTUPriceListOne = commit(mutations.setLTUPriceListOne);
export const commitRemoveLTUPriceList = commit(mutations.removeLTUPriceList);
