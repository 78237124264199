import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { LogisticsState } from './state';


const defaultState: LogisticsState = {
    expeditions: [],
    ahutranFile: null,
    transports: [],
};

export const logisticsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
