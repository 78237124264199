import { api } from '@/api';
import { ActionContext } from 'vuex';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { FinanceState } from './state';
import {
    commitSetCurrencies
    , commitSetOnePaymentMethod
    , commitSetPaymentMethod
    , commitSetTaxRatesMulti
    , commitSetTaxRatesOne
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
    commitAddNotification
    , commitRemoveNotification
} from '../main/mutations';
import {
    IFinanceCurrencyUpdate
    , IFinancePaymentMethodCreate
    , IFinanceTaxRate
    , IFinanceTaxRateCreate
    , IFinanceTaxRateUpdate
} from '@/interfaces/finance'
import { i18n } from "@/plugins/i18n";

type MainContext = ActionContext<FinanceState, State>;

export const actions = {
    async actionGetCurrencies(context: MainContext) {
        try {
            //const loadingNotification = { content: 'loading', showProgress: true };
            //commitAddNotification(context, loadingNotification);
            const response = await api.getCurrencies(context.rootState.main.token);
            if (response) {
                commitSetCurrencies(context, response.data);
            }
            // commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Currencies loaded', color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCurrency(context: MainContext, payload: IFinanceCurrencyUpdate) {
        try {
            const updatingNotification = { content: i18n.t('notification.updating').toString(), showProgress: true };
            commitAddNotification(context, updatingNotification);
            const response = (await Promise.all([
                api.updateCurency(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitRemoveNotification(context, updatingNotification);
            commitAddNotification(context, { content: i18n.t('notification.currencyUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: i18n.t('notification.currencyNotUpdated').toString(), color: 'error' });
        }
    },
    async actionGetPaymentMethod(context: MainContext) {
        try {
            const response = await api.getPaymentMethods(context.rootState.main.token);
            if (response) {
                commitSetPaymentMethod(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreatePaymentMethod(context: MainContext, payload: IFinancePaymentMethodCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createPaymentMethod(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetOnePaymentMethod(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.paymentMethodCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTaxRatesMulti(context: MainContext) {
        try {
            const response = await api.getTaxRates(context.rootState.main.token);
            if (response) {
                commitSetTaxRatesMulti(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTaxRatesOne(context: MainContext, payload: IFinanceTaxRate) {},
    async actionCreateTaxRate(context: MainContext, payload: IFinanceTaxRateCreate) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.createTaxRate(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetTaxRatesOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.taxRateCreated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateTaxRate(context: MainContext, payload: { taxRateID: number, data: IFinanceTaxRateUpdate }) {
        try {
            const savingNotification = { content: i18n.t('notification.saving').toString(), showProgress: true };
            commitAddNotification(context, savingNotification);
            const response = (await Promise.all([
                api.updateTaxRate(context.rootState.main.token, payload.taxRateID, payload.data),
                await new Promise((resolve, reject) => setTimeout(() => resolve(true), 500)),
            ]))[0];
            commitSetTaxRatesOne(context, response.data);
            commitRemoveNotification(context, savingNotification);
            commitAddNotification(context, { content: i18n.t('notification.taxRateUpdated').toString(), color: 'success' });
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionDeleteTaxRate(context: MainContext, payload: IFinancePaymentMethodCreate) {},
}

const { dispatch } = getStoreAccessors<FinanceState, State>('');

export const dispatchGetCurrencies = dispatch(actions.actionGetCurrencies);
export const dispatchUpdateCurrency = dispatch(actions.actionUpdateCurrency);
export const dispatchGetPaymentMethods = dispatch(actions.actionGetPaymentMethod);
export const dispatchCreatePaymentMethod = dispatch(actions.actionCreatePaymentMethod);
export const dispatchGetTaxRatesMulti = dispatch(actions.actionGetTaxRatesMulti);
export const dispatchGetTaxRatesOne = dispatch(actions.actionGetTaxRatesOne);
export const dispatchCreateTaxRate = dispatch(actions.actionCreateTaxRate);
export const dispatchUpdateTaxRate = dispatch(actions.actionUpdateTaxRate);
export const dispatchDeleteTaxRate = dispatch(actions.actionDeleteTaxRate);