import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { CRMState } from './state';


const defaultState: CRMState = {
    customers: [],
    companies: [],
    companyARES: null,
    branches: [],
    contactPersons: [],
    contactPersonsFull: [],
    oneContactPersonFull: null,
    productCategories: [],
    productSubcategories: [],
    productGroups: [],
    nonEmptyProductGroups: [],
    selectedProductGroupInfo: null,
    productVariants: [],
    products: [],
    product: null,
    productsCount: 0,
    offers: [],
    oneOffer: null,
    offersTable: [],
    offerItems: [],
    offerItemsGrouped: [],
    offerDiscounts: [],
    priceLists: [],
    currentPriceList: null,
    productPrices: [],
    productPricesMerge: [],
    priceListExportTasks: [],
    priceListImportTasks: [],
    priceListPopulationTasks: [],
    priceListSyncTasks: [],
    priceListSyncTaskResult: null,
    priceListPropagationTasks: [],
    priceListRecalculationTasks: [],
    priceListRecalculationTaskResult: null,
    productsExportTasks: [],
    productsImportTasks: [],
    productsImportCheckTasks: [],
    productsImportCheckTaskResult: null,
    overheads: [],
    companyGroupRabats: [],
    numberSeries: [],
    useNumberSeries: null,
    offerState: [],
    offerValidity: [],
    offerYears: [],
    offerProductGroups: [],
    productSizes: [],
    productTypes: [],
    sizeTypeProducts: [],
    recalculatedOfferItem: null,
    productPriceDiscountGroups: [],
    offerMessage: [],
    priceListGroups: [],
    productPriceSizes: [],
    productPriceTypes: [],
    productPriceSizeTypes: [],
    discountMax: null,
    productPricesCount: 0,
    productComposite: null,
    offerGroupDefaults: null,
    createdProduct: null,
    productPriceComposite: null,
    productAttributes: [],
    projects: [],
    offerItemsGAEB: [],
};

export const crmModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
